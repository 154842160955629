/* eslint-disable no-param-reassign */
import React from 'react';
import { MULTIPLE_PREMIUM_DISCOUNT_ALIAS } from 'common/constants/valuations';
import { largeCurrencyFormat, percentFormat } from 'common/formats/formats';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import {
  ADJUSTMENT_METRIC_ALIAS,
  TOTAL_PREMIUM_DISCOUNT_ALIAS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/MultiplePremiumDiscount/common/constants/sheetTitlesAndAliases';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { handleDiscountOptionsNumberFormat } from '../../../../gpc/handleOptionsNumberFormat';
import { getMultiplePremiumDiscountOptionsForCell } from '../../../../utils/utilities';

const setFormatForCells = (cells, sheetName, format) => {
  ['B4', 'C4', 'B5', 'C5'].forEach(cell => {
    cells[sheetName][cell].format = { ...format };
  });
};

const conditions = ({ cell, cells }) => {
  if (cell.sheet && cell.alias === ADJUSTMENT_METRIC_ALIAS) {
    const format
      = cell.value.toLowerCase().includes('growth') || cell.value.toLowerCase().includes('margin')
        ? percentFormat({ isDecimal: true })
        : largeCurrencyFormat;

    setFormatForCells(cells, cell.sheet.name, format);
  }
  if (cell.sheet && cell.alias === TOTAL_PREMIUM_DISCOUNT_ALIAS && cell.columnLegend === 'C') {
    const gpcTableName = getApproachTableName({ approach: cell.sheet.tableData.approach });
    if (cells[gpcTableName]) {
      const mpdReference = cell.sheet.name;
      const opts = getMultiplePremiumDiscountOptionsForCell(mpdReference, cell.value);
      Object.values(cells[gpcTableName])
        .filter(cell => cell.alias === MULTIPLE_PREMIUM_DISCOUNT_ALIAS && cell.customKey)
        .forEach(cell => {
          cell.dataEditor = props => (
            <GridSelect options={opts} handleOptionsNumberFormat={handleDiscountOptionsNumberFormat} {...props} />
          );
        });
    }
  }
};

export default conditions;
