import { ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_KEY_PREFIX } from 'pages/ValuationsAllocation/common/constants/weightedShareValues';
import { generateColumnKey, getNumberValue, getObjectValue, getStringValue } from 'utillities';
import { CreateColumns, WeightedShareValuesColumn, WeightedShareValuesColumnCellData } from './types';

const createColumns: CreateColumns = params => {
  const { fund, shareValues } = params;
  const { id: fundId = 0 } = getObjectValue(fund);

  // Weighted Value per Share
  const weightedValuePerShare = shareValues?.reduce((accumulator, current) => {
    const { is_custom_security: isCustomSecurity, security } = getObjectValue(current);

    return {
      ...accumulator,
      [generateColumnKey({
        id: fundId,
        name: getStringValue(security),
        prefix: ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        isCustomSecurity,
        security: getStringValue(security),
        value: null,
      } as WeightedShareValuesColumnCellData,
    };
  }, {}) as WeightedShareValuesColumn;

  // # of Shares
  const numberOfShares = shareValues?.reduce((accumulator, current) => {
    const { is_custom_security: isCustomSecurity, security, shares } = getObjectValue(current);

    return {
      ...accumulator,
      [generateColumnKey({
        id: fundId,
        name: getStringValue(security),
        prefix: ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        isCustomSecurity,
        security: getStringValue(security),
        value: getNumberValue(shares),
      } as WeightedShareValuesColumnCellData,
    };
  }, {}) as WeightedShareValuesColumn;

  // Value
  const value = shareValues?.reduce((accumulator, current) => {
    const {
      is_custom_security: isCustomSecurity,
      security,
      unrealized_value: unrealizedValue,
    } = getObjectValue(current);

    return {
      ...accumulator,
      [generateColumnKey({
        id: fundId,
        name: getStringValue(security),
        prefix: ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        isCustomSecurity,
        security: getStringValue(security),
        value: getNumberValue(unrealizedValue),
      } as WeightedShareValuesColumnCellData,
    };
  }, {}) as WeightedShareValuesColumn;

  // MOIC
  const MOIC = shareValues?.reduce((accumulator, current) => {
    const {
      invested_capital: investedCapital,
      is_custom_security: isCustomSecurity,
      security,
    } = getObjectValue(current);

    return {
      ...accumulator,
      [generateColumnKey({
        id: fundId,
        name: getStringValue(security),
        prefix: ALLOCATION_WEIGHTED_SHARE_VALUES_SPREADSHEET_COLUMN_KEY_PREFIX,
      })]: {
        investedCapital: getNumberValue(investedCapital),
        isCustomSecurity,
        security: getStringValue(security),
        value: null,
      } as WeightedShareValuesColumnCellData,
    };
  }, {}) as WeightedShareValuesColumn;

  return [weightedValuePerShare, numberOfShares, value, MOIC];
};

export default createColumns;
