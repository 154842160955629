import React from 'react';
import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import PropTypes from 'prop-types';

const GoToCapTable = ({ onClickHandler }) => (
  <Button variant="outlined" color="primary" onClick={onClickHandler} startIcon={<ArrowBack />}>
    Add Security
  </Button>
);

GoToCapTable.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
};

export default GoToCapTable;
