import React, { useContext, useEffect, useRef, useState } from 'react';
import { IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  FolderOutlined as FolderOutlinedIcon,
  MenuOutlined as MenuOutlinedIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { ConfirmationDelete, ConfirmationDialog } from 'components/Dialogs';
import { DialogContext } from 'context';
import useDocumentsContext from 'context/DocumentsContext';
import { useDocuments } from 'services/hooks';
import theme from 'theme';
import NumberOfDocsAndSubfolders from './NumberOfDocsAndSubfolders';
import VerticalMenu from './VerticalMenu';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingRight: '1rem',
  },
  folderActionIcon: {
    marginLeft: 0,
    opacity: 0.3,
    transition: 'ease opacity 1s',
    '&:hover': {
      opacity: 1,
    },
  },
  folderIcon: {
    marginRight: '0.5rem',
  },
  title: {
    color: `${theme.palette.primary[700]}`,
    fontWeight: '600',
    cursor: 'default',
  },
  expandIcon: {
    marginLeft: 'auto',
  },
  actionButton: {
    color: `${theme.palette.grey[300]}`,
    cursor: 'grab',
    transition: 'ease opacity 1s',
    '&:hover': {
      opacity: 1,
    },
  },
});

const FolderName = ({ folder, numberOfDocuments, numberOfFolders, isCurrentData, expanded }) => {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [folderName, setFolderName] = useState(null);
  const [width, setWidth] = useState(0);
  const { company_id, currentDocuments, setCompanyDocuments, setIsLoading } = useDocumentsContext();
  const { showDialog } = useContext(DialogContext);
  const { getDocuments, updateSubFolder, deleteFolder } = useDocuments();
  const stopPropagation = e => e.stopPropagation();
  const folderNameRef = useRef();

  useEffect(() => {
    setFolderName(folder?.name || 'New Folder');
  }, [folder]);

  const disableFolderNameEdition = () => {
    document.getElementById('folderName').blur();
    setEditMode(false);
  };

  useEffect(() => {
    const minWidth = 200;
    const textWidth = folderNameRef.current.offsetWidth;
    const inputWidh = textWidth > minWidth ? textWidth : minWidth;
    setWidth(inputWidh);
  }, [editMode, folderName]);

  const handleChange = e => {
    setFolderName(e.target.value);
  };

  const handleEditMode = e => {
    stopPropagation(e);
    setEditMode(true);
  };

  const refreshDocuments = async () => {
    setCompanyDocuments(await getDocuments(company_id));
  };

  const handleSaveFolderName = async () => {
    setIsLoading(true);
    const data = {
      name: folderName || '',
    };
    await updateSubFolder(currentDocuments.id, folder.id, data);
    await refreshDocuments();
    setIsLoading(false);
  };

  const deleteFolderCallback = async () => {
    setIsLoading(true);
    await deleteFolder(currentDocuments.id, folder.id, folderName);
    await refreshDocuments();
    setIsLoading(false);
  };

  const handleDeleteFolder = () => {
    const customMessage
      = 'By deleting this folder, you would bring one level up the files and folders contained in it.';
    showDialog({
      wrapper: ConfirmationDialog,
      wrapperProps: {
        title: 'Are you sure you want to permanently delete this folder?',
      },
      content: ConfirmationDelete,
      contentProps: { customMessage },
      actions: [
        {
          label: 'Delete',
          variant: 'contained',
          type: 'danger',
          callback: deleteFolderCallback,
        },
      ],
    });
  };

  const handleEnter = event => {
    if (event.key === 'Enter') {
      handleSaveFolderName();
      disableFolderNameEdition();
    } else if (event.key === 'Escape') {
      disableFolderNameEdition();
    }
  };

  const handleBlur = event => {
    setFolderName(event.target.value);
    disableFolderNameEdition();
  };

  return (
    <div className={classes.root}>
      <span style={{ position: 'absolute', opacity: 0 }} ref={folderNameRef}>
        {folderName}
      </span>
      <span className={classes.folderIcon}>
        <FolderOutlinedIcon />
      </span>
      {editMode ? (
        <TextField
          id="folderName"
          onKeyDown={handleEnter}
          value={folderName}
          onChange={handleChange}
          onBlur={handleBlur}
          onClick={stopPropagation}
          style={{ width }}
        />
      ) : (
        <Typography className={classes.title} onClick={stopPropagation}>
          {folderName}
        </Typography>
      )}
      <NumberOfDocsAndSubfolders numberOfDocuments={numberOfDocuments || 0} numberOfFolders={numberOfFolders || 0} />
      <IconButton className={classes.expandIcon}>{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
      <Tooltip title="Drag Folder" placement="top" arrow>
        <IconButton className={classes.actionButton}>
          <MenuOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      {isCurrentData && (
        <VerticalMenu
          isFolder
          stopPropagation={stopPropagation}
          editItem={handleEditMode}
          deleteItem={handleDeleteFolder}
          itemData={folder}
        />
      )}
    </div>
  );
};

FolderName.propTypes = {
  folder: PropTypes.object,
  numberOfDocuments: PropTypes.number,
  numberOfFolders: PropTypes.number,
  isCurrentData: PropTypes.bool,
  expanded: PropTypes.bool,
};

export default FolderName;
