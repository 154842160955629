/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { SYSTEM_EXPAND } from 'common/actions/row-groups/types';
import { rowsGroupsReducer } from 'common/reducers/rowsGroups';
import { useStore } from 'common/store';
import { LayoutContextValues, UseStoreValues } from 'common/types/store';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { LayoutContext } from 'context';
import {
  createColumns as futureCreateColumns,
  rowConfig as futureRowConfig,
} from 'pages/ValuationsAllocation/approaches/FutureExit/FutureEquity/config';
import { createColumns as presentCreateColumns } from 'pages/ValuationsAllocation/approaches/FutureExit/ModifiedPresentEquity/config/createColumns';
import {
  ALLOCATION_SCENARIO_METHOD_CSE,
  ALLOCATION_SCENARIO_METHOD_OPM,
  ALLOCATION_SCENARIO_METHOD_WATERFALL,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import {
  ALLOCATION_METHOD_OPTIONS_VALUES,
  ALLOCATION_METHOD_SPREADSHEET_KEYS,
  ALLOCATION_METHOD_SPREADSHEET_KEYS_REVERSE,
  AllocationMethodsMapKeys,
  AllocationMethodsMapValues,
} from 'pages/ValuationsAllocation/common/constants/futureExit';
import {
  FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD,
  FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS,
  FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS,
} from 'pages/ValuationsAllocation/common/constants/futureExit/sheetAliases';
import { ConfiguredSpreadsheets } from 'pages/ValuationsAllocation/hooks/useCreateValuation/types';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { getRiskFreeRateByMD } from 'services/hooks/allocations';
import { getNumberValue, getObjectValue, toString } from 'utillities';
import { createColumns, rowConfig as opmRowconfig } from './OPM/config';
import { AllocationMethodTableProps } from './types';
import { cellsShouldBeDisabled } from './utils/utils';
import { rowConfig as cseRowConfig } from './WaterfallCSE/config';
import FutureExitContext from '../utils/FutureExitContext';

type SourceType = typeof ALLOCATION_METHOD_SPREADSHEET_KEYS | typeof ALLOCATION_METHOD_SPREADSHEET_KEYS_REVERSE;

const getAllocationMetodSpreadSheetName = (key?: number | null, sourceData?: SourceType) => {
  const SOURCE = sourceData ?? ALLOCATION_METHOD_SPREADSHEET_KEYS;
  const keyValue = key ?? ALLOCATION_SCENARIO_METHOD_CSE;
  const spreadsheeName = SOURCE[keyValue as keyof AllocationMethodsMapKeys];
  return spreadsheeName as keyof ConfiguredSpreadsheets;
};

const AllocationMethodTable = (props: AllocationMethodTableProps) => {
  const {
    spreadsheets,
    onChange: onWorkbookChange,
    approach,
    isDisabled,
    approaches,
    financials,
  } = getObjectValue(props);
  const { resetConfiguration } = useContext(ValuationContext);
  const { allocation_method: allocationMethod } = getObjectValue(approach);

  const currentAllocationMethod = getNumberValue(allocationMethod);

  const initialState = { [FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS]: SYSTEM_EXPAND };

  const initialAllocationMethod = getAllocationMetodSpreadSheetName(currentAllocationMethod);
  const [rowGroups, setRowGroups] = useReducer(rowsGroupsReducer, initialState);
  const [initialLoad, setInitialLoad] = useState(true);
  const [allocationMethodSheet, setAllocationMethodSheet] = useState(spreadsheets[initialAllocationMethod]);

  const { currentAllocationMethodValue, setCurrentAllocationMethodValue } = useContext(FutureExitContext);

  const { allocationMethodOPMSheet } = getObjectValue(spreadsheets);
  const { tableData } = getObjectValue(allocationMethodOPMSheet);
  const { measurementDate } = getObjectValue(tableData);

  const [storeValue] = useStore() as unknown as UseStoreValues;
  const { companyInfo } = storeValue;
  const { companyExchangeRate } = useContext(LayoutContext) as unknown as LayoutContextValues;

  const updatePresentAndFutureTables = useCallback(() => {
    const { futureEquitySheet } = spreadsheets;
    const { presentValueSheet } = spreadsheets;

    const { total_cash_equivalents: totalCashEquivalents, total_debt: totalDebt } = getObjectValue(financials);
    const { captable_currency: captableCurrency, financials_currency: financialsCurrency }
      = getObjectValue(companyInfo);

    // Handling and adjusting cells if the Company has different currencies
    const isUniformCurrency = captableCurrency === financialsCurrency;

    // If is not uniform currency, multiply the values by the Company exchange rate
    const plusDebtTotal = isUniformCurrency ? totalDebt : totalDebt * getNumberValue(companyExchangeRate ?? 1);
    const lessCashTotal = isUniformCurrency
      ? totalCashEquivalents
      : totalCashEquivalents * getNumberValue(companyExchangeRate ?? 1);

    futureEquitySheet.reset({
      columns: futureCreateColumns({
        valuationsApproachFutureExitApproach: approach,
        financials,
        approaches,
      }),
      rowConfig: futureRowConfig({
        isDisabled,
        valuationsApproachFutureExitApproach: approach,
        measurementDate,
      }),
    });

    presentValueSheet.reset({
      columns: presentCreateColumns({
        valuationsApproachFutureExitApproach: approach,
        lessCashTotal,
        plusDebtTotal,
        measurementDate,
      }),
    });
  }, [spreadsheets, approach, approaches, financials, measurementDate, isDisabled, companyExchangeRate, companyInfo]);

  const changeAllocationMethodType = useCallback(
    ({ change }) => {
      const approachAllocationMethod
        = typeof change === 'number'
          ? change
          : ALLOCATION_METHOD_OPTIONS_VALUES[toString(change) as keyof AllocationMethodsMapValues];

      const newSpreadSheetName = getAllocationMetodSpreadSheetName(approachAllocationMethod);
      const newSpreadsheetConfigs = spreadsheets[newSpreadSheetName];

      Object.values(ALLOCATION_METHOD_SPREADSHEET_KEYS).forEach(() => {
        const spreadSheetName = getAllocationMetodSpreadSheetName(approachAllocationMethod);
        spreadsheets[spreadSheetName].columns[0][FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD] = {
          value: approachAllocationMethod,
        };
      });

      setCurrentAllocationMethodValue?.(newSpreadsheetConfigs);

      // Previous Allocation Method
      const previousAllocationMethodSheetName = getAllocationMetodSpreadSheetName(
        approachAllocationMethod,
        ALLOCATION_METHOD_SPREADSHEET_KEYS_REVERSE
      );

      const previousAllocationMethodSheet = spreadsheets[previousAllocationMethodSheetName];

      // Current Allocation Method Selected
      const currentAllocationMethodSheetName = getAllocationMetodSpreadSheetName(approachAllocationMethod);

      const currentAllocationMethodSheet = spreadsheets[currentAllocationMethodSheetName];

      const isOPM = ![ALLOCATION_SCENARIO_METHOD_WATERFALL, ALLOCATION_SCENARIO_METHOD_CSE].includes(
        getNumberValue(approachAllocationMethod)
      );

      const getPreviousRowConfig = isOPM ? cseRowConfig : opmRowconfig;
      const getCurrentRowConfig = isOPM ? opmRowconfig : cseRowConfig;

      const isDisabledOPMCells = cellsShouldBeDisabled({
        currentAllocationMethod: approachAllocationMethod,
        approach,
        isDisabled,
      });

      const isDisabledCSECells = isDisabled || [ALLOCATION_SCENARIO_METHOD_OPM].includes(approachAllocationMethod);

      currentAllocationMethodSheet.reset({
        rowConfig: getCurrentRowConfig({ isDisabled: isOPM ? isDisabledOPMCells : isDisabledCSECells }),
      });

      previousAllocationMethodSheet.reset({
        rowConfig: getPreviousRowConfig({ isDisabled: true }),
      });

      updatePresentAndFutureTables();
      resetConfiguration?.();
    },

    [
      spreadsheets,
      setCurrentAllocationMethodValue,
      isDisabled,
      updatePresentAndFutureTables,
      resetConfiguration,
      approach,
    ]
  );

  // Update Current Spreadsheet
  useEffect(() => {
    setAllocationMethodSheet(spreadsheets[initialAllocationMethod]);
  }, [initialAllocationMethod, isDisabled, spreadsheets]);

  useEffect(() => {
    if (currentAllocationMethodValue) {
      setAllocationMethodSheet(currentAllocationMethodValue);
    }
  }, [currentAllocationMethodValue]);

  const getRiskFreeRate = useCallback(async () => {
    const riskFreeRate = await getRiskFreeRateByMD({
      maturity: getNumberValue(approach?.maturity),
      measurementDateId: getNumberValue(measurementDate?.id),
    });

    allocationMethodSheet.reset({
      columns: createColumns({ valuationsApproachFutureExit: approach, riskFreeRate }),
    });

    updatePresentAndFutureTables();

    resetConfiguration?.();
  }, [allocationMethodSheet, approach, measurementDate, resetConfiguration, updatePresentAndFutureTables]);

  const onChange = useCallback(
    (cell, value) => {
      onWorkbookChange(cell, value);

      if (cell.alias === FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD) {
        changeAllocationMethodType({ change: value });
      }
      if (cell.alias === FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS) {
        getRiskFreeRate();
      }
    },
    [onWorkbookChange, changeAllocationMethodType, getRiskFreeRate]
  );

  useEffect(() => {
    if (initialLoad) {
      getRiskFreeRate();
      setInitialLoad(false);
    }
  }, [initialLoad, getRiskFreeRate]);

  return (
    <>
      <ScalarSpreadsheet
        {...allocationMethodSheet}
        onChange={onChange}
        sheet={allocationMethodSheet}
        rowGroups={rowGroups}
        setRowGroups={setRowGroups}
      />
      <br />
    </>
  );
};
export default AllocationMethodTable;
