/* eslint-disable max-len */
import React from 'react';

export const MIN_INVESTMENTS = 'Please add at least two investment dates.';
export const MIN_VALUE_ERROR = minValue => `The minimum value is ${minValue}.`;
export const MAX_VALUE_ERROR = maxValue => `The maximum value is ${maxValue}.`;
export const IS_REQUIRED_ERROR = 'This field is required.';
export const POSITIVE_NUMBER_ERROR = 'Use a positive number.';
export const VALUE_TYPE_ERROR = gridType => `Use a ${gridType} value type.`;
export const FIX_BEFORE_CONTINUE = 'Fix the following errors before continuing.';
export const LESS_SHARES_IN_CAPTABLE_THAN_FUND_OWNERSHIP
  = 'The sum of shares is less than the total shares in the Fund Ownership.';
export const WEIGHTING_PROBABILITIES_GREATER_THAN_100 = 'The sum of weighting probabilities is greater than 100%';
export const WEIGHTING_PROBABILITIES_LOWER_THAN_100 = 'The sum of weighting probabilities is lower than 100%';
export const WEIGHTING_PROBABILITY_GREATER_THAN_100 = 'This weighting probability is greater than 100%';
export const TOTAL_WEIGHT_NOT_100 = 'Total weight should be 100%';
export const NEGATIVE_OUTSTANDINGS = 'It seems you are trying to sell more shares than what you have purchased';
export const SCENARIO_NOT_WEIGHTED = 'Please note that this scenario is not weighted';
export const GREATER_THAN = minValue => `This value should be greater than ${minValue}.`;
export const EQUAL_OR_GREATER_THAN = minValue => `This value should be equal to or greater than ${minValue}.`;
export const HAS_WHITE_SPACES = 'has whitespaces.';
export const SIMILAR_TO = field => `is too similar to ${field}.`;
export const IS_REQUIRED = 'is required.';
export const REQUIRE_CHARACTERS = characters => `requires at least ${characters} characters.`;
export const ONLY_LETTERS_AND_NUMBERS = 'can only contain letters, numbers and "." or "-".';
export const ONLY_MAIL = 'should be an email address.';
export const REQUIRE_ONE_LETTER = 'requires at least one letter.';
export const SHARES_EXCEEDS_CAPTABLE_SHARES
  = 'The number of shares for the selected security exceeds the number of shares in the Cap Table.';
export const ERROR_OCCURRED = 'An error has occurred, try again later.';
export const ACCOUNT_ACTIVATED
  = 'Account activated. If someone invited you to use the app, please reset your password first.';
export const PASSWORD_EMPTY = field_name => `${field_name} is empty.`;
export const PASSWORD_MISMATCH = 'Passwords do not match.';
export const PASSWORD_CHANGE_CONFIRM = 'Your password has been changed. A message was sent to your email address.';
export const PASSWORD_USER_OR_TOKEN_NOT_FOUND = 'Password reset link is not valid.';
export const PASSWORD_URL_USED = 'The password reset link is not valid or was already used.';
export const PASSWORD_CHANGE_SEND_MAIL = 'A message was sent to your email address.';
export const PASSWORD_RESET_FAILED = 'Unable to send you a password reset link';
export const UNABLE_TO_LOGIN_TRY_AGAIN = 'Unable to login, please try again.';
export const PASSWORD_ERROR_MESSAGE
  = 'At least 9 characters are required, and must include a letter, a number, and a special character.';
export const SUCCESS_ACCOUNT_REGISTRATION = 'An activation message was sent to your email.';
export const INVALID_DATA = 'The data provided is not valid, please review the information to proceed.';
export const REDIRECT_TO = (page, seconds) => (
  <p>
    You will be redirected to the {page} in:
    <br />
    <strong>{seconds} seconds</strong>
  </p>
);
export const ACCOUNT_CREATED = 'Account Created';
export const ACCOUNT_ERROR = 'Account Error';
export const EMAIL_SENT = 'Email Sent';
export const EMAIL_NOT_SENT = 'Email Not Sent';
export const CONFIRMATION_EMAIL_SENT = 'Check your email, a new confirmation email was sent';
export const CONFIRMATION_EMAIL_ERROR = 'An error occurred while sending the confirmation email';
export const MISSING_MD_TITLE = 'No measurement date is associated with this company yet';
export const MISSING_MD_MESSAGE = 'Please add a measurement date first';
export const NO_RECORDS = 'There are no records to show';
export const NO_VALID_RECORDS = 'There are no records in use to show';
export const LOWER_VALUE_THAN_PREVIOUS = columnType => `This value is lower than in the previous ${columnType}`;
export const REPLACE_DEFAULT_VALUE = 'Replace the default value.';
export const SELECT_VALID_SECURITY = 'Select a valid security type.';
export const SECURITY_SAME_NAME = 'There is another security with the same name';
export const INFINITY_ERROR = 'Infinity is not a valid number';
export const MAX_DIGIT_ERROR = digits => `This field has a maximum of ${digits} digits`;
export const MAX_CHAR_ERROR = digits => `This field has a maximum of ${digits} characters`;
export const WRONG_USERNAME_OR_PASSWORD = 'Wrong username or password';
export const NEW_MEASUREMENT_DATE = 'New Measurement Date';
export const DELETE_MEASUREMENT_DATE = 'Remove Measurement Date';
export const DELETE_MEASUREMENT_DATE_CONFIRMATION = selectedItems =>
  `Are you sure you want to remove the measurement date from the ${selectedItems}?`;
export const CREATE_USER = 'Create User';
export const USER_FIRMS = 'User Firms';
export const DIALOG_TITLES_NOT_GRID = [NEW_MEASUREMENT_DATE, DELETE_MEASUREMENT_DATE, USER_FIRMS];
export const TOO_MANY_REQUESTS = seconds => `Too many attempts. Please try again in ${seconds} seconds.`;
export const UNCONTROLLED_ERROR = 'Something went wrong!';
export const INVALID_EXPRESSION = 'Invalid Expression';
export const DOCUMENT_UPLOAD_SUCCESS = fileName => `${fileName} was uploaded successfully.`;
export const DOCUMENT_UPLOAD_ERROR = fileName =>
  `An error occurred while uploading '${fileName}'. Please ensure it is a valid file type and that the file name does not contain any special characters.`;
export const DOCUMENT_DELETE_SUCCESS = fileName => `${fileName} was deleted successfully.`;
export const DOCUMENT_DELETE_ERROR = fileName => `An error occurred while deleting ${fileName}`;
export const DOCUMENT_DOWNLOAD_SUCCESS = fileName => `${fileName} was downloaded successfully.`;
export const DOCUMENT_DOWNLOAD_ERROR = fileName => `An error occurred while downloading ${fileName}`;
export const DOCUMENT_UPDATED_SUCCESS = fileName => `${fileName} was updated successfully.`;
export const DOCUMENT_UPDATED_ERROR = fileName => `An error occurred while updating ${fileName}`;
export const DOCUMENT_REFERENCES_UPDATED_SUCCESS = fileName =>
  `The references for ${fileName} was updated successfully.`;
export const FOLDER_CREATED_SUCCESS = folderName => `${folderName} was created successfully.`;
export const FOLDER_CREATED_ERROR = folderName => `An error occurred while creating ${folderName}`;
export const FOLDER_UPDATED_SUCCESS = folderName => `${folderName} was updated successfully.`;
export const FOLDER_DELETED_SUCCESS = folderName => `Folder ${folderName} was deleted successfully`;
export const FOLDER_DELETE_ERROR = folderName => `An error occurred while deleting ${folderName}`;
export const FOLDER_UPDATED_ERROR = folderName => `An error ocurred while updating ${folderName}`;
export const REFERENCE_ADDED_SUCCESS = 'The reference was added successfully.';
export const REFERENCE_ADDED_ERROR = 'An error occurred while adding the reference.';
export const REFERENCE_DELETED_SUCCESS = 'The reference was deleted successfully.';
export const REFERENCE_DELETED_ERROR = 'An error occurred while deleting the reference.';
export const MOVE_FILE_SUCCESS = 'The file was moved successfully.';
export const MOVE_FILE_ERROR = 'An error occurred while moving the file.';
export const FILE_ALREADY_EXISTS = 'The file already exists.';
export const DOCUMENT_REQUEST_SENT_SUCCESS = 'The request was sent successfully.';
export const DOCUMENT_REQUEST_SENT_ERROR = 'An error occurred while sending the request.';
export const FORM_IS_NOT_VALID = 'The form is not valid.';
export const FUND_DELETED_ERROR = 'An error occurred while deleting the fund.';
export const FUND_DELETED_SUCCESS = 'The fund was deleted successfully.';
export const DECIMALS_NOT_ALLOWED = 'Decimal numbers are not allowed';
export const GET_EXCHANGE_RATE_ERROR = 'Failed to get exchange rate';
export const ERROR_CLASSNAME = 'error';
