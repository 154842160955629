import React, { useState } from 'react';
import { IconButton, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { DeleteOutlined as DeleteIcon, EditOutlined as EditIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import theme from 'theme';

const useStyles = makeStyles({
  moreVertIcon: {
    borderLeft: 'solid 1px #eeeeee',
  },
  actionButton: {
    margin: `-${theme.spacing(2)}px 0`,
  },
});

const VerticalMenu = ({ itemData, stopPropagation, isFolder = false, deleteItem, editItem }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = event => {
    if (stopPropagation) {
      stopPropagation(event);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`${classes.moreVertIcon} morevert-icon-container`}>
      <IconButton
        aria-label="more"
        aria-haspopup="true"
        component="span"
        onClick={handleMenuClick}
        className="approach_bottom">
        <MoreVertIcon fontSize="small" className="morevert-icon" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'more',
        }}>
        <MenuItem onClick={e => editItem(e, itemData)}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{`Edit ${isFolder ? 'Folder Name' : 'Document'}`}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => deleteItem(itemData)}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{`Delete ${isFolder ? 'Folder' : 'Document'}`}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

VerticalMenu.propTypes = {
  itemData: PropTypes.object,
  stopPropagation: PropTypes.func,
  isFolder: PropTypes.bool,
  deleteItem: PropTypes.func,
  editItem: PropTypes.func,
};

export default VerticalMenu;
