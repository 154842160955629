import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import useDocumentsContext from 'context/DocumentsContext';
import { useDocumentsDragAndDrop } from 'services/hooks';
import useFolderValidations from './useFolderValidations';
import { columns } from '../../data/colConfig';
import DocumentsTable from '../DocumentsTable';
import FolderName from '../FolderName';

const useStyles = makeStyles(theme => ({
  accordionDetails: {
    padding: '0px 0px 0px 1rem',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  accordionItem: {
    '&.Mui-expanded': {
      margin: '0px',
    },
  },
  accordionSummary: {
    borderBottom: `0.18rem solid ${theme.palette.primary[700]}`,
    padding: 0,
    backgroundColor: 'transparent',
    '&.Mui-focused': {
      backgroundColor: 'inherit',
    },
  },
  title: {
    color: `${theme.palette.primary[700]}`,
    fontWeight: '600',
  },
}));

const Folders = ({ documentId, documentsFolders, isCurrentData, measurementDate }) => {
  const classes = useStyles();
  const validations = useFolderValidations();
  const { selectedMeasurementDate } = useDocumentsContext();
  const { handleDragStart, handleDragEnd, handleDragOver, handleDragLeave, handleDrop } = useDocumentsDragAndDrop({
    validations,
    selectedMeasurementDateId: selectedMeasurementDate.id,
  });
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  const folderListRender = folders => (
    <AccordionDetails className={classes.accordionDetails}>
      {folders.map(folder => (
        <div
          key={folder.id}
          data-id={folder.id}
          className="droppable"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={event => handleDrop(event, folder)}>
          <Accordion
            className={classes.accordionItem}
            onChange={(__event, expanded) => setIsAccordionExpanded(expanded)}>
            <AccordionSummary
              id="folder-header"
              draggable
              className={`folder draggable ${classes.accordionSummary}`}
              onDragStart={event => handleDragStart(event, folder)}
              onDragEnd={handleDragEnd}>
              <FolderName
                folder={folder}
                numberOfDocuments={folder.files?.length || null}
                numberOfFolders={folder.subFolders?.length || null}
                isCurrentData={isCurrentData}
                expanded={isAccordionExpanded}
              />
            </AccordionSummary>
            <AccordionDetails style={{ padding: '0' }}>
              <DocumentsTable
                columns={columns}
                rows={folder.files}
                isCurrentData={isCurrentData}
                documentId={folder.documentId}
                measurementDateObject={folder.measurementDate}
                measurementDate={measurementDate}
              />
            </AccordionDetails>
            {!isEmpty(folder.subFolders) && (
              <Folders
                documentsFolders={folder.subFolders}
                isCurrentData={isCurrentData}
                documentId={documentId}
                measurementDate={measurementDate}
              />
            )}
          </Accordion>
        </div>
      ))}
    </AccordionDetails>
  );

  return folderListRender(documentsFolders);
};

Folders.propTypes = {
  documentId: PropTypes.number,
  documentsFolders: PropTypes.arrayOf(PropTypes.shape({})),
  isCurrentData: PropTypes.bool,
};

export default Folders;
