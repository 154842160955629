import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PropTypes from 'prop-types';
import { ExtendedFabButton } from 'components/Buttons';

const AddCompButton = ({ openDialog, addComp }) => (
  <ExtendedFabButton
    id="add-comp-btn"
    label="Add Comp Group"
    customIcon={openDialog ? ArrowDropUpIcon : ArrowDropDownIcon}
    onClick={addComp}
  />
);

AddCompButton.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  addComp: PropTypes.func.isRequired,
};

export default AddCompButton;
