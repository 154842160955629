import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { isEmpty, isEqual, isNil, isUndefined } from 'lodash';
import moment from 'moment';
import { SYSTEM_COLLAPSE, SYSTEM_EXPAND } from 'common/actions/row-groups/types';
import { ERROR_403 } from 'common/config/api';
import {
  AGGREGATE_VALUE_ALIAS,
  ALLOCATION_FINAL_MESSAGE,
  BACKSOLVE,
  FORBIDDEN_ALLOCATION_INFO,
  FUTURE_EXIT_INPUTS_ALIAS,
  FUTURE_VALUE_ALIAS,
  GET_ALLOCATION_INFO_ACCESS,
  NO_EQUITY_ALLOCATION_AVAILABLE,
  OPTION_PRICING_ALIAS,
  PRESENT_VALUE_ALIAS,
  SAVE_DRAFT_BEFORE_PUBLISHING,
} from 'common/constants/allocations';
import {
  CAP_TABLE_NEEDS_MORE_SECURITIES_TAGLINE,
  CAP_TABLE_NEEDS_MORE_SECURITIES_TITLE,
} from 'common/constants/cap-table';
import { ALLOCATIONS_REFERENCE_TYPE } from 'common/constants/documents';
import { FIX_BEFORE_CONTINUE } from 'common/constants/messages/validations';
import { ALLOCATIONS_PAGE_KEY } from 'common/constants/notes';
import { COMMON_STOCK, PREFERRED_STOCK } from 'common/constants/securityTypes';
import { useFormat } from 'common/hooks';
import { rowsGroupsReducer } from 'common/reducers/rowsGroups';
import { useStore } from 'common/store';
import { Alert, AllocationFinalMessage, CenterAlignedParagraph, Widgets } from 'components';
import { ConfirmationDialog } from 'components/Dialogs';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import { GridSkeleton } from 'components/Grid';
import MessageBox from 'components/MessageBox';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import { DialogContext, LayoutContext } from 'context';
import AllocationContext from 'context/AllocationContext';
import NewVersionForm from 'pages/Allocation/allocations/components/NewVersionForm';
import {
  ConfirmationAllocationSaveFinal,
  getReferences,
  shouldUpdateScenario,
} from 'pages/Allocation/allocations/utilities';
import getSecurityNamesList from 'pages/Allocation/allocations/utilities/getSecurityNamesList';
import { getPresentValues } from 'pages/Allocation/allocations/utilities/util';
import Conclusions from 'pages/Allocation/conclusions/Conclusions';
import useLoadAllocationSheetConfiguration from 'pages/Allocation/hooks/useLoadAllocationSheetConfiguration';
import WeightedSharesTables from 'pages/Allocation/weighted-share-value/WeightedSharesTables';
import { EmptyCapTableMessage } from 'pages/CapTable/cap-table/components';
import {
  useCreateVersion,
  usePublishAllocation,
  useUpdateAllocationAndScenariosInfo,
  useUpdateScenarioValues,
} from 'services/hooks/allocation';
import { useNotes } from 'services/hooks/notes';
import { useAllocationValuesStore } from 'store';
import { getAllocationStatus, getArrayValue, getLedgerKey, getNumberValue } from 'utillities';
import { AddScenarioButton, NeedsFundOwnershipFirst } from './components';
import AllocationsPropTypes from './types';
import WorkbookContext from '../../../components/ScalarSpreadsheet/utilities/WorkbookContext';
import {
  ALLOCATION_CONCLUSION_TITLE_HEADER_ALIAS,
  ALLOCATION_CONCLUSION_TOTAL_VALUE_COL,
} from '../conclusions/constants';
import useAllocationStyles from '../styles/Allocations';

const defaultValues = {
  name: moment().format('MM/DD/YYYY'),
};

const defaultVersionFormState = {
  values: {
    ...defaultValues,
  },
  isValid: false,
  dbErrors: {},
  errors: {},
};

const initialTableData = {};

const initialState = {
  [FUTURE_EXIT_INPUTS_ALIAS]: SYSTEM_COLLAPSE,
  [OPTION_PRICING_ALIAS]: SYSTEM_COLLAPSE,
  [AGGREGATE_VALUE_ALIAS]: SYSTEM_EXPAND,
  [FUTURE_VALUE_ALIAS]: SYSTEM_COLLAPSE,
  [PRESENT_VALUE_ALIAS]: SYSTEM_EXPAND,
};

const Allocation = ({
  allocationInfo,
  capTableList,
  getVersions,
  identifier,
  isForbidden,
  ltmData,
  multiples,
  ntmData,
  primaryCaptable,
  scenarios,
  selectedMeasurementDate,
  setAllocationInfo,
  valuationInfo,
  weightedSV,
}) => {
  const [isDisabled, setIsDisabled] = useState(allocationInfo?.is_final || false);
  const {
    spreadsheets,
    addScenario,
    columns,
    setColumns,
    persistentDeletedColumns,
    deleteScenario,
    cloneColumn,
    weightedShareValuesByFund,
    uniqueSecurityNames,
    allocationFieldAttrs,
    measurementDates,
  } = useLoadAllocationSheetConfiguration({
    scenarios,
    allocationInfo,
    primaryCaptable,
    valuationInfo,
    weightedShareValues: weightedSV,
    capTableList,
    isDisabled,
  });
  const { notes, setNotes, notesHasChanged, saveNotes, onAddNote, onUpdateNotes, onDeleteNote } = useNotes();
  const { onChange, cells, data, areCellsValid, workbook, workbookContextValue } = useWorkbook(spreadsheets);

  const { setShowAlert, setAlertMessage } = useContext(LayoutContext);

  const classes = useAllocationStyles();
  const [{ isShowLoadingProgress, companyInfo }] = useStore();

  const [newVersionInfo, createVersion] = useCreateVersion();
  const [referencedValues, setReferencedValues] = useState();
  const [openNewVersionForm, setOpenNewVersionForm] = useState(false);
  const { setPageActions } = useContext(LayoutContext);
  const [versionFormState, setVersionFormState] = useState(defaultVersionFormState);
  const [, setUpdatingAllocation] = useState(false);
  const [updatedAllocationAndScenarios, updateAllocationAndScenarios] = useUpdateAllocationAndScenariosInfo();
  const { financialExchangeRate } = useContext(LayoutContext);
  const [publishAllocation] = usePublishAllocation();
  const { showDialog } = useContext(DialogContext);
  const { updateScenarioValues } = useUpdateScenarioValues();
  const [weightedSharesValues, setWeightedSharesValues] = useState(weightedSV);
  const [tableData, setTableData] = useState(initialTableData);
  const [rowGroups, setRowGroups] = useReducer(rowsGroupsReducer, initialState);

  const [format, formatDispatch] = useFormat({
    page: 'captable',
    sourceCurrency: companyInfo?.financials_currency,
    units: REGULAR_UNIT,
  });

  // Allocation Values Store
  const setCurrentAllocationStatus = useAllocationValuesStore(state => state.setCurrentAllocationStatus);
  const setCurrentAllocationVersion = useAllocationValuesStore(state => state.setCurrentAllocationVersion);
  const setCurrentEquityValue = useAllocationValuesStore(state => state.setCurrentEquityValue);
  const setCurrentFirmTotal = useAllocationValuesStore(state => state.setCurrentFirmTotal);

  const getPresentValuesFromSSVLedgerCell = useCallback(({ columnLegend }, cells) => {
    const ledgerData = cells?.[getLedgerKey(columnLegend)].SSV;
    if (ledgerData?.share_values) {
      const { share_values } = ledgerData;
      return share_values.map(shareValue => ({
        security: {
          id: shareValue.security,
          name: shareValue.name,
        },
        price: shareValue.share_price,
        value: shareValue.share_price,
      }));
    }
  }, []);

  const updateWeightedShareValues = useCallback(
    (scenarios, scenarioIndex) => {
      if (!cells) {
        return;
      }
      Object.values(cells.allocation)
        .filter(
          ({ alias, columnLegend }) =>
            alias === 'aggregateTotal' && columnLegend === scenarios[scenarioIndex].columnLegend
        )
        .forEach(cell => {
          onChange(cell, scenarios);
        });
    },
    [cells, onChange]
  );

  const getCurrentEquityValue = useCallback(() => {
    const reduceValue = (sofar, { value, columnLegend }) => {
      // eslint-disable-next-line no-param-reassign
      sofar[columnLegend] = value;
      return sofar;
    };
    if (cells?.allocation && allocationFieldAttrs?.current_equity_value) {
      const equityValueCells = Object.values(cells.allocation)
        .filter(({ alias }) => alias === 'equity_value')
        .reduce(reduceValue, {});
      const weightingCells = Object.values(cells.allocation)
        .filter(({ alias }) => alias === 'weighting_probability')
        .reduce(reduceValue, {});
      // match up the equity value cells with the weighting cells based on their keys and multiplying their values, then sum the total
      const currentEquityValue = Object.keys(equityValueCells).reduce((sofar, key) => {
        if (weightingCells[key]) {
          // convert the equityValueCells[key] to a number if it's a string and same with the weightingCells[key]
          // eslint-disable-next-line no-param-reassign
          sofar += Number(equityValueCells[key]) * Number(weightingCells[key]);
        }
        return sofar;
      }, 0);
      return currentEquityValue.toFixed(allocationFieldAttrs.current_equity_value.decimal_places);
    }
    return 0;
  }, [cells, allocationFieldAttrs]);

  const updateCurrentEquityValue = useCallback(() => {
    const currentEquityValue = getCurrentEquityValue();

    setTableData(state => ({
      ...state,
      current_equity_value: currentEquityValue,
    }));
  }, [getCurrentEquityValue]);

  useEffect(() => {
    setWeightedSharesValues(weightedSV);
  }, [weightedSV]);

  useEffect(() => {
    if (selectedMeasurementDate) {
      setTableData({
        ...initialTableData,
        date: selectedMeasurementDate.date,
        current_equity_value: getCurrentEquityValue(),
      });
    }
  }, [selectedMeasurementDate, getCurrentEquityValue]);

  const afterCaptableChange = useCallback(
    (cell, reversed) => {
      if (cell.alias === 'cap_table' && cell.value.id && reversed) {
        const securityNames = getSecurityNamesList(reversed);
        // compare the set of security names with the set of uniqueSecurityNames to see if they are different
        const isDifferent = !isEqual(securityNames?.sort(), uniqueSecurityNames?.sort());
        if (isDifferent) {
          // if they are different, then set the uniqueSecurityNames to the security names from the captable
          setColumns([...reversed]);
        }
      }
    },
    [uniqueSecurityNames, setColumns]
  );

  const onWorkbookChange = useCallback(
    (cell, value) => {
      const previousColumns = columns?.map(column => ({ ...column })) || [];
      const reversedData = onChange(cell, value);
      afterCaptableChange(cell, reversedData?.[0]);
      updateCurrentEquityValue();
      const index = columns?.findIndex(({ columnRef }) => columnRef === cell.columnRef);
      if (isUndefined(index) || index === -1) {
        return;
      }
      if (!columns) {
        return;
      }
      const column = columns[index];
      const previousColumn = previousColumns[index];

      const {
        scenario_method,
        cap_table,
        equity_value,
        exit_date,
        discount_rate,
        scenario_type,
        maturity,
        volatility,
        total_aggregate_value,
        columnLegend,
      } = column;

      if (scenario_type.toString() !== BACKSOLVE.toString()) {
        updateScenarioValues({
          scenarioMethod: scenario_method.toString(),
          capTable: cap_table,
          scenarioIndex: index,
          equityValue: equity_value,
          exitDate: exit_date,
          discountRate: discount_rate,
          scenarioType: scenario_type,
          maturity: maturity ?? 0,
          volatility: volatility ?? 0,
          totalAggregateValue: total_aggregate_value,
          columnLegend,
          presentValues: getPresentValues(scenario_method, getPresentValuesFromSSVLedgerCell, column, cells),
          scenarios: columns,
          updateWeightedShareValues,
          tryToUpdate: shouldUpdateScenario({
            prevScenario: previousColumn,
            currentScenario: column,
          }),
        });
      } else {
        const scenario_values = cells?.allocation[`LEDGERS_${columnLegend}`]?.distributionValues;
        if (scenario_values) {
          const tmpColumn = { ...column, scenario_values };
          // create a new array of tmpColumns that consist of the current columns and the tmpColumn replacing it's matching column
          const tmpColumns = columns.map(c => (c.columnRef === tmpColumn.columnRef ? tmpColumn : c));
          updateWeightedShareValues(tmpColumns, index);
        }
      }
    },
    [
      cells,
      columns,
      getPresentValuesFromSSVLedgerCell,
      onChange,
      updateScenarioValues,
      updateWeightedShareValues,
      updateCurrentEquityValue,
      afterCaptableChange,
    ]
  );

  const isValidCaptable = useMemo(() => {
    if (primaryCaptable?.securities) {
      const { securities } = primaryCaptable;
      const validSecuritiesForBreakpoints = securities.filter(security =>
        [COMMON_STOCK, PREFERRED_STOCK].includes(security.security_type)
      );
      return validSecuritiesForBreakpoints.length > 0;
    }
  }, [primaryCaptable]);

  const generalDisableCondition = useMemo(
    () => isShowLoadingProgress || isDisabled || !isValidCaptable,
    [isShowLoadingProgress, isDisabled, isValidCaptable]
  );

  const customSecurities = useMemo(
    () => primaryCaptable?.fund_ownership?.fund_ownership_detail?.filter(fod => !fod.security) || [],
    [primaryCaptable]
  );

  const isValidCustomSecurities = useMemo(() => customSecurities.length > 0, [customSecurities]);

  const hasSecurities = useMemo(
    () => isValidCaptable || isValidCustomSecurities,
    [isValidCaptable, isValidCustomSecurities]
  );

  // should essentially cause a re-render of the Allocation component
  useEffect(() => {
    if (!isEmpty(newVersionInfo)) {
      getVersions(newVersionInfo);
    }
  }, [getVersions, newVersionInfo]);

  // Update the visible columns when a column is deleted or added
  useEffect(() => {
    if (identifier && columns) {
      const allocationDate = allocationInfo?.date;
      const tmpReferencedValues = getReferences({
        columns,
        capTables: capTableList,
        allocationDate,
      });
      setReferencedValues(tmpReferencedValues);
    }
  }, [identifier, columns, allocationInfo, capTableList]);

  const closeNewVersionForm = useCallback(() => {
    setOpenNewVersionForm(false);
    setVersionFormState(defaultVersionFormState);
  }, []);

  const saveAsNewVersion = useCallback(() => {
    const propsToDelete = ['created_at', 'updated_at', 'slug'];

    const allocationVersion = {
      ...allocationInfo,
      current_equity_value: tableData.current_equity_value,
    };
    const { values } = versionFormState;

    propsToDelete.forEach(prop => {
      if (allocationVersion[prop]) {
        delete allocationVersion[prop];
      }
    });

    allocationVersion.name = values?.name;
    allocationVersion.is_final = false;

    if (columns?.length) {
      allocationVersion.allocation_scenarios = columns.map(column => ({
        ...column,
        backsolve_valuation: column.backsolve_valuation,
        specified_share_values: column.specified_share_values,
        future_exit: column.future_exit,
        cap_table: column.cap_table.id,
      }));
    }

    if (allocationVersion.fund_conclusion?.length) {
      allocationVersion.fund_conclusion = allocationVersion.fund_conclusion.map(conclusion => ({
        ...conclusion,
        id: 0,
      }));
    }

    setVersionFormState(defaultVersionFormState);
    // calling hook will update newVersionInfo, and that will trigger a series of effects
    createVersion(allocationVersion);
  }, [allocationInfo, columns, createVersion, versionFormState, tableData]);

  const saveConfirmation = useCallback(
    callback => {
      if (allocationInfo.is_final) {
        showDialog({
          wrapper: ConfirmationDialog,
          wrapperProps: {
            title: 'Confirmation',
          },
          content: ConfirmationAllocationSaveFinal,
          actions: [
            {
              label: 'Cancel',
              variant: 'text',
            },
            {
              label: 'Save',
              variant: 'contained',
              color: 'primary',
              callback,
            },
          ],
        });
      }
    },
    [allocationInfo.is_final, showDialog]
  );

  const saveAllocations = useCallback(
    (allocationsData, isNewVersion = false) => {
      if (!areCellsValid(false, true)) {
        setShowAlert(true);
        setAlertMessage(FIX_BEFORE_CONTINUE);
        return;
      }

      setShowAlert(false);
      let data = allocationsData || allocationInfo;
      data = {
        ...data,
        current_equity_value: tableData.current_equity_value,
      };
      data.allocation_scenarios = [...columns];
      const noScenarios = isEmpty(data?.allocation_scenarios);
      const noSecurities = !isValidCustomSecurities && !isValidCaptable;
      data.weighted_share_values = noScenarios && noSecurities ? [] : weightedSharesValues;
      data.fund_conclusion = noScenarios && noSecurities ? [] : allocationInfo.fund_conclusion;

      if (isNewVersion) {
        saveAsNewVersion();
      } else {
        if (!isEmpty(notes) && notesHasChanged) {
          saveNotes();
        }

        if (allocationInfo.is_final) {
          saveConfirmation(() => {
            setUpdatingAllocation(true);
            updateAllocationAndScenarios(
              allocationInfo.id,
              data,
              [...columns, ...persistentDeletedColumns],
              financialExchangeRate,
              setColumns
            )
              .then(() => setUpdatingAllocation(false))
              .catch(error => {
                setUpdatingAllocation(false);
                throw new Error(error);
              });
          });
        } else {
          setUpdatingAllocation(true);
          updateAllocationAndScenarios(
            allocationInfo.id,
            data,
            [...columns, ...persistentDeletedColumns],
            financialExchangeRate,
            setColumns
          )
            .then(() => setUpdatingAllocation(false))
            .catch(error => {
              setUpdatingAllocation(false);
              throw new Error(error);
            });
        }
      }
    },
    [
      isValidCaptable,
      isValidCustomSecurities,
      allocationInfo,
      areCellsValid,
      columns,
      financialExchangeRate,
      saveAsNewVersion,
      setColumns,
      updateAllocationAndScenarios,
      weightedSharesValues,
      persistentDeletedColumns,
      setAlertMessage,
      setShowAlert,
      notes,
      notesHasChanged,
      saveNotes,
      tableData,
      saveConfirmation,
    ]
  );

  useEffect(() => {
    if (!isEmpty(updatedAllocationAndScenarios)) {
      setAllocationInfo(updatedAllocationAndScenarios);
    }
  }, [updatedAllocationAndScenarios, setAllocationInfo]);

  const confirmAction = useCallback(
    (action, message, callback) => {
      showDialog({
        wrapper: ConfirmationDialog,
        content: CenterAlignedParagraph,
        contentProps: { message },
        actions: [
          {
            label: 'Cancel',
            type: 'cancel',
          },
          {
            label: action,
            variant: 'contained',
            type: 'success',
            callback,
          },
        ],
      });
    },
    [showDialog]
  );

  const publish = useCallback(() => {
    const callback = () => publishAllocation(allocationInfo.id);
    confirmAction('Publish', 'Are you sure you want to publish this allocation?', callback);
  }, [allocationInfo, confirmAction, publishAllocation]);

  const makeFinal = useCallback(() => {
    const callback = () =>
      // eslint-disable-next-line implicit-arrow-linebreak
      saveAllocations({
        ...allocationInfo,
        is_final: true,
      });

    confirmAction('Make Final', 'Are you sure you want to make this allocation final?', callback);
  }, [allocationInfo, confirmAction, saveAllocations]);

  const noScenarios = useMemo(
    () => (isValidCaptable ? 'The Allocation table is empty. Add default scenarios' : NO_EQUITY_ALLOCATION_AVAILABLE),
    [isValidCaptable]
  );

  const displayWithoutScenarios = useMemo(
    () => !columns?.length && data && isValidCustomSecurities,
    [columns, data, isValidCustomSecurities]
  );

  // Set page actions
  useEffect(() => {
    if (!isEmpty(allocationInfo) && (!isEmpty(columns) || displayWithoutScenarios)) {
      // if there is at least one column with id equal to 0, then the allocation wasn't saved yet
      const isDraft = columns?.some(column => column.id === 0);

      setPageActions({
        mainAction: {
          id: 'allocations',
          label: 'Save',
          isActive: isDisabled,
          callback: () => saveAllocations(),
          isLock: allocationInfo.is_final,
        },
        secondaryActions: [
          {
            label: 'Save as a New Version',
            isActive: !isEmpty(columns) || isValidCustomSecurities,
            callback: () => setOpenNewVersionForm(true),
          },
          !allocationInfo.is_final && {
            label: 'Publish',
            isActive: (!isEmpty(columns) && !allocationInfo.is_published) || isValidCustomSecurities,
            callback: publish,
            disabled: isDraft,
            tooltip: isDraft && SAVE_DRAFT_BEFORE_PUBLISHING,
          },
          {
            label: 'Make Final',
            isActive: allocationInfo?.is_published && !allocationInfo.is_final,
            callback: makeFinal,
          },
        ],
      });
    }
    return () => setPageActions(null);
  }, [
    displayWithoutScenarios,
    isValidCustomSecurities,
    columns,
    allocationInfo,
    setPageActions,
    publish,
    makeFinal,
    saveAllocations,
    isDisabled,
  ]);

  const AllocationContextData = useMemo(
    () => ({
      multiples,
      ltmData,
      ntmData,
      measurementDate: selectedMeasurementDate,
      measurementDateId: selectedMeasurementDate?.id,
      valuationInfo,
      approachList: valuationInfo?.valuations_approaches,
    }),
    [valuationInfo, multiples, ltmData, ntmData, selectedMeasurementDate]
  );

  const showFundConclusion = useMemo(() => {
    const showConclusion = !isEmpty(weightedShareValuesByFund) && !isEmpty(cells);
    return isValidCustomSecurities ? !isEmpty(weightedShareValuesByFund) : showConclusion;
  }, [weightedShareValuesByFund, cells, isValidCustomSecurities]);

  const allocationStatus = useMemo(() => {
    const { is_published: isPublished, is_final: isFinal } = allocationInfo;

    return getAllocationStatus({ isFinal, isPublished });
  }, [allocationInfo]);

  const forbiddenAccess = useMemo(
    () => isForbidden || isEqual(allocationInfo, ERROR_403),
    [allocationInfo, isForbidden]
  );

  const fundOwnershipDetailIsEmpty = useMemo(
    () => getArrayValue(primaryCaptable?.fund_ownership?.fund_ownership_detail).length === 0,
    [primaryCaptable]
  );

  const captableNeedsMoreSecurities = useMemo(
    () => primaryCaptable?.securities && !hasSecurities && !displayWithoutScenarios,
    [displayWithoutScenarios, hasSecurities, primaryCaptable]
  );

  const allocationTableIsEmpty = useMemo(
    () =>
      !columns?.length
      && !displayWithoutScenarios
      && getArrayValue(primaryCaptable?.fund_ownership?.fund_ownership_detail)?.length > 0,
    [columns, displayWithoutScenarios, primaryCaptable]
  );

  const allocationIsEmpty = useMemo(
    () =>
      companyInfo?.id
      && (forbiddenAccess || fundOwnershipDetailIsEmpty || captableNeedsMoreSecurities || allocationTableIsEmpty),
    [allocationTableIsEmpty, captableNeedsMoreSecurities, companyInfo, forbiddenAccess, fundOwnershipDetailIsEmpty]
  );

  const allocationVersion = useMemo(() => {
    if (allocationInfo?.company_measurement_date === selectedMeasurementDate?.cmd_id)
      return getNumberValue(allocationInfo?.version);

    return null;
  }, [allocationInfo, selectedMeasurementDate]);

  const currentEquityValue = useMemo(() => tableData?.current_equity_value ?? null, [tableData]);

  const fundsConclusionTotalValue = useMemo(() => {
    const conclusionsCells = cells?.conclusions;

    if (isEmpty(conclusionsCells)) return null;

    return (
      Object.values(conclusionsCells)
        // Ignore the Title Header and only iterate over the Total Value column
        .filter(
          cell =>
            cell.alias !== ALLOCATION_CONCLUSION_TITLE_HEADER_ALIAS
            && cell.colKey === ALLOCATION_CONCLUSION_TOTAL_VALUE_COL
        )
        .reduce((accumulator, current) => accumulator + Number(current?.value ?? 0), 0)
    );
  }, [cells]);

  // Updating Allocation Values
  useEffect(() => {
    if (data && !allocationIsEmpty) {
      // Set current Allocation Version
      setCurrentAllocationVersion?.(allocationVersion);
      // Set current Allocation Status
      if (!isNil(allocationStatus)) setCurrentAllocationStatus?.(allocationStatus);
      // Set current Equity Value
      if (!isNil(currentEquityValue)) setCurrentEquityValue?.(getNumberValue(currentEquityValue));
      // Set current Firm Total
      if (!isNil(fundsConclusionTotalValue)) setCurrentFirmTotal?.(getNumberValue(fundsConclusionTotalValue));
    }

    return () => {
      if (allocationIsEmpty) return;

      // Set current Allocation Version
      setCurrentAllocationVersion?.(null);
      // Reset current Allocation Status
      setCurrentAllocationStatus?.(null);
      // Reset current Equity Value
      setCurrentEquityValue?.(null);
      // Reset current Firm Total
      setCurrentFirmTotal?.(null);
    };
  }, [
    allocationIsEmpty,
    allocationStatus,
    allocationVersion,
    currentEquityValue,
    data,
    fundsConclusionTotalValue,
    setCurrentAllocationStatus,
    setCurrentAllocationVersion,
    setCurrentEquityValue,
    setCurrentFirmTotal,
  ]);

  // If Allocation is Empty, set Allocation Status to null, Equity Value and Firm Total to Zero
  useEffect(() => {
    if (allocationIsEmpty) {
      // Set current Allocation Status
      setCurrentAllocationStatus?.(null);
      // Set current Allocation Version
      setCurrentAllocationVersion?.(null);
      // Set current Equity Value
      setCurrentEquityValue?.(0);
      // Set current Firm Total
      setCurrentFirmTotal?.(0);
    }
  }, [
    allocationIsEmpty,
    data,
    setCurrentAllocationStatus,
    setCurrentAllocationVersion,
    setCurrentEquityValue,
    setCurrentFirmTotal,
  ]);

  useEffect(() => {
    setIsDisabled(allocationInfo?.is_final);
  }, [allocationInfo.is_final]);

  if (forbiddenAccess) {
    return <MessageBox title={FORBIDDEN_ALLOCATION_INFO} tagline={GET_ALLOCATION_INFO_ACCESS} fullWidth={false} />;
  }

  if (isEmpty(data) && scenarios?.length > 0) return <GridSkeleton />;

  if (fundOwnershipDetailIsEmpty) {
    return (
      <NeedsFundOwnershipFirst
        measurementDates={measurementDates}
        selectedMeasurementDate={selectedMeasurementDate}
        title="No fund ownership defined for the primary cap table"
        tagline="Add fund ownership to see its share values"
      />
    );
  }

  if (captableNeedsMoreSecurities) {
    return (
      <EmptyCapTableMessage
        title={CAP_TABLE_NEEDS_MORE_SECURITIES_TITLE}
        tagline={CAP_TABLE_NEEDS_MORE_SECURITIES_TAGLINE}
        fullWidth={false}
        page="allocation"
      />
    );
  }

  if (allocationTableIsEmpty) {
    return (
      <>
        <MessageBox title="The Allocation table is empty" tagline="Add default scenarios" fullWidth={false} />
        <AddScenarioButton disableCondition={generalDisableCondition} onClickHandler={addScenario} />
      </>
    );
  }

  if (data && !isEmpty(data))
    return (
      <AllocationContext.Provider value={AllocationContextData}>
        <WorkbookContext.Provider value={workbookContextValue}>
          {isDisabled && (
            <AllocationFinalMessage
              tableTerms={data.allocation}
              openProformaDialog={openNewVersionForm}
              setIsDisabled={setIsDisabled}
              saveAsProformaConfirmation={saveAsNewVersion}
              variant
              isAllocation
              actions={[
                {
                  label: 'Duplicate',
                  buttonProps: {
                    variant: 'contained',
                    size: 'small',
                  },
                  callback: () => setOpenNewVersionForm(true),
                },
              ]}
            />
          )}
          {!isDisabled && allocationInfo.is_final && <Alert isAlertVisible> {ALLOCATION_FINAL_MESSAGE}</Alert>}
          {showFundConclusion && (
            <div className={classes.marginTopMedium}>
              <Conclusions
                configuration={{
                  ...data.conclusions,
                  format,
                }}
                onChange={onChange}
              />
            </div>
          )}
          {!displayWithoutScenarios ? (
            <div className={classes.marginTopMedium}>
              <ScalarSpreadsheet
                {...data.allocation}
                cloneColumn={cloneColumn}
                deleteColumn={deleteScenario}
                format={format}
                formatDispatch={formatDispatch}
                onChange={onWorkbookChange}
                referencedValues={referencedValues}
                rowGroups={rowGroups}
                setColumns={setColumns}
                setRowGroups={setRowGroups}
                sheet={data.allocation}
                workbook={workbook}
              />
            </div>
          ) : (
            <div className={classes.marginTopMedium}>
              <h4 className={classes.blueText}>{noScenarios}</h4>
            </div>
          )}
          <WeightedSharesTables
            data={data}
            primaryCaptable={primaryCaptable}
            scenarios={columns}
            weightedShareValues={weightedSharesValues}
            isValidCustomSecurities={isValidCustomSecurities}
            onChange={onChange}
            format={format}
          />
          <AddScenarioButton disableCondition={generalDisableCondition} onClickHandler={addScenario} />

          <NewVersionForm
            open={openNewVersionForm}
            onClose={closeNewVersionForm}
            onSave={() => saveAllocations(null, true)}
            defaultValues={defaultValues}
            onFormChange={setVersionFormState}
          />

          <Widgets
            notesProps={{
              pageType: ALLOCATIONS_REFERENCE_TYPE,
              pageTypeKey: ALLOCATIONS_PAGE_KEY,
              pageTypeId: allocationInfo.id,
              notes,
              setNotes,
              onAddNote,
              onUpdateNotes,
              onDeleteNote,
              isDisabled,
            }}
          />
        </WorkbookContext.Provider>
      </AllocationContext.Provider>
    );

  return <GridSkeleton />;
};

// Allocation Info Prop Types
Allocation.propTypes = AllocationsPropTypes;

export default Allocation;
