import React, { FC } from 'react';
import CurrentRequestedDocumentAccordion from './CurrentRequestedDocumentAccordion';
import CurrentRequestedQuestionsAccordion from './CurrentRequestedQuestionsAccordion';
import { CurrentRequestedDocumentsAndQuestionsProps } from './types';

const CurrentRequestedDocumentsAndQuestions: FC<CurrentRequestedDocumentsAndQuestionsProps> = ({
  currentInformationRequests,
}) => (
  <>
    <CurrentRequestedDocumentAccordion currentInformationRequests={currentInformationRequests} />
    <CurrentRequestedQuestionsAccordion currentInformationRequests={currentInformationRequests} />
  </>
);

export default CurrentRequestedDocumentsAndQuestions;
