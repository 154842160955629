import React, { ChangeEvent, FC } from 'react';
import { Box, FormControl, MenuItem, Select } from '@material-ui/core';
import { ALLOCATIONS_FINAL } from 'common/constants/allocations';
import { AllocationStatus } from 'common/types/allocation';
import { VALUATIONS_SUMMARY_ALLOCATIONS_STATUSES } from 'pages/Funds/common/constants/valuationsSummary';
import { CustomOptionIcon } from 'pages/Funds/components/CustomOptionIcon';
import useStyles from './styles';
import { AllocationStatusSelectProps } from './types';

const ALLOCATION_STATUS_SELECTOR = 'allocation-status-selector';
const ALLOCATION_STATUS_OPTION = 'allocation-status-option';

const AllocationStatusSelect: FC<AllocationStatusSelectProps> = props => {
  const { allocationId, allocationStatus, setAllocationStatus } = props;

  const classes = useStyles();

  const handleOnChange = (
    event: ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    if (allocationStatus !== ALLOCATIONS_FINAL)
      setAllocationStatus({
        allocationIds: [allocationId],
        allocationStatus: Number(event.target.value) as AllocationStatus,
      });
  };

  return (
    <FormControl className={classes.formContainer}>
      <Select
        className={classes.optionSelector}
        disabled={allocationStatus === ALLOCATIONS_FINAL}
        disableUnderline
        id={`${ALLOCATION_STATUS_SELECTOR}-${allocationId}`}
        onChange={handleOnChange}
        value={allocationStatus.toString()}>
        {VALUATIONS_SUMMARY_ALLOCATIONS_STATUSES.map(status => (
          <MenuItem
            id={`${ALLOCATION_STATUS_OPTION}-${status.label}`}
            key={`${ALLOCATION_STATUS_OPTION}-${status.label}`}
            value={status.value.toString()}>
            <Box className={classes.optionContainer}>
              <CustomOptionIcon status={status.value} />
              <span className={classes.optionText}>{status.label}</span>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AllocationStatusSelect;
