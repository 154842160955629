import { Cell, Cells } from 'common/types/scalarSpreadsheet';
import { ValuationsApproach } from 'common/types/valuation';
import {
  ALLOCATION_SCENARIO_METHOD_CSE,
  ALLOCATION_SCENARIO_METHOD_WATERFALL,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import {
  ALLOCATION_METHOD_OPTIONS_KEYS,
  ALLOCATION_METHOD_OPTIONS_VALUES,
  AllocationMethodsMapKeys,
  AllocationMethodsMapValues,
} from 'pages/ValuationsAllocation/common/constants/futureExit';
import { parseCellValue } from 'pages/ValuationsAllocation/util';
import { getExpression, getNumberValue, getObjectValue, toString } from 'utillities';
import { alphabetGenerator } from 'utillities/alphabet-utilities';
import { CustomParserParams } from '../OPM/config/customParser';
import { CellParserParams } from '../OPM/config/customParser/types';
import { HandlersParams } from '../OPM/config/customReverseParser/types';

type CellsShouldBeDisabledParams = {
  currentAllocationMethod: number;
  approach?: ValuationsApproach;
  isDisabled?: boolean;
};

export const cellsShouldBeDisabled = (params: CellsShouldBeDisabledParams) => {
  const { currentAllocationMethod, approach, isDisabled } = params;
  return (
    isDisabled
    || ([ALLOCATION_SCENARIO_METHOD_WATERFALL, ALLOCATION_SCENARIO_METHOD_CSE].includes(currentAllocationMethod)
      && Boolean(approach?.id))
  );
};

export const getCellValue = (cell: Cell, columnLegend: string) => {
  const {
    allowEmptyValues = true,
    customKey = null,
    dbType = 'string',
    expr = '',
    format = null,
    gridType = 'string',
    isTextArea = false,
    value = '',
  } = getObjectValue(cell);

  return {
    ...cell,
    allowEmptyValues,
    columnLegend,
    customKey,
    dbType,
    expr: getExpression({ expr, columnLegend }),
    format,
    gridType,
    isTextArea,
    value,
  };
};

type GetCustomParserParams = {
  parserParams: CustomParserParams;
  cellParser: (params: CellParserParams) => { [key: string]: Cell };
};

export const getCustomParser = (params: GetCustomParserParams) => {
  const { parserParams, cellParser } = params;
  const { approaches, columns, isDisabled, rowConfig, valuationsApproachFutureExit } = parserParams;

  let cells = {} as Cells;
  const alphabet = alphabetGenerator([], columns.length) as string[];

  rowConfig.forEach((row, rowIndex: number) => {
    columns.forEach((column, colIndex: number) => {
      const parsedCells = cellParser({
        alphabet,
        approaches,
        colIndex,
        column,
        isDisabled,
        row,
        rowIndex,
        valuationsApproachFutureExit,
      });
      cells = {
        ...cells,
        ...parsedCells,
      };
    });
  });

  return cells;
};

export const handleAllocationMethod = (params: HandlersParams) => {
  const { approach, cell, futureExitAttributes } = params;
  const fieldDecimalPlaces = getNumberValue(futureExitAttributes?.allocation_method?.decimal_places);
  const currentCellValue = toString(cell.value);
  const allocationMethodValue
    = typeof cell.value === 'number'
      ? ALLOCATION_METHOD_OPTIONS_KEYS[currentCellValue as keyof AllocationMethodsMapKeys]
      : ALLOCATION_METHOD_OPTIONS_VALUES[currentCellValue as keyof AllocationMethodsMapValues];

  const tempCell = {
    ...cell,
    value: allocationMethodValue,
  };
  const updatedCellValue = parseCellValue({ cell: tempCell, fieldDecimalPlaces });
  approach.allocation_method = updatedCellValue;
};
