import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useInformation409A } from 'common/hooks';
import { ExtraActionsMenu } from 'components';
import { InformationDashboard409AContext } from 'context/InformationDashboard409AContext';
import { getCompanyRequestedInformation } from 'dashboard409a/services/dashboard';
import { hideLoadingProgressBar, showLoadingProgressBar } from 'dashboard409a/states/features/global';
import { RootStateType } from 'dashboard409a/states/store';
import theme from 'theme';
import { CreateInformationRequestModal } from './CreateInformationRequestModal';
import CurrentRequestedDocumentsAndQuestions from './CurrentRequestedDocumentsAndQuestions/CurrentRequestedDocumentsAndQuestions';
import { CurrentInformationRequest } from './CurrentRequestedDocumentsAndQuestions/types';
import { InitialRequestProcessModal } from './InitialRequestProcessModal';
import useStyles from './styles';
import { CompanyData } from './types';
import { ApiService, InformationRequestStatus } from '../../../api';
import useGetCompanyUsers from '../../../services/hooks/useGetCompanyUsers';
import { VALUATION_TYPE_409A } from '../../common/constants';

const Information: FC = () => {
  const classes = useStyles();
  const [isInfoSetupModalOpen, setIsInfoSetupModalOpen] = useState(false);
  const [isTaskPreperationModelOpen, setIsTaskPreperationModelOpen] = useState(false);
  const [currentInformationRequests, setCurrentInformationRequests] = useState<CurrentInformationRequest[]>([]);
  const [updateTaskList, setUpdateTaskList] = useState(false);
  const [informationRequestStatuses, setInformationRequestStatuses] = useState<InformationRequestStatus[]>([]);
  const { currentCompany, currentMeasurementDateId, currentCompanyMeasurementDateId } = useSelector(
    (state: RootStateType) => state.global
  );
  const { data: userData } = useGetCompanyUsers(currentCompany?.id, '');

  const { handleGetCurrentInformationRequests } = useInformation409A({
    currentCompanyMeasurementDateId,
    setCurrentInformationRequests,
  });
  const [companyData, setCompanyData] = useState<CompanyData>({ requested_documents: [], questions: [] });
  const { requested_documents: requestedDocuments, questions } = companyData;

  const documentsAndQuestionsProps = {
    companyId: currentCompany?.id,
    documents: requestedDocuments,
    questions,
    setUpdateTaskList,
  };

  const optionsForExtraActionsMenu = [
    {
      label: 'Edit Company Questions and Documents',
      callback: () => setIsInfoSetupModalOpen(true),
      isActive: true,
    },
  ];

  const createAndSetInformationRequestStatus = useCallback(async () => {
    if (currentCompanyMeasurementDateId === undefined) return;
    const informationRequestStatus = await ApiService.apiCreateInformationRequestStatusCreate({
      report_date: currentCompanyMeasurementDateId,
      valuation_type: VALUATION_TYPE_409A,
    });
    setInformationRequestStatuses([informationRequestStatus]);
  }, [currentCompanyMeasurementDateId]);

  const retrieveAndSetInformationRequestStatus = useCallback(async () => {
    if (currentCompanyMeasurementDateId === undefined) return;
    const informationRequestStatus = await ApiService.apiRetrieveInformationRequestStatusRead(
      currentCompanyMeasurementDateId.toString()
    );
    if (informationRequestStatus.results.length === 0) {
      await createAndSetInformationRequestStatus();
      return;
    }

    setInformationRequestStatuses(informationRequestStatus.results);
  }, [createAndSetInformationRequestStatus, currentCompanyMeasurementDateId]);

  useEffect(() => {
    if (currentCompanyMeasurementDateId) {
      retrieveAndSetInformationRequestStatus();
    }
  }, [currentCompanyMeasurementDateId, retrieveAndSetInformationRequestStatus]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      showLoadingProgressBar();

      if (currentCompany?.id && currentMeasurementDateId) {
        const data = await getCompanyRequestedInformation(currentCompany.id);
        setCompanyData(data);
        handleGetCurrentInformationRequests();
        setUpdateTaskList(false);
      }

      await retrieveAndSetInformationRequestStatus();

      hideLoadingProgressBar();
    };
    fetchCompanyData();
  }, [
    currentCompany,
    currentMeasurementDateId,
    handleGetCurrentInformationRequests,
    retrieveAndSetInformationRequestStatus,
    updateTaskList,
  ]);

  const providerValue = useMemo(
    () => ({
      handleGetCurrentInformationRequests,
    }),
    [handleGetCurrentInformationRequests]
  );

  return (
    <InformationDashboard409AContext.Provider value={providerValue}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.header}>
          <Grid item>
            <Typography className={classes.typographyTitle}>Information</Typography>
            <Typography className={classes.typographyDescription}>
              Documentation and financial information collected from the company.
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonOutlined}
              onClick={() => setIsTaskPreperationModelOpen(true)}
              startIcon={<MailIcon />}>
              {currentInformationRequests?.length ? 'UPDATE INFO REQUEST' : 'NEW INFO REQUEST'}
            </Button>
            <ExtraActionsMenu options={optionsForExtraActionsMenu} dotsColor={theme.palette.gray[500]} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!isEmpty(currentInformationRequests) && (
            <CurrentRequestedDocumentsAndQuestions currentInformationRequests={currentInformationRequests} />
          )}
        </Grid>
        <CreateInformationRequestModal
          isOpen={isTaskPreperationModelOpen}
          setIsOpen={() => setIsTaskPreperationModelOpen(false)}
          informationRequestStatuses={informationRequestStatuses}
          setUpdateTaskList={setUpdateTaskList}
          currentInformationRequests={currentInformationRequests}
          companyUsers={userData || []}
        />
        {/* change this to something along the lines of 'edit company questions and verify it works */}
        <InitialRequestProcessModal
          {...documentsAndQuestionsProps}
          isOpen={isInfoSetupModalOpen}
          setIsOpen={setIsInfoSetupModalOpen}
        />
      </Grid>
    </InformationDashboard409AContext.Provider>
  );
};

export default Information;
