import uuid from 'react-uuid';
import { ONE_HUNDRED_VALUE } from 'common/config/app';
import {
  WEIGHTING_PROBABILITIES_GREATER_THAN_100,
  WEIGHTING_PROBABILITIES_LOWER_THAN_100,
} from 'common/constants/messages/validations';
import {
  SHEET_ALIASES_CONSTANTS,
  SHEET_TITLES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { clearAndAddFeedback, displayMessageOnCellTooltip } from 'pages/ValuationsAllocation/util';
import { generateColumnKey, getNumberValue, getObjectValue, getStringValue, parseFloatToFixed } from 'utillities';
import { CustomValidationsParams } from './types';
import { handleScenarioEquityValue } from './utils';

const WEIGHTING_PROBABILITY_VALIDATIONS = Object.freeze({
  [WEIGHTING_PROBABILITIES_GREATER_THAN_100]: WEIGHTING_PROBABILITIES_LOWER_THAN_100,
  [WEIGHTING_PROBABILITIES_LOWER_THAN_100]: WEIGHTING_PROBABILITIES_GREATER_THAN_100,
});

const {
  VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE_TITLE,
  VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE_TITLE,
} = SHEET_TITLES_CONSTANTS;

const {
  VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE,
  VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
  VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE_KEY,
} = SHEET_ALIASES_CONSTANTS;

const customValidations = (params: CustomValidationsParams) => {
  const {
    addFeedback,
    addFeedbackFromCell,
    cell,
    parsedColumns: parsedCells,
    removeFeedback,
    removeFeedbackFromCell,
  } = params;

  const { alias, columnLegend, data } = getObjectValue(cell);
  const { isApproach = false, scenarioId = uuid(), scenarioRef = uuid() } = getObjectValue(data);

  const isApproachAllocationScenarioCell = isApproach;

  // Validate Scenario Equity Value
  handleScenarioEquityValue({ parsedColumns: parsedCells, addFeedbackFromCell, removeFeedbackFromCell });

  // Validate Scenario Weighting/Probability
  if (alias === VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY) {
    const weightingProbabilityCells = Object.values(getObjectValue(parsedCells)).filter(
      parsedCell => parsedCell?.alias === VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY
    );

    const weightingProbability = weightingProbabilityCells.reduce(
      (accumulator, current) => accumulator + getNumberValue(current?.value as number),
      0
    );

    const weightingProbabilityTotal = weightingProbability * ONE_HUNDRED_VALUE;

    const weightedEquityValueCell = getObjectValue(
      Object.values(getObjectValue(parsedCells)).find(parsedCell => {
        const { customKey } = getObjectValue(parsedCell);

        return customKey === VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE_KEY;
      })
    );

    // Display Total Weighting/Probability on Weighted Equity Value
    displayMessageOnCellTooltip({
      addFeedbackFromCell,
      cell: weightedEquityValueCell,
      message: `${VALUATION_SUMMARY_SPREADSHEET_WEIGHTED_EQUITY_VALUE_TITLE}: ${parseFloatToFixed(
        weightingProbabilityTotal.toString(),
        2 // Fraction digits
      )}%`,
    });

    // Add Weighting Probability feedback if the total is less than 100
    if (weightingProbabilityTotal < ONE_HUNDRED_VALUE) {
      clearAndAddFeedback({
        addFeedback,
        feedback: WEIGHTING_PROBABILITIES_LOWER_THAN_100,
        removeFeedback,
        validations: WEIGHTING_PROBABILITY_VALIDATIONS,
      });
    }
    // Add Weighting Probability feedback if the total is greater than 100
    else if (weightingProbabilityTotal > ONE_HUNDRED_VALUE) {
      clearAndAddFeedback({
        addFeedback,
        feedback: WEIGHTING_PROBABILITIES_GREATER_THAN_100,
        removeFeedback,
        validations: WEIGHTING_PROBABILITY_VALIDATIONS,
      });
    }
    // Remove Weighting Probability feedbacks
    else {
      weightingProbabilityCells.forEach(weightingProbabilityCell => {
        removeFeedbackFromCell?.(weightingProbabilityCell, WEIGHTING_PROBABILITIES_LOWER_THAN_100);
        removeFeedbackFromCell?.(weightingProbabilityCell, WEIGHTING_PROBABILITIES_GREATER_THAN_100);
      });
    }
  }
  // Validate Approaches Allocation Scenarios Weights
  else if (isApproachAllocationScenarioCell) {
    const scenarioWeightsCells = Object.values(getObjectValue(parsedCells)).filter(parsedCell => {
      const { data: cellCustomData } = getObjectValue(parsedCell);
      const {
        isApproach: parsedCellIsApproach = false,
        scenarioId: parsedCellScenarioId = uuid(),
        scenarioRef: parsedCellScenarioRef = uuid(),
      } = getObjectValue(cellCustomData);

      const matchScenarioIds = scenarioId !== 0 && parsedCellScenarioId !== 0 && parsedCellScenarioId === scenarioId;
      const matchScenarioRefs
        = parsedCellScenarioRef !== '' && scenarioRef !== '' && parsedCellScenarioRef === scenarioRef;

      return parsedCellIsApproach && (matchScenarioIds || matchScenarioRefs);
    });

    const scenarioWeights = scenarioWeightsCells.map(
      parsedCell => getNumberValue(parsedCell?.value as number) * ONE_HUNDRED_VALUE
    );

    const scenarioWeightsTotal = scenarioWeights.reduce(
      (accumulator, current) => accumulator + getNumberValue(current),
      0
    );

    const scenarioEquityValueCustomKey = generateColumnKey({
      id: scenarioId,
      name: VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE,
      prefix: getStringValue(columnLegend),
    });

    const scenarioEquityValueCell = getObjectValue(
      Object.values(getObjectValue(parsedCells)).find(parsedCell => {
        const { customKey } = getObjectValue(parsedCell);

        return customKey === scenarioEquityValueCustomKey;
      })
    );

    // Display Total Scenario Weights on Scenario Equity Value
    displayMessageOnCellTooltip({
      addFeedbackFromCell,
      cell: scenarioEquityValueCell,
      message: `${VALUATION_SUMMARY_SPREADSHEET_SCENARIO_EQUITY_VALUE_TITLE}: ${scenarioWeightsTotal}%`,
    });

    // Add Approach Allocation Sceneario Weight feedback if the total is less than 100
    if (scenarioWeightsTotal < ONE_HUNDRED_VALUE) {
      clearAndAddFeedback({
        addFeedback,
        feedback: WEIGHTING_PROBABILITIES_LOWER_THAN_100,
        removeFeedback,
        validations: WEIGHTING_PROBABILITY_VALIDATIONS,
      });
    }
    // Add Approach Allocation Sceneario Weight feedback if the total is greater than 100
    else if (scenarioWeightsTotal > ONE_HUNDRED_VALUE) {
      clearAndAddFeedback({
        addFeedback,
        feedback: WEIGHTING_PROBABILITIES_GREATER_THAN_100,
        removeFeedback,
        validations: WEIGHTING_PROBABILITY_VALIDATIONS,
      });
    }
    // Remove Approach Allocation Sceneario Weight feedbacks
    else {
      scenarioWeightsCells.forEach(weightingProbabilityCell => {
        removeFeedbackFromCell?.(weightingProbabilityCell, WEIGHTING_PROBABILITIES_LOWER_THAN_100);
        removeFeedbackFromCell?.(weightingProbabilityCell, WEIGHTING_PROBABILITIES_GREATER_THAN_100);
      });
    }
  }
};

export default customValidations;
