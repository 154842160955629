import React from 'react';
import { largeCurrencyFormat, sharesFormat } from 'common/formats/formats';
import { GridDateEditor, GridDateValueViewer } from 'components/FeaturedSpreadsheet/components';
import { LedgerGridDate } from 'components/LedgerTable/components';

const colConfig = measurementDate => [
  {
    value: 'Date',
    alias: 'date',
    width: '175px',
    gridDateComponent: LedgerGridDate,
    gridType: 'gridDate',
    dataEditor: props => <GridDateEditor {...props} />,
    valueViewer: props => <GridDateValueViewer {...props} />,
    isRequired: true,
  },
  {
    value: 'Shares',
    alias: 'shares',
    width: '175px',
    format: sharesFormat,
    minValue: 1,
    gridType: 'number',
    hasTotal: true,
  },
  {
    value: 'Accrued Dividends',
    alias: 'accrued_dividends',
    width: '175px',
    readOnly: true,
    format: largeCurrencyFormat,
    gridType: 'number',
    // In case we update/change this formula, we need to update the formula
    // on the backend as well (app.cap_table.models.Security.calculate_accrued_dividends)
    expr: `=IF(@23==Y,(@4 * (1+@22/@24) ^ (YEARFRAC(date#, "${measurementDate}", 3) * @24) - @4) * shares#, @22 * @4 * shares# *YEARFRAC(date#, "${measurementDate}"))`,
    hasTotal: true,
  },
];

export default colConfig;
