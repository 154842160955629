/* eslint-disable import/prefer-default-export */
import moment from 'moment';

export const MOMENT_DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const TEXT_MASK_DATE_FORMAT = 'mm/dd/yyyy';
export const FIRST_PRICING_FORMAT = 'MMM DD YYYY hh:mmA';
export const SHORT_DATE_FORMAT = 'MM/dd/yyyy';
export const LONG_DATE_FORMAT = `${MOMENT_DEFAULT_DATE_FORMAT} HH:mm:ss`;

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const MDY_FORMAT = 'MM-DD-YYYY';
export const MOMENT_SUPPORTED_DATE_FORMATS = [MDY_FORMAT, ISO_DATE_FORMAT, 'MM/DD/YYYY'];
export const MOMENT_SUPPORTED_DATE_TIME_FORMATS = [
  'MM-DD-YYYY HH:mm:ss',
  'YYYY-MM-DD HH:mm:ss',
  'MM/DD/YYYY HH:mm:ss',
  'MM-DD-YYYY hh:mm A',
  'YYYY-MM-DD hh:mm A',
  'MM/DD/YYYY hh:mm A',
];

export const shortDate = (date, separator = '/') => {
  if (!date) return null;

  const dateParsed = moment(date, MOMENT_SUPPORTED_DATE_FORMATS, true);

  const dateFormat = `MM${separator}DD${separator}YYYY`;

  return dateParsed.isValid() ? dateParsed.format(dateFormat) : null;
};

const currentYear = moment().year();

export const getYearFromDate = date => {
  const dateParsed = moment(date, MOMENT_SUPPORTED_DATE_FORMATS, true);

  return dateParsed.isValid() ? dateParsed.year() : null;
};

export const reconstructDate = (month = '12', day = '31') => {
  const mmnt = moment(`${currentYear}-${month}-${day}`, ISO_DATE_FORMAT);
  // quick fix for DatePicker bug
  return new Date(mmnt).toISOString();
};

export const gridShortDate = date => moment(date || moment(), MOMENT_SUPPORTED_DATE_FORMATS).format('MM/DD/YYYY');

export const gridLongDate = date =>
  moment(date || moment(), MOMENT_SUPPORTED_DATE_TIME_FORMATS).format('MM/DD/YYYY HH:mm');

export const gridLocalShortDate = date =>
  moment(date || Date.now())
    .local()
    .format('MM/DD/YYYY');

export const dbShortDate = (date, format = ISO_DATE_FORMAT) => {
  const dateParsed = moment(date ?? Date.now(), [format, ...MOMENT_SUPPORTED_DATE_FORMATS], true);

  return dateParsed.isValid() ? dateParsed.format(format) : null;
};

export const dbLongDate = (date, format) => moment(date).format(format || `${ISO_DATE_FORMAT} HH:mm:ss`);

export const nextQuarterEndDate = () => moment().add(2, 'months').endOf('month').format('MM/DD/YYYY');

export const isValidDate = (date, format = MOMENT_SUPPORTED_DATE_FORMATS) => moment(date, format, true).isValid();

export const endOfDay = (date, format = ISO_DATE_FORMAT) => moment(date, format).endOf('day').format(LONG_DATE_FORMAT);

export const formatDateView = param => {
  if (isValidDate(param)) {
    return shortDate(param);
  }
  return param;
};

export const currentISODate = () => moment().format(ISO_DATE_FORMAT);

// Example: July 7, 2022
export const formatDateViewLL = param => moment(param).format('LL');

// Extracts the date from a measurement date slug: 2022-08-30-1234 --> 2022-08-30
// We can get fancier with the default regular expression, but YYYY-MM-DD works for our usual format
export const extractDate = ({ dateSlug, format = /^\d{4}-\d{2}-\d{2}/ }) => {
  if (dateSlug?.match(format)) {
    return dateSlug.match(format)[0];
  }

  return null;
};

export const getYearFromIsoFormattedDate = date => date.split('-')[0];

export const formatDateTime = (dateParam, options = {}) => {
  const {
    timeZone = options.timeZone || 'UTC',
    hour12 = options.hour12 || true,
    month = options.month || '2-digit',
    day = options.day || '2-digit',
    year = options.year || 'numeric',
    hour = options.hour || 'numeric',
    minute = options.minute || 'numeric',
  } = options;

  const formattedDate = dateParam.toLocaleString('en-US', {
    timeZone,
    hour12,
    month,
    day,
    year,
    hour,
    minute,
  });

  return formattedDate;
};
