/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/**
 * @name Captable Hook
 * @memberof module:common/hooks
 * @type {ReactHook}
 * @return {React} Captable Hook
 */

import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { isUndefined } from 'lodash';
import { isEmpty } from 'validate.js';
import { ERROR_403, ERROR_500 } from 'common/config/api';
import { ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY } from 'common/constants/services/companies';
import { useStore } from 'common/store';
import UnsavedChanges from 'context/UnsavedChanges';
import { useResponse } from './useResponse';
import { CaptableService } from '..';
import { captableAction, globalAction } from '../../common/actions';

/**
 * @function
 * @name useGetCaptableInfo
 * @memberof Captable Hook
 * @description Hook to fetch captable info from captable service
 * @param {integer} dataId the captable id
 * @return {object} structure for store context.
 */

export const useGetCaptableInfo = () => {
  const [globalStore, dispatch] = useStore();
  const [capTableInfo, setUpdatedCapTableInfo] = useState({});

  const { processErrorResponse, errorNotification } = useResponse();

  const getCapTableInfo = useCallback(
    async (newCaptableId, updateGlobalStore = true) => {
      if (newCaptableId) {
        dispatch(globalAction.showLoadingProgress(true));
        dispatch(captableAction.setCaptableInfo({}));

        const defaultErrorMessage = 'An error occurred while loading the Cap Table information';

        try {
          const captSvc = new CaptableService();
          const capTableInfo = await captSvc.dataById(newCaptableId);
          if (!isEmpty(capTableInfo)) {
            if (updateGlobalStore) {
              dispatch(
                captableAction.setCaptableInfo({
                  ...globalStore.capTableInfo,
                  ...capTableInfo,
                })
              );
            }
            setUpdatedCapTableInfo(capTableInfo);
          } else {
            errorNotification(defaultErrorMessage);
          }
        } catch (error) {
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'get the Cap Table information',
          });
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, globalStore.capTableInfo]
  );

  return [capTableInfo, getCapTableInfo];
};

/**
 * @function
 * @name useGetBreakpointAnalysis
 * @description Get the breakpoint analysis for a specific Cap Table by its id
 * @param {integer} captableId
 * @return {object}
 */
export const useGetBreakpointAnalysis = captableId => {
  const [globalStore, dispatch] = useStore();
  const [capTableInfo, setUpdatedCapTableInfo] = useState({});

  const { processErrorResponse, errorNotification } = useResponse();

  const getCapTableBreakpoints = useCallback(
    async newCaptableId => {
      if (newCaptableId) {
        dispatch(globalAction.showLoadingProgress(true));

        const defaultErrorMessage = 'An error occurred while loading the Breakpoint Analysis information';

        try {
          const captSvc = new CaptableService();
          const breakpointAnalysis = await captSvc.getAllBreakpointsData(newCaptableId);

          if (breakpointAnalysis) {
            let prevCaptableInfo = { ...globalStore.captableInfo };

            if (isEmpty(prevCaptableInfo)) {
              const captSvc = new CaptableService();
              const updatedCaptableInfo = await captSvc.dataById(newCaptableId);

              if (updatedCaptableInfo) {
                prevCaptableInfo = { ...updatedCaptableInfo };
              }
            }

            const captable = {
              ...prevCaptableInfo,
              waterfall_breakpoints: breakpointAnalysis.waterfall_breakpoints,
              custom_breakpoints: breakpointAnalysis.custom_breakpoints,
            };
            dispatch(captableAction.setCaptableInfo(captable));
            setUpdatedCapTableInfo(captable);
          } else {
            errorNotification(defaultErrorMessage);
          }
        } catch (error) {
          if (error.status === 403) {
            setUpdatedCapTableInfo(ERROR_403);
          } else {
            processErrorResponse({ error, defaultErrorMessage });
          }
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, globalStore.captableInfo?.id]
  );

  useEffect(() => {
    getCapTableBreakpoints(captableId);
  }, [captableId, getCapTableBreakpoints]);

  return [capTableInfo, getCapTableBreakpoints];
};

export const useUpdateCapTableAndSecuritiesInfo = () => {
  const [data, setData] = useState({});

  const captSvc = new CaptableService();

  const [globalStore, dispatch] = useStore();
  const { setAction } = useContext(UnsavedChanges);

  const { processErrorResponse, successNotification } = useResponse();

  const getErrorInfoFromResponse = (error, defaultErrorMessage) => {
    let errorMessage = defaultErrorMessage;
    const servicePath = error.response.req.url.split('/').pop();
    if (servicePath === 'securities' && error.response.status === Number(ERROR_500)) {
      const securitiesError = JSON.parse(error.response.text).securities;
      if (securitiesError) {
        [errorMessage] = securitiesError;
      }
    }
    return {
      errorMessage,
      servicePath,
    };
  };

  // React Query Client
  const queryClient = useQueryClient();

  const updateCapTableData = async (captableId, securities, captableInfo, updateGlobalStore) => {
    if (captableId) {
      dispatch(globalAction.showLoadingProgress(true));

      let defaultErrorMessage = 'An error occurred while updating Cap Table information';

      try {
        let updatedCap = { ...globalStore.captableInfo };
        let updatedSecurities = [...(globalStore.captableInfo?.securities || [])];

        if (!isUndefined(captableInfo)) {
          updatedCap = await captSvc.updateCaptableById(captableId, captableInfo);
        }

        if (securities?.length > 0) {
          defaultErrorMessage = 'An error occurred while updating Cap Table securities';
          updatedSecurities = await captSvc.updateSecuritiesById(captableId, securities);
          updatedCap = await captSvc.getCaptableById(captableId);
        }

        setData({ ...updatedCap, securities: updatedSecurities });
        if (updateGlobalStore) {
          dispatch(captableAction.setCaptableInfo({ ...globalStore.capTableInfo, ...updatedCap }));
        }
        successNotification('Cap Table information updated successfully');
        setAction(false);
      } catch (error) {
        const { errorMessage, servicePath } = getErrorInfoFromResponse(error, defaultErrorMessage);
        const action = servicePath === 'securities' ? 'update Cap Table securities' : 'update Cap Table information';
        processErrorResponse({
          error,
          defaultErrorMessage: errorMessage,
          action,
        });
      } finally {
        // Invalidate the Allocation Values query to refetch the data with the new values
        queryClient.invalidateQueries({ queryKey: [ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY] });

        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };

  return [data, updateCapTableData];
};

/**
 * @function
 * @name useUpdateCaptableInfo
 * @memberof Captable Hook
 * @description Hook to update captable info from captable service
 * @param {integer} dataId the captable id
 * @param {object} captableInfo captable structure data
 * @return {object} structure for store context.
 */
export const useUpdateCaptableInfo = (captableId, captableInfo) => {
  const [globalStore, dispatch] = useStore();
  const [data, setData] = useState({});
  const prevCaptableInfo = useRef(globalStore.captableInfo);

  const { processErrorResponse, errorNotification, successNotification } = useResponse();

  const updateCapTableData = useCallback(
    async (newCaptableId, newCaptableInfo) => {
      if (newCaptableId && newCaptableInfo) {
        dispatch(globalAction.showLoadingProgress(true));

        const defaultErrorMessage = 'An error occurred while updating Cap Table information';

        try {
          const captSvc = new CaptableService();
          const captableInfo = await captSvc.updateCaptableById(newCaptableId, newCaptableInfo);
          if (!isEmpty(captableInfo)) {
            dispatch(
              captableAction.setCaptableInfo({
                ...prevCaptableInfo.current,
                ...captableInfo,
              })
            );
            setData(captableInfo);
            successNotification('Cap Table updated successfully');
          } else {
            errorNotification(defaultErrorMessage);
          }
        } catch (error) {
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'update the Cap Table',
          });
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, prevCaptableInfo]
  );

  useEffect(() => {
    updateCapTableData(captableId, captableInfo);
  }, [captableId, captableInfo, updateCapTableData]);

  return [data, updateCapTableData];
};

/**
 * @function
 * @name useUpdateCaptableSecurities
 * @memberof Captable Hook
 * @description Hook to update captable info from Cap Table service
 * @param {INTEGER} dataId the captable id
 * @param {OBJECT} captableSecurititesInfo captable structure data
 * @return {OBJECT} structure for store context.
 */
export const useUpdateCaptableSecurities = () => {
  const captSvc = new CaptableService();
  const [globalStore, dispatch] = useStore();
  const [data, setData] = useState({});

  const { processErrorResponse, errorNotification, successNotification } = useResponse();

  const updateSecuritiesData = async (newCaptableId, newCaptableSecurititesInfo) => {
    dispatch(globalAction.showLoadingProgress(true));

    const defaultErrorMessage = 'An error occurred while updating the Cap Table securities';

    try {
      const captableSecuritiesInfo = await captSvc.updateSecuritiesById(newCaptableId, newCaptableSecurititesInfo);
      if (captableSecuritiesInfo) {
        dispatch(
          captableAction.setCaptableInfo({
            ...globalStore.captableInfo,
            securities: captableSecuritiesInfo,
          })
        );
        setData(captableSecuritiesInfo);
        successNotification('Cap Table securities updated successfully');
      } else {
        errorNotification(defaultErrorMessage);
      }
    } catch (error) {
      processErrorResponse({
        error,
        defaultErrorMessage,
        action: 'update the Cap Table securities',
      });
    } finally {
      dispatch(globalAction.showLoadingProgress(false));
    }
  };
  return [{ captableSecuritiesInfo: data }, updateSecuritiesData];
};

/**
 * @function
 * @name useUpdateCaptableFundOwnership
 * @memberof Captable Hook
 * @description Hook to update captable Fund Ownership info from Cap Table service
 * @param {INTEGER} captableId the captable id
 * @param {OBJECT} fundOwnershipInfo captable structure data
 * @return {OBJECT} structure for store context.
 */
export const useUpdateCaptableFundOwnership = () => {
  const [updatedFundOwnership, setUpdatedFundOwnership] = useState({});

  const captSvc = new CaptableService();

  const [globalStore, dispatch] = useStore();
  const { setAction } = useContext(UnsavedChanges);

  const { processErrorResponse, successNotification } = useResponse();

  // React Query Client
  const queryClient = useQueryClient();

  const isSecurityUsedInFod = (fundOwnershipDetail, security) =>
    fundOwnershipDetail.some(
      fod =>
        fod.security_id === security.id
        || security.convertible_notes.map(cn => cn.convertible_note_security).includes(fod.security_id)
    );

  const updateFundOwnershipData = async (captableId, fundOwnershipInfo) => {
    if (captableId && fundOwnershipInfo) {
      dispatch(globalAction.showLoadingProgress(true));

      try {
        const fundOwnershipResponse = await captSvc.updateFundOwnershipById(captableId, fundOwnershipInfo);

        const { fund_ownership_detail } = fundOwnershipResponse;

        // The response includes the fields fund_ownership_id, fund_id, and security_id,
        // but the frontend is expecting fund_ownership, fund, and security, respectively.
        const tmpFundOwnershipDetail = fund_ownership_detail.map(fod => ({
          ...fod,
          fund: fod.fund_id,
          security: fod.security_id,
          fund_ownership: fod.fund_ownership_id,
        }));

        const tmpFundOwnershipInfo = {
          ...globalStore.captableInfo.fund_ownership,
          ...fundOwnershipResponse,
          fund_ownership_detail: tmpFundOwnershipDetail,
        };

        dispatch(
          captableAction.setCaptableInfo({
            ...globalStore.captableInfo,
            securities: globalStore.captableInfo.securities.map(security => ({
              ...security,
              is_used_in_fod: isSecurityUsedInFod(tmpFundOwnershipDetail, security),
            })),
            fund_ownership: tmpFundOwnershipInfo,
          })
        );

        setAction(false);
        successNotification('Fund Ownership was updated successfully');
        setUpdatedFundOwnership(tmpFundOwnershipInfo);
      } catch (error) {
        const defaultErrorMessage
          = error.response?.body?.detail || 'An error occurred while updating the Cap Table Fund Ownership';
        processErrorResponse({
          error,
          defaultErrorMessage,
          action: 'update the Cap Table Fund Ownership',
        });
      } finally {
        // Invalidate the Allocation Values query to refetch the data with the new values
        queryClient.invalidateQueries({ queryKey: [ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY] });

        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };
  return [updatedFundOwnership, updateFundOwnershipData];
};

/**
 * @function
 * @name useUpdateCaptableCustomBreakpoints
 * @memberof Captable Hook
 * @description Hook to update captable custom breakpoints from Cap Table service
 * @param {INTEGER} captableId the captable id
 * @param {OBJECT} breakpointsData captable custom BPs data
 */
export const useUpdateCaptableCustomBreakpoints = () => {
  const [updatedCustomBreakpoints, setUpdatedCustomBreakpoints] = useState({});

  const captSvc = new CaptableService();

  const [globalStore, dispatch] = useStore();
  const { setAction } = useContext(UnsavedChanges);

  const { processErrorResponse, successNotification } = useResponse();

  // React Query Client
  const queryClient = useQueryClient();

  const updateCustomBreakpoints = async (captableId, breakpointsData) => {
    if (captableId && breakpointsData) {
      dispatch(globalAction.showLoadingProgress(true));

      try {
        const breakpointsResponse = await captSvc.updateCustomBreakpoints(captableId, breakpointsData);

        dispatch(
          captableAction.setCaptableInfo({
            ...globalStore.captableInfo,
            ...breakpointsResponse,
          })
        );

        setAction(false);
        successNotification('Breakpoints were updated successfully');
        setUpdatedCustomBreakpoints(breakpointsResponse);
      } catch (error) {
        const defaultErrorMessage = 'An error occurred while updating the breakpoints';
        processErrorResponse({
          error,
          defaultErrorMessage,
          action: 'update the Cap Table breakpoints',
        });
      } finally {
        // Invalidate the Allocation Values query to refetch the data with the new values
        queryClient.invalidateQueries({ queryKey: [ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY] });

        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };
  return [updatedCustomBreakpoints, updateCustomBreakpoints];
};

export const useCreateCapTableProforma = () => {
  const [proformaInfo, setProformaInfo] = useState({});

  const captSvc = new CaptableService();

  const [, dispatch] = useStore();
  const { setAction } = useContext(UnsavedChanges);

  const { processErrorResponse, successNotification } = useResponse();

  // React Query Client
  const queryClient = useQueryClient();

  const createProforma = async proformaInfoPayload => {
    if (proformaInfoPayload) {
      dispatch(globalAction.showLoadingProgress(true));

      try {
        const response = await captSvc.createCapTableProforma(proformaInfoPayload);
        setAction(false);
        setProformaInfo(response);
        successNotification('The new proforma was created successfully');
      } catch (error) {
        const defaultErrorMessage = 'An error occurred while creating the new proforma';
        processErrorResponse({
          error,
          defaultErrorMessage,
          action: 'create new proforma',
        });
      } finally {
        // Invalidate the Allocation Values query to refetch the data with the new values
        queryClient.invalidateQueries({ queryKey: [ALLOCATION_VALUES_BY_COMPANY_QUERY_KEY] });

        dispatch(globalAction.showLoadingProgress(false));
      }
    }
  };
  return [proformaInfo, setProformaInfo, createProforma];
};

export const useGetPrimaryCaptableByCompanyDate = () => {
  const [, dispatch] = useStore();
  const [capTableInfo, setPrimaryCaptable] = useState({});

  const { processErrorResponse, errorNotification } = useResponse();

  const getPrimaryCaptable = useCallback(
    async companyDate => {
      if (companyDate) {
        dispatch(globalAction.showLoadingProgress(true));

        const defaultErrorMessage = 'An error occurred while loading the Cap Table information';

        try {
          const captSvc = new CaptableService();
          const capTableInfo = await captSvc.getPrimaryCaptableByCompanyDate(companyDate);

          if (!isEmpty(capTableInfo)) {
            setPrimaryCaptable(capTableInfo);
            dispatch(captableAction.setCaptableInfo(capTableInfo));
          } else {
            errorNotification(defaultErrorMessage);
          }
        } catch (error) {
          processErrorResponse({
            error,
            defaultErrorMessage,
            action: 'get the Cap Table information',
          });
        } finally {
          dispatch(globalAction.showLoadingProgress(false));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  return [capTableInfo, getPrimaryCaptable];
};
