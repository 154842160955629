import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import { afterCellChanged, createColumns, customParser, reverseParser, rowConfig } from './config';
import { conditions } from './PerformanceMetricsAsOfDate/config/conditions';

const createMultiplePremiumDiscount = ({
  companyName,
  approach,
  measurementDate,
  financials,
  performanceMetricsSheet,
  performanceMetricsAsOfDateSheet,
  multiplePremiumDiscountFromDB,
  comparisons,
  multiplePremiumDiscountIdsToDelete,
  gpcSheet,
}) => {
  const columns = createColumns({
    multiplePremiumDiscountFromDB,
    measurementDate,
    financials,
    valuationDate: approach.valuation_date,
  });
  const config = rowConfig({ companyName, maxDate: measurementDate?.date });
  const name = getApproachTableName({ approach, tableSuffix: 'multiple_premium_discount' });

  const spreadsheet = new SpreadsheetConfig({
    columns,
    format: undefined,
    name,
    showTitlesColumn: false,
    parser: customParser,
    reverseParser,
    rowConfig: config,
    reverseParseAfterLoad: true,
    showToolbar: true,
    tableData: {
      approach,
      comparisons,
      performanceMetricsSheet,
      performanceMetricsAsOfDateSheet,
      companyName,
      multiplePremiumDiscountIdsToDelete,
      gpcSheet,
    },
    afterCellChanged,
    totalParser: undefined,
    conditions,
  });

  return { spreadsheet };
};

export default createMultiplePremiumDiscount;
