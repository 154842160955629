export const getMedianExpression = (rowRange: string, startWithEqual = true): string =>
  startWithEqual ? `=MEDIAN(FILTER_UNDEFINED(${rowRange}))` : `MEDIAN(FILTER_UNDEFINED(${rowRange}))`;
export const getMeanExpression = (rowRange: string, startWithEqual = true): string =>
  startWithEqual ? `=MEAN(FILTER_UNDEFINED(${rowRange}))` : `MEAN(FILTER_UNDEFINED(${rowRange}))`;

export const get75thPercentileExpression = (rowRange: string, startWithEqual = true): string =>
  startWithEqual ? `=PERCENTILE(FILTER_UNDEFINED(${rowRange}), 75)` : `PERCENTILE(FILTER_UNDEFINED(${rowRange}), 75)`;
export const get25thPercentileExpression = (rowRange: string, startWithEqual = true): string =>
  startWithEqual ? `=PERCENTILE(FILTER_UNDEFINED(${rowRange}), 25)` : `PERCENTILE(FILTER_UNDEFINED(${rowRange}), 25)`;

export const getPreviousCell = (key: string): string => {
  const match = /^([A-Za-z]+)(\d+)$/.exec(key);

  if (!match) {
    // Handled invalid input
    throw new Error('Invalid column legend format');
  }

  const [, letters, numberStr] = match;
  const number = parseInt(numberStr, 10);

  if (number <= 1) {
    // Handle case where number is 1 or less
    return '';
  }

  return `${letters}${parseInt(numberStr, 10) - 1}`;
};
