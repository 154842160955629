import { createTheme } from '@material-ui/core/styles';
import { getNumberValue } from 'utillities';
import overrides from './overrides';
import palette from './palette';
import { ITheme, IThemeOptions } from './types';
import typography from './typography';

const navigationBarHeight = '48';
const progressBarHeight = '4';
const toolbarHeight = '100';

export const HEADER_HEIGHT
  = getNumberValue(toolbarHeight) + getNumberValue(navigationBarHeight) + getNumberValue(progressBarHeight);
export const SIDEBAR_CLOSED_WIDTH = 60;

export const FIXED_HEADER_STYLES = Object.freeze({
  left: `${SIDEBAR_CLOSED_WIDTH}px`,
  top: `calc(${HEADER_HEIGHT}px - ${progressBarHeight}px)`,
  width: `calc(100% - ${SIDEBAR_CLOSED_WIDTH}px)`,
});

export const themeOptions: IThemeOptions = Object.freeze({
  cellPadding: 5,
  navigationBarHeight,
  overrides,
  palette,
  progressBarHeight,
  toolbarHeight,
  typography,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

const theme = createTheme(themeOptions) as unknown as ITheme;

export default theme;
