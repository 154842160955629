import { SPECIFIED_VOLATILITY, VOLATILITY_SOURCE_ALIAS } from './constants';

const conditions = ({ cell, tableData, sheet }) => {
  const { volatilitySourceOptions, isDisabled } = tableData;
  const { cells } = sheet;
  const { alias } = cell;
  if (alias === VOLATILITY_SOURCE_ALIAS) {
    const volatilityCell = cells.B5;

    volatilityCell.readOnly
      = volatilitySourceOptions.find(option => option.id === cell.value)?.name !== SPECIFIED_VOLATILITY || isDisabled;
  }
};

export default conditions;
