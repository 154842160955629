import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, isEqual, isNil, isNull } from 'lodash';
import { useParams } from 'react-router-dom';
import { globalAction } from 'common/actions';
import { ERROR_403 } from 'common/config/api';
import { MISSING_MD_MESSAGE, MISSING_MD_TITLE } from 'common/constants/messages/validations';
import {
  VALUATIONS_SSV_NO_SECURITIES_MESSAGE,
  VALUATIONS_TITLE,
  VALUATIONS_VERSION_LABEL,
} from 'common/constants/valuations';
import { useCompanyBreadcrumb } from 'common/hooks';
import { UseCompanyBreadcrumbItem } from 'common/hooks/useCompanyBreadcrumb/types';
import { useStore } from 'common/store';
import { UseStoreValues } from 'common/types/store';
import { AllocationStatusBadge, LoadingSection, MessageBox, MissingMeasurementDateMessage } from 'components';
import { GridSkeleton } from 'components/Grid';
import { AllocationDialog } from 'layouts/Main/components';
import { CompaniesParams } from 'pages/Portfolio/types';
import { useRefreshHelpersWithContext } from 'pages/Valuations/components/RefreshGPCOption/hooks';
import { useCompanyMeasurementDateFilter, usePendingDecisionsByFirm } from 'services/hooks';
import { useGetPublicCompsByDate, useUpdateValuation } from 'services/hooks/valuations';
import {
  dbShortDate,
  getArrayValue,
  getNumberValue,
  getObjectValue,
  getStringValue,
  handleCatchError,
  throwError,
} from 'utillities';
import { ALLOCATION_FORBIDDEN_TAGLINE, ALLOCATION_FORBIDDEN_TITLE } from './common/constants/allocation';
import { useAllocationVersionQueryString, useLoadValuation } from './hooks';
import {
  SaveValuationParams,
  TickersSymbolsByDate,
  UpdatePublicCompsParams,
  UseAllocationVersionQueryString,
  UseCompanyMeasurementDateFilter,
  UsePendingDecisionsByFirmHook,
} from './types';
import ValuationApproaches from './ValuationApproaches';

const ValuationsAllocation: FC = () => {
  const { firmSlugParam } = useParams<CompaniesParams>();

  const [tickersSymbolsByDate, setTickersSymbolsByDate] = useState<TickersSymbolsByDate | null>(null);
  const [storeValue, dispatch] = useStore() as unknown as UseStoreValues;
  const { companyInfo, firmList } = storeValue;
  const { financials_currency: financialsCurrency } = getObjectValue(companyInfo);

  const { MDDialogue, selectedMeasurementDate } = (
    useCompanyMeasurementDateFilter as unknown as UseCompanyMeasurementDateFilter
  )();

  const {
    dialogProps,
    isForbidden,
    selectedVersion: selectedAllocationVersion,
  } = (useAllocationVersionQueryString as unknown as UseAllocationVersionQueryString)(selectedMeasurementDate);

  const {
    allocationInfo,
    getVersions,
    onClose: onCloseAllocationDialog,
    open: openAllocationDialog = false,
    setAllocationInfo,
    versions,
  } = getObjectValue(dialogProps);

  const { decisions, displayDecisionAlerts, fetchPendingDecisions, getDisconnectDialog, isFetchingDecision } = (
    usePendingDecisionsByFirm as unknown as UsePendingDecisionsByFirmHook
  )();

  const { getTickerListsByDate } = useRefreshHelpersWithContext();

  const { mutateAsync: updateValuation, isLoading: isUpdatingValuation } = useUpdateValuation();

  // Get Valuation, Financials, and Other Initial Data
  const {
    capTableVersions,
    cashTaxRate,
    companyMeasurementDate,
    compGroups,
    financialFiscalYearPeriods,
    financialPeriods,
    financialPeriodsPerMD,
    financials,
    financialStatementsList,
    financialVersions,
    getValuationInfo,
    hasMeasurementDate,
    isLoadingCapTableVersions,
    isLoadingCompanyMeasurementDate,
    isLoadingCompGroups,
    isLoadingEffectiveTaxRate,
    isLoadingFinancialFiscalYearPeriods,
    isLoadingFinancialStatementsList,
    isLoadingFinancialVersions,
    isLoadingHasMeasurementDate,
    isLoadingPrimaryCapTable,
    isLoadingValuationInfo,
    otherFinancialStatements,
    primaryCapTable,
    primaryFinancialStatement,
    setCashTaxRate,
    setValuation,
    valuation,
  } = useLoadValuation({
    currentAllocation: selectedAllocationVersion,
    currentMeasurementDate: selectedMeasurementDate,
  });

  // Get Public Comps by Date
  const {
    data: publicCompsByDate,
    isFetching: isFetchingPublicCompsByDate,
    isLoading: isLoadingPublicCompsByDate,
  } = useGetPublicCompsByDate({
    currency: financialsCurrency,
    financialPeriods,
    shouldQueryAutomatically: !isEmpty(tickersSymbolsByDate),
    tickersSymbolsByDate,
  });

  const companyBreadcrumb = useMemo(
    () => ({
      page: VALUATIONS_TITLE,
      item: selectedAllocationVersion
        ? {
          label: `${VALUATIONS_VERSION_LABEL} ${getNumberValue(
            selectedAllocationVersion?.version
          )} - ${getStringValue(selectedAllocationVersion?.name)}`,
          status: <AllocationStatusBadge />,
        }
        : ({} as UseCompanyBreadcrumbItem),
    }),
    [selectedAllocationVersion]
  );

  // Set Company Breadcrumb
  useCompanyBreadcrumb(companyBreadcrumb);

  const activeFirm = useMemo(() => {
    if (!isEmpty(firmList) && !isEqual(firmList, ERROR_403) && firmSlugParam)
      return getObjectValue(firmList?.find(firm => firm?.slug === firmSlugParam));

    return null;
  }, [firmList, firmSlugParam]);

  const isLoading = useMemo(
    () =>
      [
        isFetchingDecision,
        isLoadingCapTableVersions,
        isLoadingCompanyMeasurementDate,
        isLoadingCompGroups,
        isLoadingEffectiveTaxRate,
        isLoadingFinancialFiscalYearPeriods,
        isLoadingFinancialStatementsList,
        isLoadingFinancialVersions,
        isLoadingHasMeasurementDate,
        isLoadingPrimaryCapTable,
        isLoadingValuationInfo,
      ].some(loading => loading),
    [
      isFetchingDecision,
      isLoadingCapTableVersions,
      isLoadingCompanyMeasurementDate,
      isLoadingCompGroups,
      isLoadingEffectiveTaxRate,
      isLoadingFinancialFiscalYearPeriods,
      isLoadingFinancialStatementsList,
      isLoadingFinancialVersions,
      isLoadingHasMeasurementDate,
      isLoadingPrimaryCapTable,
      isLoadingValuationInfo,
    ]
  );

  const accessIsForbidden = useMemo(
    () => isForbidden || isEqual(allocationInfo, ERROR_403),
    [allocationInfo, isForbidden]
  );

  const saveValuation = useCallback(
    async (params: SaveValuationParams) => {
      const { cleanUnsavedChanges, currentValuationApproaches, setArePendingChanges, updatedValuation } = params;

      const specifiedShareValuesApproaches = updatedValuation?.valuations_approaches?.filter(
        approach => approach?.valuations_approach_ssv
      );

      const someSpecifiedShareValuesWithoutSecurities = specifiedShareValuesApproaches?.some(
        approach => approach?.valuations_approach_ssv?.share_values?.length === 0
      );

      // Check if there are Specified Share Values without Securities
      if (someSpecifiedShareValuesWithoutSecurities) return throwError(VALUATIONS_SSV_NO_SECURITIES_MESSAGE);

      try {
        const valuationResponse = await updateValuation({ valuation: updatedValuation });
        const { valuation: savedValuation } = getObjectValue(valuationResponse);

        // Update Valuation with saved version
        if (!isEmpty(savedValuation)) setValuation(getObjectValue(savedValuation));

        cleanUnsavedChanges();
        setArePendingChanges(false);

        currentValuationApproaches.current = getArrayValue(savedValuation.valuations_approaches);

        return savedValuation;
      } catch (error) {
        return handleCatchError(error);
      }
    },
    [setValuation, updateValuation]
  );

  const updatePublicComps = useCallback(
    async (params: UpdatePublicCompsParams) => {
      const { approach, currentApproaches, valuationDate } = params;

      if (valuationDate !== approach.valuation_date) {
        const tickerListsByDate = getTickerListsByDate([approach], currentApproaches, dbShortDate(valuationDate));

        setTickersSymbolsByDate(tickerListsByDate);
      }
    },

    [getTickerListsByDate]
  );

  // Get Pending Decisions
  useEffect(() => {
    if (activeFirm?.id && !decisions && !isFetchingDecision) fetchPendingDecisions?.(activeFirm.id);
  }, [activeFirm, decisions, fetchPendingDecisions, isFetchingDecision]);

  // Refetch Valuation Info when Pending Decisions changes
  useEffect(() => {
    if (decisions && !isEmpty(selectedAllocationVersion)) getValuationInfo().catch(handleCatchError);
  }, [decisions, getValuationInfo, selectedAllocationVersion]);

  // Show Loading Progress
  useEffect(() => {
    dispatch?.(
      globalAction.showLoadingProgress(
        isLoading || (isLoadingPublicCompsByDate && isFetchingPublicCompsByDate) || isUpdatingValuation
      )
    );
  }, [dispatch, isFetchingPublicCompsByDate, isLoading, isLoadingPublicCompsByDate, isUpdatingValuation]);

  // Display Loading while checking for Measurement Date
  if (isLoadingHasMeasurementDate) return <LoadingSection />;

  if (!hasMeasurementDate || isNil(companyInfo?.measurement_date_id))
    return <MissingMeasurementDateMessage title={MISSING_MD_TITLE} tagline={MISSING_MD_MESSAGE} />;

  // Loading Grid Skeleton while fetching data
  if (isLoading || isEmpty(valuation)) return <GridSkeleton />;

  // Check if user has access to Equity Allocation
  if (accessIsForbidden) {
    return <MessageBox fullWidth={false} tagline={ALLOCATION_FORBIDDEN_TAGLINE} title={ALLOCATION_FORBIDDEN_TITLE} />;
  }

  return (
    <>
      {capTableVersions
        && cashTaxRate
        && companyMeasurementDate
        && compGroups
        && financialFiscalYearPeriods
        && financialPeriods
        && financialPeriodsPerMD
        && financials
        && financialVersions
        && otherFinancialStatements
        && primaryCapTable
        && (primaryFinancialStatement || (isNull(primaryFinancialStatement) && isEmpty(financialStatementsList))) // Custom FundOwnership
        && selectedAllocationVersion
        && selectedMeasurementDate
        && valuation && (
        <ValuationApproaches
          allocationVersion={selectedAllocationVersion}
          capTableVersions={capTableVersions}
          cashTaxRate={cashTaxRate}
          companyMeasurementDate={companyMeasurementDate}
          compGroups={compGroups}
          displayDecisionAlerts={displayDecisionAlerts}
          financialFiscalYearPeriods={financialFiscalYearPeriods}
          financialPeriods={financialPeriods}
          financialPeriodsPerMD={financialPeriodsPerMD}
          financials={financials}
          financialVersions={financialVersions}
          getDisconnectDialog={getDisconnectDialog}
          getValuationInfo={getValuationInfo}
          isLoadingPublicCompsByDate={isLoadingPublicCompsByDate}
          isUpdatingValuation={isUpdatingValuation}
          measurementDate={selectedMeasurementDate}
          otherFinancialStatements={otherFinancialStatements}
          primaryCapTable={primaryCapTable}
          primaryFinancialStatement={primaryFinancialStatement}
          publicCompsByDate={publicCompsByDate}
          saveValuation={saveValuation}
          setCashTaxRate={setCashTaxRate}
          setValuation={setValuation}
          updatePublicComps={updatePublicComps}
          valuation={valuation}
          getVersions={getVersions}
        />
      )}

      {MDDialogue && <MDDialogue />}

      {openAllocationDialog && (
        <AllocationDialog
          allocationInfo={allocationInfo}
          getVersions={getVersions}
          onClose={onCloseAllocationDialog}
          open
          setAllocationInfo={setAllocationInfo}
          versions={versions}
        />
      )}
    </>
  );
};

export default ValuationsAllocation;
