import { isUndefined } from 'lodash';
import { ADJUSTED_EBITDA_ALIAS, EBITDA_ALIAS, TOTAL_REVENUE_ALIAS } from 'common/constants/financials';
import {
  COMPANY,
  ENTERPRISE_VALUE_ID,
  EV_EXCL_OP_LEASES_ALIAS,
  LTM_REVENUE,
  MARKET_CAP_ID,
  STOCK_PRICE_ID,
  TICKER_SYMBOL_ID,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import addApproachDataInColumn from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/addApproachDataInColumn';
import { getTitleWithIcon } from './utillities';

const titleToFspMap = {
  Revenue: TOTAL_REVENUE_ALIAS,
  EBITDA: EBITDA_ALIAS,
};

const periodTypeMap = {
  ltm: 'ltm_financial_statement_period_id',
  ntm: 'ntm_financial_statement_period_id',
};

const rowTransformer = (approach, financials, financialsPeriods, sortedColumn = {}) => {
  const filteredFinancialPeriods = financialsPeriods.filter(
    ({ financial_statement }) => financial_statement === approach.financial_statement
  );
  const comparisons = approach?.gpc_comparison || [];
  const TABLE_COLUMNS = [
    {
      title: getTitleWithIcon('Ticker Symbol', TICKER_SYMBOL_ID, sortedColumn),
      id: 'ticker-symbol',
      // parentColumn has the id of the column to collapse into
      parentColumn: LTM_REVENUE,
      // how to name fields - the key in a field object will get copied as a key into the new column
      // with it's value being the field from the gpc comparison we want to extract
      fields: [{ ticker_symbol: TICKER_SYMBOL_ID }],
      isSortableColumn: true,
      isStringColumn: true,
    },
    {
      title: getTitleWithIcon('Stock Price', STOCK_PRICE_ID, sortedColumn),
      id: STOCK_PRICE_ID,
      parentColumn: LTM_REVENUE,
      fields: [{ stock_price: STOCK_PRICE_ID }],
      customFormat: {
        currency: {
          units: '',
        },
      },
      isSortableColumn: true,
    },
    {
      title: getTitleWithIcon('Market Cap', MARKET_CAP_ID, sortedColumn),
      id: MARKET_CAP_ID,
      parentColumn: LTM_REVENUE,
      fields: [{ market_cap: MARKET_CAP_ID }],
      isSortableColumn: true,
    },

    {
      title: getTitleWithIcon('Enterprise Value', ENTERPRISE_VALUE_ID, sortedColumn),
      id: ENTERPRISE_VALUE_ID,
      parentColumn: LTM_REVENUE,
      multiple_basis: approach.multiple_basis,
      fields: [{ enterprise_value: ENTERPRISE_VALUE_ID }, { ev_excl_operating_leases: EV_EXCL_OP_LEASES_ALIAS }],
      isSortableColumn: true,
    },
    {
      title: 'Revenue',
      id: LTM_REVENUE,
      periodType: 'ltm',
      isParent: true,
      fields: [
        { number: LTM_REVENUE },
        { enabled: 'ltm_revenue_enabled' },
        { calendarYears: 'calendar_years_financials' },
      ],
    },
    {
      title: 'EBITDA',
      id: 'ltm_ebitda',
      periodType: 'ltm',
      fields: [
        { number: 'ltm_ebitda' },
        { enabled: 'ltm_ebitda_enabled' },
        { calendarYears: 'calendar_years_financials' },
      ],
    },
    {
      title: 'Revenue',
      id: 'ntm_revenue',
      periodType: 'ntm',
      fields: [
        { number: 'ntm_revenue' },
        { enabled: 'ntm_revenue_enabled' },
        { calendarYears: 'calendar_years_financials' },
      ],
    },
    {
      title: 'EBITDA',
      id: 'ntm_ebitda',
      periodType: 'ntm',
      fields: [
        { number: 'ntm_ebitda' },
        { enabled: 'ntm_ebitda_enabled' },
        { calendarYears: 'calendar_years_financials' },
      ],
    },
  ];

  const getIncomeStatementField = ({ field, periodType, gpcApproach }) => {
    if (!field.includes(EBITDA_ALIAS)) return field;
    const periods = {
      ltm: gpcApproach.use_adjusted_LTM_ebitda ? ADJUSTED_EBITDA_ALIAS : EBITDA_ALIAS,
      ntm: gpcApproach.use_adjusted_NTM_ebitda ? ADJUSTED_EBITDA_ALIAS : EBITDA_ALIAS,
    };

    if (!periods[periodType]) {
      throw new Error(`Invalid period type: ${periodType}`);
    }

    return periods[periodType];
  };

  // copy the fields into the column
  const getComparisonFields = (gpc_comparison, comparisonFields) =>
    comparisonFields.reduce((obj, comparisonField) => {
      // eslint-disable-next-line no-param-reassign
      obj[Object.keys(comparisonField)[0]] = gpc_comparison[Object.values(comparisonField)[0]];
      return obj;
    }, {});

  return TABLE_COLUMNS.map((tableColumn, index) => {
    const tmpTableColumn = {};
    comparisons.forEach(comparison => {
      // eslint-disable-next-line no-param-reassign
      tmpTableColumn[comparison.cap_iq_id] = getComparisonFields(comparison, tableColumn.fields);
      // this sets a default value to the companies value
      // this eventually should come from the valuation page somehow
      // index > 3 because these are the rows in the latter half of the table
      if (index > 3 && !isUndefined(financials)) {
        // eslint-disable-next-line no-param-reassign
        tmpTableColumn[COMPANY] = financials[tableColumn.id];
        if (approach.ntm_financial_statement_period_id) {
          const { periodType, title } = tableColumn;
          const relatedPeriod = filteredFinancialPeriods.filter(
            ({ id }) => id === Number(approach[periodTypeMap[periodType]])
          )?.[0];
          if (relatedPeriod) {
            const selectedField = getIncomeStatementField({
              field: titleToFspMap[title],
              periodType,
              gpcApproach: approach,
            });
            tmpTableColumn[COMPANY] = relatedPeriod.income_statement[selectedField];
          }
        }
      }
    });
    // eslint-disable-next-line no-param-reassign
    tableColumn = addApproachDataInColumn(tableColumn, approach);
    // pull the necessary data out of the approach object and add it to the column
    return { ...tableColumn, ...tmpTableColumn };
  });
};

export default rowTransformer;
