export const PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX = 'performance_metrics';
export const PERFORMANCE_METRICS_SPREADSHEET_COLUMN_NAME = 'Column';
export const PERFORMANCE_METRICS_SPREADSHEET_COLUMN_NAME_PLURAL = 'Columns';
export const PERFORMANCE_METRICS_SPREADSHEET_SLUG = 'gpc-performance-metrics';
export const PERFORMANCE_METRICS_SPREADSHEET_TABLE = `${PERFORMANCE_METRICS_SPREADSHEET_SLUG}-table`;
export const PERFORMANCE_METRICS_SPREADSHEET_HEADER_CLASSNAME = `${PERFORMANCE_METRICS_SPREADSHEET_TABLE}-header`;
export const PERFORMANCE_METRICS_SPREADSHEET_RANK_VOLATILITY_HEADER_CLASSNAME = `${PERFORMANCE_METRICS_SPREADSHEET_TABLE}-rank-volatility-header`;
export const ONE_YEAR_VOLATILITY_PERCENTILES_SELECTION = 'one_year_volatility_percentile_selection';
export const TWO_YEAR_VOLATILITY_PERCENTILES_SELECTION = 'two_year_volatility_percentile_selection';
export const FIVE_YEAR_VOLATILITY_PERCENTILES_SELECTION = 'five_year_volatility_percentile_selection';
export const ONE_YEAR_VOLATILITY = 'one_year_volatility';
export const TWO_YEAR_VOLATILITY = 'two_year_volatility';
export const FIVE_YEAR_VOLATILITY = 'five_year_volatility';
export const PERFORMANCE_METRICS_SPREADSHEET_TITLE = 'Performance Metrics';

export const VOLATILITY_YEARS = {
  9: ONE_YEAR_VOLATILITY_PERCENTILES_SELECTION,
  10: TWO_YEAR_VOLATILITY_PERCENTILES_SELECTION,
  11: FIVE_YEAR_VOLATILITY_PERCENTILES_SELECTION,
};

export const VOLATILITY_YEARS_VALUES = [
  ONE_YEAR_VOLATILITY_PERCENTILES_SELECTION,
  TWO_YEAR_VOLATILITY_PERCENTILES_SELECTION,
  FIVE_YEAR_VOLATILITY_PERCENTILES_SELECTION,
];

export const VOLATILITY_YEARS_COMPANY_CELL = {
  9: ONE_YEAR_VOLATILITY,
  10: TWO_YEAR_VOLATILITY,
  11: FIVE_YEAR_VOLATILITY,
};

// Multiple options constants
export const PERCENTILE_25 = '25th Percentile';
export const PERCENTILE_75 = '75th Percentile';
export const SPECIFIED_LABEL = 'Specified';
export const MEDIAN_LABEL = 'Median';
export const MEAN_LABEL = 'Mean';

export const VALUES_OPTIONS = [MEDIAN_LABEL, MEAN_LABEL, PERCENTILE_25, PERCENTILE_75, SPECIFIED_LABEL];

export const VALUES_OPTIONS_OBJECT = VALUES_OPTIONS.reduce<Record<string, string>>(
  (obj, option) => ({
    ...obj,
    [option]: option,
  }),
  {}
);
