export const cellsParser = (data, colConfig, cell) => {
  let colConfigTemp = {};
  if (colConfig?.length) {
    colConfigTemp = colConfig.reduce((acc, curr) => {
      const { alias, ...rest } = curr;
      return { ...acc, [alias]: { ...rest, alias } };
    }, {});
  }
  return data.reduce((acc, row, rowIndex) => {
    const prop = Object.entries(row).reduce((acc, curr) => {
      const config = { ...colConfigTemp[curr.alias], readOnly: cell?.isDisabled };

      const [key, val] = curr;
      const { alias = key, sourceTable } = config;
      const value = row[alias] || val;
      const cellKey = `${alias}${rowIndex + 1}`;

      const expr = config.expr ? config.expr.replace(/#/g, rowIndex + 1).replace(/@/g, cell?.columnLegend) : '';

      const regularCell = {
        ...config,
        value,
        alias,
        key: cellKey,
        insideLedger: true,
        rowNumber: rowIndex,
        expr,
      };
      // special cells for shares ledger in FundOwnership
      if (row?.acquisition_ref) {
        regularCell.acquisition_ref = row.acquisition_ref;
      }
      const purchaseDateCell = { ...regularCell, sourceTable };
      const soldSharesCell = { ...regularCell, value: row[alias] || null };

      const sourceTableMap = {
        purchased: purchaseDateCell,
        sold: soldSharesCell,
      };

      return {
        ...acc,
        [cellKey]: sourceTable ? sourceTableMap[sourceTable] : regularCell,
      };
    }, {});

    return { ...acc, ...prop };
  }, {});
};

export const reverseCellsParser = (rows, cells) =>
  rows.map((row, rowIndex) =>
    Object.entries(row).reduce(
      (acc, [key]) => ({
        ...acc,
        [key]: cells[`${key}${rowIndex + 1}`] ? cells[`${key}${rowIndex + 1}`].value : null,
      }),
      {}
    )
  );
