import React from 'react';
import { Button, Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ListItemsSubMenu from './ListItemsSubMenu';

const ListItemsMenu = ({
  menuItem,
  open,
  activeFirstLevel,
  handleMenu,
  classes,
  openDropDown,
  handleSubMenu,
  companySlugParam,
  fundSlugParam,
}) => {
  const { title, slug, icon, subMenu, filterComponent } = menuItem;

  const subMenuProps = {
    menuItem,
    classes,
    openDropDown,
    handleSubMenu,
    companySlugParam,
    fundSlugParam,
  };

  return (
    <div key={slug} data-testid={slug}>
      <ListItem
        id={`sidebar-menu-${slug}`}
        component={Button}
        selected={activeFirstLevel === slug}
        className={`${classes.item} sidebar-menu-first-level`}
        onClick={event => handleMenu(event, menuItem)}>
        <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
        {open && <ListItemText primary={title} className={classes.itemText} />}
        {open && subMenu && (activeFirstLevel === slug ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>

      <Collapse in={open && activeFirstLevel === slug} timeout="auto" unmountOnExit className={classes.subMenu}>
        {filterComponent && <ListItem className={clsx(classes.subitem)}>{filterComponent}</ListItem>}
        <List component="div" disablePadding>
          {subMenu?.map(subMenuItem => (
            <ListItemsSubMenu
              idIndex={subMenuItem.id}
              subMenuItem={subMenuItem}
              key={`${subMenuItem.slug}-${subMenuItem.id}`}
              {...subMenuProps}
            />
          ))}
          {menuItem.actionMenu && (
            <ListItemsSubMenu
              idIndex={subMenu.length ? subMenu.length + 1 : 0}
              subMenuItem={menuItem.actionMenu}
              key={`${menuItem.actionMenu.slug}-${subMenu.length ? subMenu.length + 1 : 0}`}
              {...subMenuProps}
            />
          )}
        </List>
      </Collapse>
    </div>
  );
};

ListItemsMenu.propTypes = {
  menuItem: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    icon: PropTypes.node,
    subMenu: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        icon: PropTypes.node,
      })
    ),
    filterComponent: PropTypes.node,
    actionMenu: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
    }),
  }).isRequired,
  open: PropTypes.bool.isRequired,
  activeFirstLevel: PropTypes.string,
  handleMenu: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    item: PropTypes.string,
    itemIcon: PropTypes.string,
    itemText: PropTypes.string,
    subMenu: PropTypes.string,
    subitem: PropTypes.string,
  }).isRequired,
  openDropDown: PropTypes.func.isRequired,
  handleSubMenu: PropTypes.func.isRequired,
  companySlugParam: PropTypes.string,
  fundSlugParam: PropTypes.string,
};

export default ListItemsMenu;
