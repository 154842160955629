/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import {
  ACCRUED_DIVIDENDS_ROW_NUMBER,
  INVESTMENT_DATE_ROW_NUMBER,
  MULTIPLE_INVESTMENT_LEDGER_ROW_NUMBER,
  SHARES_OUTSTANDING_ROW_NUMBER,
} from 'common/constants/cap-table';
import { useStore } from 'common/store';
import { LedgerTable } from 'components';
import { LedgerDialog } from 'components/Dialogs';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import { cellsParser, reverseCellsParser } from 'components/LedgerTable/utilities/ledgerParser';
import CapTableContext from 'context/CapTableContext';
import { calcSharesOutstandingFromMultipleInvestments } from 'pages/CapTable/cap-table/utilities/parser';
import { useTableValidation } from 'services/hooks';
import { onCellsChanged as onLedgerCellsChanged } from 'utillities';
import { gridShortDate } from 'utillities/datesFormats';
import colConfig from './colConfig';

const emptyRow = {
  date: null,
  shares: null,
  accrued_dividends: null,
  isNew: true,
};

const MultipleInvestmentDates = ({ cell, closeDialog }) => {
  const [{ captableInfo }] = useStore();
  const [rows, setRows] = useState();
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [ledgerCells, setLedgerCells] = useState();

  const { validateTable } = useTableValidation();
  const { cells, columns, onCellsChanged, format } = useContext(FeaturedSpreadsheetContext);
  const { isDisabled, measurementDate } = useContext(CapTableContext);

  const sourceKey = useMemo(() => cell.columnLegend + MULTIPLE_INVESTMENT_LEDGER_ROW_NUMBER, []);
  const investmentDateKey = useMemo(() => cell.columnLegend + INVESTMENT_DATE_ROW_NUMBER, []);
  const sharesOutstandingKey = useMemo(() => cell.columnLegend + SHARES_OUTSTANDING_ROW_NUMBER, []);
  const accruedDividendsKey = useMemo(() => cell.columnLegend + ACCRUED_DIVIDENDS_ROW_NUMBER, []);

  // Reserve the cells parse
  const investmentDates = useMemo(() => {
    if (!isEmpty(ledgerCells) && !isEmpty(rows)) {
      return reverseCellsParser(rows, ledgerCells);
    }
    return [];
  }, [rows, ledgerCells]);

  const config = useMemo(() => (isEmpty(captableInfo) ? [] : colConfig(measurementDate)), []);

  const addRow = () => {
    setRows([...investmentDates, emptyRow]);
  };

  const deleteRow = rowIndex => {
    const tmpRows = [...investmentDates];
    tmpRows.splice(rowIndex, 1);
    setRows(tmpRows);
  };

  const save = () => {
    const tmpCells = Object.entries(ledgerCells).map(([, cell]) => cell);
    const { areCellsValid, validatedCells } = validateTable({
      cellsToValidate: tmpCells.filter(cell => cell.insideLedger),
      toggleAlert: false,
    });

    if (areCellsValid) {
      const tmpState = { ...cells };
      const changes = [];

      // Find the earliest date to save it as the default Investment Date
      const earliestDate = investmentDates
        .map(investment => gridShortDate(investment.date))
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .shift();

      // Reset "Investment Date" and "Shares Outstanding"
      if (isEmpty(investmentDates)) {
        // Reset "Investment Date"
        changes.push({
          cell: tmpState[investmentDateKey],
          value: gridShortDate(),
        });

        // Reset "Shares Outstanding"
        changes.push({
          cell: tmpState[sharesOutstandingKey],
          value: tmpState[sharesOutstandingKey].defaultValue,
        });
      }

      // Save shares outstanding and investment date
      if (investmentDates[0]) {
        changes.push({
          cell: tmpState[investmentDateKey],
          value: earliestDate,
        });

        // The shares outstanding (row 5), should be the sum of all the shares in the ledger
        const totalShares = calcSharesOutstandingFromMultipleInvestments(investmentDates);

        changes.push({
          cell: tmpState[sharesOutstandingKey],
          value: totalShares.toString(),
        });
      }

      // Save Investment dates ledger
      changes.push({
        cell: tmpState[sourceKey],
        value: investmentDates,
      });

      onCellsChanged(changes);
      closeDialog();
    } else {
      setIsAlertVisible(true);
      // Replace current cells with the validated ones
      setLedgerCells(validatedCells.reduce((acc, curr) => ({ ...acc, [curr.key]: curr }), {}));
    }
  };

  const getInitialData = () => {
    const cellColumnIndex = cell.columnOrder;
    const findMultipleInvestments = columns.find(column => column.order === cellColumnIndex)?.multiple_investments;

    const valueDate = cells[investmentDateKey].value;
    let ledgerData = [...cells[sourceKey].value];

    // Use the security values to create the first row
    if (isEmpty(ledgerData)) {
      ledgerData = findMultipleInvestments;
    }

    if (isEmpty(findMultipleInvestments)) {
      ledgerData.unshift({
        date: valueDate ? gridShortDate(valueDate) : null,
        shares: cells[sharesOutstandingKey].value || 0,
        accrued_dividends: cells[accruedDividendsKey].value || 0,
      });
    }

    setRows(ledgerData);
  };

  useEffect(() => {
    if (cells && sourceKey && cells[sourceKey]) {
      getInitialData();
    }
  }, [cells, sourceKey]);

  useEffect(() => {
    if (!isNil(rows)) {
      const parsedCells = cellsParser(rows, config, cell);
      const changes = rows.map((_, index) => {
        const dividendCell = parsedCells[`shares${index + 1}`];
        return {
          cell: dividendCell,
          value: dividendCell.expr,
        };
      });
      onLedgerCellsChanged(changes, { ...parsedCells, ...cells }).then(updatedCells => {
        setLedgerCells(updatedCells);
      });
    }
  }, [rows]);

  return (
    <LedgerDialog
      id="multiple-investment-dates-ledger"
      title="Multiple Investment Dates"
      onSave={save}
      onClose={closeDialog}
      showDeleteColumn
      disabled={cell.isDisabled}>
      {ledgerCells && (
        <LedgerTable
          cell={cell}
          colConfig={config}
          rows={rows}
          setRows={setRows}
          emptyRow={emptyRow}
          disabled={isDisabled}
          tableTerms={{
            tableName: 'Multiple Investment Dates',
            tableSlug: 'multiple-investment-dates-ledger',
            columnName: 'Investment Date',
            pluralColumnName: 'Investment Dates',
          }}
          isAlertVisible={isAlertVisible}
          setIsAlertVisible={setIsAlertVisible}
          addRow={addRow}
          deleteRow={deleteRow}
          cells={ledgerCells}
          sharedCells={cells}
          setCells={setLedgerCells}
          currency={format.currency}
          showTotalRow
        />
      )}
    </LedgerDialog>
  );
};

MultipleInvestmentDates.propTypes = {
  columnLegend: PropTypes.string,
  cell: PropTypes.object,
  closeDialog: PropTypes.func,
};

export default MultipleInvestmentDates;
