/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty, isEqual, isNull, isUndefined } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { ERROR_403 } from 'common/config/api';
import {
  firmCompGroupsUrl,
  firmRequestsUrl,
  firmSettingsUrl,
  firmSummaryUrl,
  firmUserManagementUrl,
  notFoundPath,
} from 'common/config/urls';
import { PROCESS_MANAGEMENT_ENABLER } from 'common/constants/features';
import {
  COMP_GROUPS_SLUG,
  FIRM_REQUESTS_SLUG,
  FIRM_SETTINGS_SLUG,
  FIRMS_SUMMARY_SLUG,
  FORBIDDEN_FIRM_SUMMARY,
  GET_FIRM_SUMMARY_ACCESS,
  REQUESTS,
} from 'common/constants/firms';
import { FIRM_TYPE } from 'common/constants/pageTypes';
import { forbiddenPath } from 'common/constants/paths';
import { USER_MANAGEMENT_PAGE_TITLE, USER_MANAGEMENT_TAB } from 'common/constants/user';
import { useStore } from 'common/store';
import { MessageBox } from 'components';
import { ExtendedFabButton } from 'components/Buttons';
import useCheckFeatures from 'components/FeatureEnabler/hooks';
import LayoutContext from 'context/LayoutContext';
import { FirmDialog } from 'layouts/Main/components';
import useHandleClickDialog from 'layouts/Main/components/NewCompanyDialog/utilities/useHandleClickDialog';
import { UserPermissions as UserPermissionsView } from 'pages';
import CompGroups from 'pages/CompGroups/components/CompGroups';
import { PRIVATE_TRANSACTIONS } from 'pages/CompGroups/constants/constants';
import { FundsSummaries } from 'pages/Funds/components';
import { PUBLIC_COMPANIES } from 'pages/Valuations/util/constants';
import { usePendingDecisionsByFirm, useResponse } from 'services/hooks';
import useGetFundByFirmId, { useCreateOrUpdateCompGroups } from 'services/hooks/firm';
import Settings from './FirmSettings';
import NoCompanyMessage from './NoCompanyMessage';
import NoInformationMessage from './NoInformationMessage';
import Requests from './Requests/FirmRequests';
import generateCompGroupsRequest from './utilities/generateCompGroupsRequest';

const Firms = () => {
  const handleClick = useHandleClickDialog();
  const { errorNotification } = useResponse();
  const { firmSlugParam, tableSlugParam } = useParams();
  const history = useHistory();
  const [{ firmList, companyList, isShowLoadingProgress, firmId, user }] = useStore();
  const [fundsSummary, getFundByFirmId] = useGetFundByFirmId();
  const [GPCCompsGroups, setGPCCompsGroups] = useState();
  const [transactionCompsGroups, setTransactionCompsGroups] = useState();
  const [updatedCompGroups, createOrUpdateCompGroups] = useCreateOrUpdateCompGroups();
  const [features, checkFeature] = useCheckFeatures();
  const { displayDecisionAlerts, getDisconnectDialog, fetchPendingDecisions } = usePendingDecisionsByFirm();
  const [compsToDelete, setCompsToDelete] = useState([]);
  // accordions expanded state
  const [expandedState, setExpandedState] = useState({});
  const [transactionsValidatedGrids, setTransactionsValidatedGrids] = useState({});
  const [showTransactionCellsWithErrors, setShowTransactionCellsWithErrors] = useState(false);

  const {
    setNavItems,
    setPageTitle,
    setPageType,
    setPageActions,
    showPageForm,
    togglePageForm,
    setPageBreadcrumbs,
    toggleExportDialog,
    setExtraPageActions,
  } = useContext(LayoutContext);

  const activeFirm = useMemo(
    () =>
      isEmpty(firmList) || firmList === ERROR_403 || !firmSlugParam
        ? null
        : firmList.find(item => item.slug === firmSlugParam),
    [firmList, firmSlugParam]
  );
  const renderSuperuserPage = useMemo(
    () => user?.is_superuser && firmSlugParam === '0' && tableSlugParam === 'comp-groups',
    [user, firmSlugParam]
  );

  const funds = useMemo(
    () =>
      !fundsSummary || isEmpty(activeFirm) || isEqual(fundsSummary, ERROR_403)
        ? null
        : fundsSummary.map(item => ({
          id: item.fund_id,
          name: item.fund_name,
          slug: item.fund_slug,
          currency: item.fund_currency,
        })),
    [fundsSummary, activeFirm]
  );

  const menuItems = useMemo(() => {
    const compGroupsItem = {
      callback: () => history.push(firmCompGroupsUrl(firmSlugParam)),
      id: 'comp-groups',
      itemKey: 0,
      slug: 'comp-groups',
      title: 'Comp Groups',
    };

    if (renderSuperuserPage) {
      return [compGroupsItem];
    }

    const firmTabs = [
      {
        callback: () => history.push(firmSummaryUrl(firmSlugParam)),
        id: 'summary',
        itemKey: 1,
        slug: 'summary',
        title: 'Summary',
      },
      {
        callback: () => history.push(firmSettingsUrl(firmSlugParam)),
        id: 'edit-firm',
        itemKey: 2,
        slug: FIRM_SETTINGS_SLUG,
        title: 'Settings',
      },
      compGroupsItem,
      {
        callback: () => history.push(firmUserManagementUrl(firmSlugParam)),
        id: USER_MANAGEMENT_TAB,
        itemKey: 3,
        slug: USER_MANAGEMENT_TAB,
        title: USER_MANAGEMENT_PAGE_TITLE,
      },
    ];

    if (checkFeature(PROCESS_MANAGEMENT_ENABLER)) {
      firmTabs.push({
        callback: () => history.push(firmRequestsUrl(firmSlugParam)),
        id: REQUESTS.toLowerCase(),
        itemKey: 4,
        slug: FIRM_REQUESTS_SLUG,
        title: REQUESTS,
      });
    }

    return firmTabs;
  }, [firmSlugParam, renderSuperuserPage, features]);

  const saveCompGroups = areCellsValid => {
    if (!areCellsValid) {
      return;
    }
    const compGroupsList = [...GPCCompsGroups, ...transactionCompsGroups];

    // generate payload, prevent firm admin from updating global comp groups
    const validCompGroups = renderSuperuserPage ? compGroupsList : compGroupsList.filter(({ is_global }) => !is_global);
    const validRequestBody = generateCompGroupsRequest(validCompGroups, compsToDelete, renderSuperuserPage);
    const firmIdentifier = renderSuperuserPage ? 0 : firmId;
    createOrUpdateCompGroups(firmIdentifier, validRequestBody);
  };

  const handleValidate = () => {
    const compGroupsList = [...GPCCompsGroups, ...transactionCompsGroups];
    const allCompGroupsHaveValidNames = compGroupsList.every(({ name }) => name !== '');
    const allGPCCompGroupsHaveData = GPCCompsGroups?.every(group => group.gpc_comps.length);
    const allPTCompGroupsHaveData = transactionCompsGroups?.every(group => group.pt_comps?.length);

    if (allCompGroupsHaveValidNames && allGPCCompGroupsHaveData && allPTCompGroupsHaveData) {
      if (Object.values(transactionsValidatedGrids).every(({ isValid }) => isValid)) {
        saveCompGroups(true);
      } else {
        setShowTransactionCellsWithErrors(true);
        errorNotification('Check that the transactions table has valid data');
      }
    } else {
      errorNotification('Check that the comp groups have valid names and are not empty');
    }
  };

  const handleSave = () => {
    if (!isEmpty(GPCCompsGroups) || !isEmpty(transactionCompsGroups) || !isEmpty(compsToDelete)) {
      handleValidate();
    } else {
      errorNotification('You must add at least one comp group to save.');
    }
  };

  // 1. Set page actions
  useEffect(() => {
    if (!isEmpty(firmList) && tableSlugParam === 'comp-groups') {
      setPageActions({
        mainAction: {
          id: 'firms',
          label: 'Save',
          callback: handleSave,
        },
      });
    }

    return () => {
      setPageActions(null);
    };
  }, [firmList, firmId, GPCCompsGroups, transactionCompsGroups, transactionsValidatedGrids]);

  // 2. Get initial data
  useEffect(() => {
    if (!isEmpty(activeFirm)) {
      if (activeFirm.name) {
        setPageTitle(activeFirm.name);
      }

      if (activeFirm.id) {
        getFundByFirmId(activeFirm.id);
      }
    }
    if (isUndefined(activeFirm) && renderSuperuserPage) {
      setPageTitle('Global comp groups');
    }

    return () => {
      setPageTitle(null);
      setPageType(FIRM_TYPE);
    };
  }, [activeFirm, renderSuperuserPage]);

  useEffect(() => {
    const extraActions = [
      {
        label: 'Excel Export',
        callback: toggleExportDialog,
        isActive: true,
      },
    ];

    setExtraPageActions(extraActions);
  }, []);

  useEffect(() => {
    const fetchActiveFirmData = async () => {
      fetchPendingDecisions(activeFirm.id);
    };

    if (activeFirm?.id) {
      fetchActiveFirmData();
    }
  }, [activeFirm?.id]);

  useEffect(() => {
    setNavItems(menuItems);
    return () => setNavItems(null);
  }, [menuItems]);

  // 3. Set breadcrumbs
  const formatSlug = slug => {
    const slugArray = slug.split('-');
    return slugArray.map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' ');
  };
  // update tables with response results
  useEffect(() => {
    if (updatedCompGroups && !isUndefined(renderSuperuserPage)) {
      const filterCriteria = (group_type, requiredType, is_global) =>
        group_type === requiredType && (renderSuperuserPage ? is_global : !is_global);
      setGPCCompsGroups(
        updatedCompGroups.filter(({ group_type, is_global }) => filterCriteria(group_type, PUBLIC_COMPANIES, is_global))
      );
      setTransactionCompsGroups(
        updatedCompGroups.filter(({ group_type, is_global }) =>
          filterCriteria(group_type, PRIVATE_TRANSACTIONS, is_global)
        )
      );
      setCompsToDelete([]);
    }
    return () => {
      setGPCCompsGroups(undefined);
      setTransactionCompsGroups(undefined);
    };
  }, [updatedCompGroups, renderSuperuserPage]);

  const breadcrumbs = () => [
    {
      title: activeFirm?.name || 'Admin',
    },
    {
      title: formatSlug(tableSlugParam),
    },
  ];

  useEffect(() => {
    setPageBreadcrumbs(breadcrumbs);
  }, [activeFirm, tableSlugParam]);

  const compGroupsProps = {
    GPCCompsGroups,
    setGPCCompsGroups,
    transactionCompsGroups,
    setTransactionCompsGroups,
    setCompsToDelete,
    expandedState,
    setExpandedState,
    transactionsValidatedGrids,
    setTransactionsValidatedGrids,
    showTransactionCellsWithErrors,
  };

  if (renderSuperuserPage) {
    return <CompGroups {...compGroupsProps} firmId={0} />;
  }

  if (activeFirm && isEmpty(activeFirm)) {
    history.push(notFoundPath);
    return null;
  }
  // wait until user is defined to get renderSuperuserPage defined.
  if (isUndefined(activeFirm) && !isNull(user)) {
    history.push(forbiddenPath);
    return null;
  }

  if (activeFirm?.id && isEqual(fundsSummary, ERROR_403)) {
    return <MessageBox title={FORBIDDEN_FIRM_SUMMARY} tagline={GET_FIRM_SUMMARY_ACCESS} fullWidth={false} />;
  }

  // User should be able to edit a firm or create comp groups even if there are no companies
  if (tableSlugParam === FIRMS_SUMMARY_SLUG && Array.isArray(companyList) && companyList.length === 0) {
    return <NoCompanyMessage />;
  }

  const areFundsAvailable = ({ fundsParam }) => fundsParam && isEmpty(fundsParam);

  return (
    <>
      {tableSlugParam === FIRMS_SUMMARY_SLUG && (
        <>
          {/* Display Decision alerts */}
          {displayDecisionAlerts()}

          {/* areFundsAvailable will always be false because the field should be fundsParam */}
          {areFundsAvailable({ funds }) ? <NoInformationMessage /> : <FundsSummaries funds={funds} />}

          {getDisconnectDialog()}

          {showPageForm && (
            <>
              <FirmDialog open={showPageForm} onClose={togglePageForm} />
              <ExtendedFabButton
                id="firm-add-company-btn"
                disabled={isShowLoadingProgress}
                label="Add Company"
                onClick={handleClick}
              />
            </>
          )}
        </>
      )}
      {tableSlugParam === COMP_GROUPS_SLUG && (
        <CompGroups {...compGroupsProps} firmId={firmId} compsToDelete={compsToDelete} />
      )}
      {tableSlugParam === FIRM_SETTINGS_SLUG && <Settings />}
      {tableSlugParam === FIRM_REQUESTS_SLUG && <Requests />}
      {tableSlugParam === USER_MANAGEMENT_TAB && <UserPermissionsView />}
    </>
  );
};

export default Firms;
