import React, { FC, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import {
  CAP_TABLE_NEEDS_MORE_SECURITIES_TAGLINE,
  CAP_TABLE_NEEDS_MORE_SECURITIES_TITLE,
} from 'common/constants/cap-table';
import { FINANCIALS_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { FUND_OWNERSHIP_SPREADSHEET_TABLE_TERMS } from 'common/constants/fund-ownership';
import { VALUATIONS_SLUG } from 'common/constants/valuations';
import { useFormat } from 'common/hooks';
import { UseFormatValues } from 'common/types/format';
import { CustomScalarSpreadsheet, EmptyTableMessage } from 'components';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import { EmptyCapTableMessage } from 'pages/CapTable/cap-table/components';
import {
  ALLOCATION_EMPTY_FUND_OWNERSHIP_TAGLINE,
  ALLOCATION_EMPTY_FUND_OWNERSHIP_TITLE,
} from 'pages/ValuationsAllocation/common/constants/allocation';
import {
  EQUITY_ALLOCATION_KEY,
  EQUITY_ALLOCATION_SPREADSHEET_SLUG,
} from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import { EQUITY_ALLOCATION_SPREADSHEET_TABLE_TERMS } from 'pages/ValuationsAllocation/common/constants/equityAllocation/sheetConfigs';
import { VALUATIONS_VALUATION } from 'pages/ValuationsAllocation/common/constants/valuations';
import { RedirectToFundOwnershipButton } from 'pages/ValuationsAllocation/components';
import { getObjectValue, getStringValue } from 'utillities';
import useStyles from './styles';
import { EquityAllocationProps } from './types';

const EQUITY_ALLOCATION_CONTAINER_CLASS = `${VALUATIONS_SLUG}-${EQUITY_ALLOCATION_SPREADSHEET_SLUG}-container`;

const EquityAllocation: FC<EquityAllocationProps> = props => {
  const {
    areCapTableSecuritiesInvalid,
    collapsibleColumns,
    equityAllocationRowsGroups,
    handleDeleteScenario,
    isAllocationEmpty,
    isFundOwnershipEmpty,
    isLoading,
    isLoadingValuationSummary,
    isSaving,
    measurementDate,
    onSpreadsheetChange,
    setCollapsibleColumns,
    setEquityAllocationRowsGroups,
    workbook,
  } = props;

  const { [EQUITY_ALLOCATION_KEY]: equityAllocationSpreadsheet } = getObjectValue(workbook);
  const { data: equityAllocationSpreadsheetData } = getObjectValue(equityAllocationSpreadsheet);

  const classes = useStyles();

  const [format, formatDispatch] = useFormat({ page: FINANCIALS_CURRENCY_PAGE }) as UseFormatValues;

  // Handle transition from GridSkeleton to Equity Allocation
  const isLoadingEquityAllocation = useMemo(
    () => isEmpty(equityAllocationSpreadsheetData),
    [equityAllocationSpreadsheetData]
  );

  // Create Equity Allocation Sheet props
  const equityAllocationSheetProps = useMemo(() => {
    if (equityAllocationSpreadsheet) {
      return {
        ...equityAllocationSpreadsheet,
        collapsibleColumns,
        deleteColumn: handleDeleteScenario,
        format,
        formatDispatch,
        key: getStringValue(equityAllocationSpreadsheet?.name),
        onChange: onSpreadsheetChange,
        rowGroups: equityAllocationRowsGroups,
        setCollapsibleColumns,
        setRowGroups: setEquityAllocationRowsGroups,
        sheet: equityAllocationSpreadsheet,
        workbook,
      } as unknown as typeof ScalarSpreadsheet;
    }

    return null;
  }, [
    collapsibleColumns,
    equityAllocationRowsGroups,
    equityAllocationSpreadsheet,
    format,
    formatDispatch,
    handleDeleteScenario,
    onSpreadsheetChange,
    setCollapsibleColumns,
    setEquityAllocationRowsGroups,
    workbook,
  ]);

  // Loading Equity Allocation
  if (isLoadingEquityAllocation || isLoadingValuationSummary) {
    return (
      <Grid className={clsx(classes.container, EQUITY_ALLOCATION_CONTAINER_CLASS)}>
        <GridSkeleton />
      </Grid>
    );
  }

  // Display empty Fund Ownership message if there is no Fund Ownership
  if (isFundOwnershipEmpty) {
    return (
      <Grid className={clsx(EQUITY_ALLOCATION_CONTAINER_CLASS, classes.container, classes.emptyMessageContainer)}>
        <EmptyTableMessage
          component={<RedirectToFundOwnershipButton measurementDateSlug={getStringValue(measurementDate?.slug)} />}
          tableTerms={FUND_OWNERSHIP_SPREADSHEET_TABLE_TERMS}
          tagline={ALLOCATION_EMPTY_FUND_OWNERSHIP_TAGLINE}
          title={ALLOCATION_EMPTY_FUND_OWNERSHIP_TITLE}
        />
      </Grid>
    );
  }

  // Display empty Cap Table message if Cap Table Securities are invalid
  if (areCapTableSecuritiesInvalid) {
    return (
      <Grid className={clsx(EQUITY_ALLOCATION_CONTAINER_CLASS, classes.container, classes.emptyMessageContainer)}>
        <EmptyCapTableMessage
          page={VALUATIONS_VALUATION}
          tagline={CAP_TABLE_NEEDS_MORE_SECURITIES_TAGLINE}
          title={CAP_TABLE_NEEDS_MORE_SECURITIES_TITLE}
        />
      </Grid>
    );
  }

  // Display empty Allocation message if there are no Allocation Scenarios
  if (isAllocationEmpty) {
    return (
      <Grid className={clsx(EQUITY_ALLOCATION_CONTAINER_CLASS, classes.container, classes.emptyMessageContainer)}>
        <EmptyTableMessage tableTerms={EQUITY_ALLOCATION_SPREADSHEET_TABLE_TERMS} />
      </Grid>
    );
  }

  return (
    <Grid className={clsx(classes.container, EQUITY_ALLOCATION_CONTAINER_CLASS)}>
      {/* Equity Allocation */}
      <CustomScalarSpreadsheet
        {...equityAllocationSheetProps}
        isLoading={isLoading || isLoadingEquityAllocation}
        isSaving={isSaving}
      />
    </Grid>
  );
};

export default EquityAllocation;
