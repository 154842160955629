import { CapTableVersion } from 'common/types/company';
import { handleAllocationMethod } from 'pages/ValuationsAllocation/approaches/FutureExit/AllocationMethodTable/utils/utils';
import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/futureExit';
import { parseCellValue } from 'pages/ValuationsAllocation/util';
import { getNumberValue, getObjectValue } from 'utillities';
import { CustomReverseParserParams, HandlersParams } from './types';

const handleCapTable = (params: HandlersParams) => {
  const { approach, cell, futureExitAttributes } = params;
  const fieldDecimalPlaces = getNumberValue(futureExitAttributes?.cap_table?.decimal_places);

  if (typeof cell.value === 'object') {
    const capTable = cell.value as CapTableVersion;
    approach.cap_table = getNumberValue(capTable.id);
  } else {
    const updatedCellValue = parseCellValue({ cell, fieldDecimalPlaces });
    approach.cap_table = updatedCellValue;
  }
};

const customReverseParser = (params: CustomReverseParserParams) => {
  const { cells, approach, futureExitAttributes } = getObjectValue(params);
  const { valuations_approach_future_exit: valuationFEApproach } = getObjectValue(approach);
  const updatedFEApproach = getObjectValue(valuationFEApproach);

  const { FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD, FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE }
    = SHEET_ALIASES_CONSTANTS;

  Object.values(getObjectValue(cells)).forEach(cell => {
    const { alias } = getObjectValue(cell);

    switch (alias) {
      // Handle Allocation Method
      case FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD: {
        handleAllocationMethod({ approach: updatedFEApproach, cell, futureExitAttributes });
        break;
      }
      // Handle Cap Table Selection
      case FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE: {
        handleCapTable({ approach: updatedFEApproach, cell, futureExitAttributes });
        break;
      }

      default:
        break;
    }
  });
};

export default customReverseParser;
