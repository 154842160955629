import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Collapse, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { CUSTOM_BREAKPOINTS_REFERENCE_TITLE, CUSTOM_BREAKPOINTS_REFERENCE_TYPE } from 'common/constants/documents';
import { FIX_BEFORE_CONTINUE } from 'common/constants/messages/validations';
import { BREAKPOINTS_PAGE_VALUE, CAP_TABLE_PAGE_KEY } from 'common/constants/notes';
import { useStore } from 'common/store';
import { Widgets } from 'components';
import { ExtendedFabButton } from 'components/Buttons';
import { ConfirmationDialog } from 'components/Dialogs';
import { GridSkeleton } from 'components/Grid';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import DialogContext from 'context/DialogContext';
import LayoutContext from 'context/LayoutContext';
import { ConfirmationSaveFinal, ProformaCaptableDialog } from 'pages/CapTable/cap-table/components';
import { CaptableHk, useDocuments, useKeyEventForFabButton } from 'services/hooks';
import { useNotes } from 'services/hooks/notes';
import useCustomBreakpointsTableData from './useCustomBreakpointsTableData';
import conditions from '../common/conditions';

const CustomBreakpoints = props => {
  const {
    captableId,
    hideGrid,
    selectedMeasurementDate,
    customBreakpointColumns,
    saveAsNewProforma,
    format,
    isAllocationFinal,
    isDisabled,
  } = props;
  const [{ captableInfo }] = useStore();
  const { useUpdateCaptableCustomBreakpoints } = CaptableHk;
  const { setPageActions } = useContext(LayoutContext);

  const initialFormValues = useMemo(
    () => ({
      isValid: true,
      name: `${captableInfo?.name} Proforma`,
      values: {},
      errors: {},
    }),
    [captableInfo]
  );

  const {
    spreadsheet: CustomBreakpointsDetailSheet,
    addCustomColumn,
    deleteCustomColumn,
  } = useCustomBreakpointsTableData({
    customBreakpointColumns,
    captableInfo,
    format,
    isDisabled,
  });

  const [spreadsheets, setSpreadsheets] = useState([CustomBreakpointsDetailSheet]);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [openProformaDialog, setOpenProformaDialog] = useState(false);
  const [formState, setFormState] = useState(initialFormValues);
  const [, setTableData] = useState();
  const [, updateCustomBreakpoints] = useUpdateCaptableCustomBreakpoints();
  const { filesToSave, setFilesToSave, addReferenceForExistingDocuments } = useDocuments();
  const { notes, setNotes, notesHasChanged, saveNotes, onAddNote, onUpdateNotes, onDeleteNote } = useNotes();
  const [isDisplayingRowNumbers, setIsDisplayingRowNumbers] = useState(false);
  const { showDialog } = useContext(DialogContext);

  const { onChange, workbook } = useWorkbook(spreadsheets, setTableData);

  useEffect(() => {
    if (CustomBreakpointsDetailSheet?.yLength > 0) {
      setSpreadsheets([CustomBreakpointsDetailSheet]);
    }
  }, [captableInfo, CustomBreakpointsDetailSheet]);

  useEffect(() => {
    if (hideGrid) {
      // Hide alert, if present, when disabling the custom breakpoints
      setIsAlertVisible(false);
    }
  }, [hideGrid]);

  const getGridData = useCallback(() => {
    const breakpointsColumns = CustomBreakpointsDetailSheet?.columns;
    const tmpCustomBreakpoints = isEmpty(breakpointsColumns)
      ? captableInfo?.custom_breakpoints?.breakpoints
      : breakpointsColumns;
    const adjustedBreakPoints = (tmpCustomBreakpoints || []).map(({ isDeleteableColumn, ...bp }) => bp);

    return {
      use_custom_breakpoints: !hideGrid,
      custom_breakpoints: {
        breakpoints: adjustedBreakPoints,
        prices_per_share: adjustedBreakPoints?.map(bpCol => bpCol.price),
      },
    };
  }, [CustomBreakpointsDetailSheet, captableInfo, hideGrid]);

  const saveConfirmation = useCallback(
    callback => {
      if (isAllocationFinal) {
        showDialog({
          wrapper: ConfirmationDialog,
          wrapperProps: {
            title: 'Confirmation',
          },
          content: ConfirmationSaveFinal,
          actions: [
            {
              label: 'Cancel',
              variant: 'text',
            },
            {
              label: 'Save',
              variant: 'contained',
              color: 'primary',
              callback,
            },
          ],
        });
      }
    },
    [isAllocationFinal, showDialog]
  );

  const saveProforma = useCallback(() => {
    const gridData = getGridData();

    saveAsNewProforma({
      ...captableInfo,
      ...gridData,
      name: formState.values.name || `${captableInfo.name} Proforma`,
    });
  }, [captableInfo, formState.values.name, getGridData, saveAsNewProforma]);

  const handleSave = () => {
    // Save files to be uploaded
    if (!isEmpty(filesToSave)) {
      addReferenceForExistingDocuments(
        selectedMeasurementDate.id,
        filesToSave,
        captableId,
        CUSTOM_BREAKPOINTS_REFERENCE_TYPE
      );
    }
    if (!isEmpty(notes) && notesHasChanged) {
      saveNotes();
    }
    const cells = CustomBreakpointsDetailSheet?.cells || {};
    const cellsToValidate = Object.entries(cells).map(([, cell]) => cell);
    const areCellsInvalid = cellsToValidate.some(cell => cell.isValid === false);
    if (CustomBreakpointsDetailSheet?.columns) {
      // Allow saving even if not all custom BPs are valid
      // (used to turn them off instead of overwriting them)
      if (!areCellsInvalid || hideGrid) {
        const gridData = getGridData();

        if (isAllocationFinal) {
          saveConfirmation(() => {
            updateCustomBreakpoints(captableId, gridData);
            setIsAlertVisible(false);
          });
        } else {
          updateCustomBreakpoints(captableId, gridData);
          setIsAlertVisible(false);
        }
      } else {
        setIsAlertVisible(true);
      }
    }
  };

  const savesAsProformaConfirmation = () => {
    setOpenProformaDialog(true);
  };

  useEffect(() => {
    if (!isEmpty(captableInfo)) {
      setPageActions({
        mainAction: {
          id: 'custom-breakpoints',
          label: 'Save',
          isActive: isDisabled,
          callback: handleSave,
          isLock: isAllocationFinal,
        },
        secondaryActions: [
          {
            label: 'Save as New Proforma',
            isActive: captableInfo.is_primary,
            callback: savesAsProformaConfirmation,
          },
        ],
      });
    }
    return () => setPageActions(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captableInfo, hideGrid, filesToSave, CustomBreakpointsDetailSheet, notes, isDisabled, isAllocationFinal]);

  const CustomBreakpointsDetailSheetProps = {
    ...CustomBreakpointsDetailSheet,
    sheet: CustomBreakpointsDetailSheet,
    workbook,
    onChange,
    page: 'captable',
    className: 'waterfall-custom-width',
    deleteColumn: deleteCustomColumn,
    conditions,
    setIsDisplayingRowNumbers,
  };

  useKeyEventForFabButton(hideGrid);

  const handleOnCloseAlert = useCallback(() => {
    setIsAlertVisible(false);
  }, []);

  const handleOnCloseProformaCapTable = useCallback(() => {
    setOpenProformaDialog(false);
  }, []);

  const handleOnFormChange = useCallback(formValues => {
    setFormState(formValues);
  }, []);

  const renderProformaCaptable = useMemo(
    () => (
      <ProformaCaptableDialog
        editMode={false}
        initialValues={initialFormValues || false}
        isValid={formState?.isValid}
        onClose={handleOnCloseProformaCapTable}
        onFormChange={handleOnFormChange}
        onSave={saveProforma}
        open={openProformaDialog || false}
        tableTerms={{ tableName: 'Cap Table' }}
        title="New Proforma Cap Table"
      />
    ),
    [formState, handleOnCloseProformaCapTable, handleOnFormChange, initialFormValues, openProformaDialog, saveProforma]
  );

  if (!hideGrid && !CustomBreakpointsDetailSheet?.data) {
    return <GridSkeleton />;
  }

  return (
    <>
      <Collapse in={isAlertVisible}>
        <Alert
          variant="outlined"
          severity="error"
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleOnCloseAlert}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {FIX_BEFORE_CONTINUE}
        </Alert>
        <br />
      </Collapse>

      {/* Proforma Cap Table */}
      {renderProformaCaptable}

      {!hideGrid && (
        <PerfectScrollbar className="always-visible" id="custom-breakpoints">
          <ScalarSpreadsheet {...CustomBreakpointsDetailSheetProps} />
          <Widgets
            documentReferencesProps={{
              selectedMeasurementDate,
              referenceType: CUSTOM_BREAKPOINTS_REFERENCE_TYPE,
              currentPage: CUSTOM_BREAKPOINTS_REFERENCE_TITLE,
              referencedFeatureId: captableId,
              filesToSave,
              setFilesToSave,
              isDisplayingRowNumbers,
              isDisabled,
            }}
            notesProps={{
              pageType: BREAKPOINTS_PAGE_VALUE,
              pageTypeKey: CAP_TABLE_PAGE_KEY,
              pageTypeId: captableId,
              notes,
              setNotes,
              onAddNote,
              onUpdateNotes,
              onDeleteNote,
              isDisabled,
            }}
          />
          <br />
        </PerfectScrollbar>
      )}
      <ExtendedFabButton
        id="breakpoint-add-btn"
        label="Add Breakpoint"
        onClick={addCustomColumn}
        disabled={hideGrid || isDisabled}
      />
    </>
  );
};

CustomBreakpoints.propTypes = {
  captableId: PropTypes.number,
  hideGrid: PropTypes.bool,
  selectedMeasurementDate: PropTypes.object,
  saveAsNewProforma: PropTypes.func,
  customBreakpointColumns: PropTypes.array,
  format: PropTypes.object,
  isAllocationFinal: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default CustomBreakpoints;
