import React, { FC } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isEmpty } from 'lodash';
import StyledDocumentSubtitle from './StyledDocumentSubtitle';
import { CurrentRequestedTasksAccordionProps } from './types';
import useStyles from '../styles';

const CurrentRequestedTasksAccordion: FC<CurrentRequestedTasksAccordionProps> = ({
  id,
  title,
  subTitles,
  childrenComponent,
  values,
}) => {
  const classes = useStyles();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${id}-content`} id={`${id}-header`}>
        <Typography variant="h2" gutterBottom className={classes.accordionTitle}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Box display="flex" justifyContent="space-between" marginBottom={4}>
          {subTitles.map(subTitle => (
            <StyledDocumentSubtitle key={subTitle.id}>{subTitle.label}</StyledDocumentSubtitle>
          ))}
        </Box>
        {!isEmpty(values) && childrenComponent}
      </AccordionDetails>
    </Accordion>
  );
};

export default CurrentRequestedTasksAccordion;
