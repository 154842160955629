import { EXTERNAL_VALUATION_REFERENCE_TYPE, SSV_REFERENCE_TYPE } from 'common/constants/documents';
import { getSlugValue } from 'utillities';

export const VALUATIONS_VALUATION = 'valuation';
export const VALUATIONS_APPROACH_QUERY_PARAM = 'approach';

export const VALUATIONS_SPREADSHEET_COLUMN_NAME = 'approach';
export const VALUATIONS_SPREADSHEET_COLUMN_NAME_PLURAL = 'approaches';
export const VALUATIONS_SPREADSHEET_TITLE = 'Valuation';
export const VALUATIONS_SPREADSHEET_SLUG = getSlugValue(VALUATIONS_SPREADSHEET_TITLE);
export const VALUATIONS_SPREADSHEET_TABLE = `${VALUATIONS_SPREADSHEET_SLUG}-table`;
export const COMPANY_ROW_ALIAS = 'company';

export const VALUATIONS_SPREADSHEET_TABLE_TERMS = Object.freeze({
  columnName: VALUATIONS_SPREADSHEET_COLUMN_NAME,
  pluralColumnName: VALUATIONS_SPREADSHEET_COLUMN_NAME_PLURAL,
  tableName: VALUATIONS_SPREADSHEET_TITLE,
  tableSlug: VALUATIONS_SPREADSHEET_TABLE,
});

// Approaches

export const VALUATIONS_BACKSOLVE_APPROACH = 'Backsolve Valuation';
export const VALUATIONS_BACKSOLVE_APPROACH_MENU = 'Backsolve';
export const VALUATIONS_DISCOUNT_CASH_FLOW_APPROACH = 'Discounted Cash Flow';
export const VALUATIONS_EXTERNAL_VALUATION_APPROACH = 'External Valuation';
export const VALUATIONS_FUTURE_EXIT_APPROACH = 'Future Exit';
export const VALUATIONS_PRIVATE_TRANSACTIONS_APPROACH = 'Private Transactions';
export const VALUATIONS_PUBLIC_COMPANIES_APPROACH = 'Guideline Public Companies';
export const VALUATIONS_PUBLIC_COMPANIES_APPROACH_MENU = 'Public Comps';
export const VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH = 'Guideline Transactions';
export const VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH_MENU = 'Transaction Comps';
export const VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH = 'Specified Share Values';
export const VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH_MENU = 'Specified Share Values';
export const SET_AS_CALIBRATION = 'Set as Calibration';

export const VALUATIONS_APPROACH_TYPES = Object.freeze([
  VALUATIONS_BACKSOLVE_APPROACH,
  VALUATIONS_DISCOUNT_CASH_FLOW_APPROACH,
  VALUATIONS_EXTERNAL_VALUATION_APPROACH,
  VALUATIONS_FUTURE_EXIT_APPROACH,
  VALUATIONS_PUBLIC_COMPANIES_APPROACH,
  VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH,
  VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH,
]);

export const VALUATIONS_APPROACH_TYPES_WITH_VALUATION_DATE_ENABLED = [VALUATIONS_PUBLIC_COMPANIES_APPROACH];

// Approaches Properties

export const VALUATIONS_APPROACH_BACKSOLVE_PROPERTY = 'valuations_approach_backsolve';
export const VALUATIONS_APPROACH_DCF_PROPERTY = 'valuations_approach_dcf';
export const VALUATIONS_APPROACH_EV_PROPERTY = 'valuations_approach_ev';
export const VALUATIONS_APPROACH_FUTURE_EXIT_PROPERTY = 'valuations_approach_future_exit';
export const VALUATIONS_APPROACH_GPC_PROPERTY = 'valuations_approach_gpc';
export const VALUATIONS_APPROACH_GPT_PROPERTY = 'valuations_approach_gpt';
export const VALUATIONS_APPROACH_SSV_PROPERTY = 'valuations_approach_ssv';
export const VALUATIONS_APPROACH_GPC_REFERENCE = 'gpc_reference';
export const VALUATIONS_APPROACH_GPT_REFERENCE = 'gpt_reference';

export const VALUATIONS_APPROACH_TYPES_PROPERTIES_MAP = new Map([
  [VALUATIONS_BACKSOLVE_APPROACH, VALUATIONS_APPROACH_BACKSOLVE_PROPERTY],
  [VALUATIONS_DISCOUNT_CASH_FLOW_APPROACH, VALUATIONS_APPROACH_DCF_PROPERTY],
  [VALUATIONS_EXTERNAL_VALUATION_APPROACH, VALUATIONS_APPROACH_EV_PROPERTY],
  [VALUATIONS_FUTURE_EXIT_APPROACH, VALUATIONS_APPROACH_FUTURE_EXIT_PROPERTY],
  [VALUATIONS_PUBLIC_COMPANIES_APPROACH, VALUATIONS_APPROACH_GPC_PROPERTY],
  [VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH, VALUATIONS_APPROACH_GPT_PROPERTY],
  [VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH, VALUATIONS_APPROACH_SSV_PROPERTY],
]);

// Guideline Public Transactions

export const VALUATIONS_MA_COMPS = 'M&A Comps';
export const VALUATIONS_LINK_WITH_GPT = 'link_with_gpt';
export const VALUATIONS_VALUATION_APPROACH_GPT = 'valuation_approach_gpt';

// Guideline Public Companies

export const VALUATIONS_CANNOT_DELETE_GPC = 'This approach is being used in a Market Adjustment'; // 'This approach cannot be deleted because it is being used in a Market Adjustment';
export const VALUATIONS_LINK_WITH_GPC = 'link_with_gpc';
export const VALUATIONS_SPECIFIED_ID = 0;
export const VALUATIONS_SPECIFIED_LABEL = 'Specified';
export const VALUATIONS_USE_CAP_IQ_ID
  = 'A Company ID from Capital IQ can be used instead of the stock ticker to retrieve data on acquired companies or other entities that are not available via the ticker. The Capital IQ Company ID uses the format ';
export const VALUATIONS_VALUATION_APPROACH_GPC = 'valuation_approach_gpc';
export const VALUATIONS_WAS_NOT_FOUND_MD = 'was not found for the current measurement date';

// Benchmark

export const VALUATIONS_BENCHMARK_APPROACH_TYPES = [
  VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH,
  VALUATIONS_PUBLIC_COMPANIES_APPROACH,
];

export const VALUATIONS_BENCHMARK_RELATED_APPROACHES_TYPES = new Map([
  [VALUATIONS_PUBLIC_COMPANIES_APPROACH, VALUATIONS_APPROACH_GPC_PROPERTY],
  [VALUATIONS_PUBLIC_TRANSACTIONS_APPROACH, VALUATIONS_APPROACH_GPT_PROPERTY],
  [VALUATIONS_DISCOUNT_CASH_FLOW_APPROACH, VALUATIONS_APPROACH_DCF_PROPERTY],
]);

// Cap Table

export const VALUATIONS_CAP_TABLE_CURRENCY_APPROACHES = [
  VALUATIONS_BACKSOLVE_APPROACH,
  VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH,
];

// References

export const VALUATIONS_VALUATION_REFERENCES_MAP = new Map([
  [VALUATIONS_EXTERNAL_VALUATION_APPROACH, EXTERNAL_VALUATION_REFERENCE_TYPE],
  [VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH, SSV_REFERENCE_TYPE],
]);

// Keys

export const VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY = 'enterprise_value';
export const VALUATIONS_SPREADSHEET_EQUITY_VALUE_KEY = 'equity_value';
export const VALUATIONS_SPREADSHEET_GPC_KEY = 'gpc';
export const VALUATIONS_SPREADSHEET_OTHER_KEY = 'compsSheet';

// Errors

export const VALUATIONS_APPROACH_TYPE_NOT_IMPLEMENTED_MESSAGE = 'Approach type not implemented';
export const VALUATIONS_MISSING_FINANCIAL_STATEMENT_TITLE = 'There is no financial statement for this measurement date';
export const VALUATIONS_MISSING_FINANCIAL_STATEMENT_TAGLINE = 'Please save a financial statement to continue';
export const VALUATIONS_FINAL_VALUATION_LOCKED_MESSAGE
  = 'This Valuation table version is used in a final valuation, any changes saved could affect that valuation';

// Regex

export const VALUATIONS_SPREADSHEET_APPROACH_TABLE_NAME_REGEX = /\b([a-zA-Z]+_\d+)/;
