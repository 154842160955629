import { MeasurementDate } from 'common/types/measurementDate';
import { ValuationsApproach } from 'common/types/valuation';
import {
  LTM_ADJUSTED_EBITDA,
  LTM_EBITDA,
  LTM_REVENUE,
  NTM_ADJUSTED_EBITDA,
  NTM_EBITDA,
  NTM_REVENUE,
  SPECIFIED_LABEL,
  SPECIFIED_LTM_EBITDA,
  SPECIFIED_LTM_REVENUE,
  SPECIFIED_NTM_EBITDA,
  SPECIFIED_NTM_REVENUE,
} from 'pages/ValuationsAllocation/common/constants/futureExit';
import {
  MEAN_LABEL,
  MEDIAN_LABEL,
  PERCENTILE_25,
  PERCENTILE_75,
} from 'pages/ValuationsAllocation/common/constants/futureExit/sheetConfigs';
import {
  VALUATIONS_APPROACH_GPC_PROPERTY,
  VALUATIONS_APPROACH_GPT_PROPERTY,
  VALUATIONS_VALUATION_APPROACH_GPC,
  VALUATIONS_VALUATION_APPROACH_GPT,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { FinancialsTransposed } from 'pages/ValuationsAllocation/util';

export interface FieldAttributes {
  decimal_places?: string | number;
  max_digits?: string | number;
  type?: string;
}
export interface FutureExitApproachAttrs {
  is_deleted?: FieldAttributes;
  deleted_at?: FieldAttributes;
  financials_metric?: FieldAttributes;
  financials_metric_value?: FieldAttributes;
  referenced_approach_multiple?: FieldAttributes;
  referenced_approach_multiple_value?: FieldAttributes;
  less_debt?: FieldAttributes;
  plus_cash?: FieldAttributes;
  present_enterprise_value?: FieldAttributes;
  discount_rate?: FieldAttributes;
  dilution_percentage?: FieldAttributes;
  exit_date?: FieldAttributes;
}

export interface CreateFutureEquityValueSheetParams {
  approach?: ValuationsApproach;
  approaches?: Array<ValuationsApproach>;
  futureExitApproachAttrs?: FutureExitApproachAttrs;
  fieldAttributes?: FutureExitApproachAttrs;
  isDisabled?: boolean;
  isStatic?: boolean;
  financials: FinancialsTransposed;
  staticFinancials?: {
    ltmData: any;
    ntmData: any;
    use_adjusted_ebitda?: boolean;
  };
  measurementDate?: MeasurementDate;
}

export interface MultipleMapKeys {
  [SPECIFIED_LABEL]: string;
  [MEAN_LABEL]: string;
  [MEDIAN_LABEL]: string;
  [PERCENTILE_75]: string;
  [PERCENTILE_25]: string;
}

export const METRIC_OPTION_MAP = {
  [LTM_REVENUE]: LTM_REVENUE,
  [NTM_REVENUE]: NTM_REVENUE,
  [LTM_EBITDA]: LTM_EBITDA,
  [NTM_EBITDA]: NTM_EBITDA,
  [LTM_ADJUSTED_EBITDA]: LTM_ADJUSTED_EBITDA,
  [NTM_ADJUSTED_EBITDA]: NTM_ADJUSTED_EBITDA,
  [SPECIFIED_LTM_REVENUE]: LTM_REVENUE,
  [SPECIFIED_NTM_REVENUE]: NTM_REVENUE,
  [SPECIFIED_LTM_EBITDA]: LTM_EBITDA,
  [SPECIFIED_NTM_EBITDA]: NTM_EBITDA,
};

export type MetricOptionMapKeys = {
  [key in
    | typeof LTM_REVENUE
    | typeof NTM_REVENUE
    | typeof LTM_EBITDA
    | typeof NTM_EBITDA
    | typeof LTM_ADJUSTED_EBITDA
    | typeof NTM_ADJUSTED_EBITDA
    | typeof SPECIFIED_LTM_REVENUE
    | typeof SPECIFIED_NTM_REVENUE
    | typeof SPECIFIED_LTM_EBITDA
    | typeof SPECIFIED_NTM_EBITDA]: string;
};

export type ValuationApproachCompsMapKeys = {
  [key in typeof VALUATIONS_VALUATION_APPROACH_GPC | typeof VALUATIONS_VALUATION_APPROACH_GPT]: string;
};

export type ValuationApproachMapKeys = {
  [key in typeof VALUATIONS_APPROACH_GPC_PROPERTY | typeof VALUATIONS_APPROACH_GPT_PROPERTY]: string;
};
