import React, { useContext } from 'react';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import CompGroupContext from 'context/CompGroupContext';
import { getSelectionCellOptions } from 'pages/ValuationsAllocation/util/getSelectionCellOptions';

const MultipleTypeSelect = props => {
  const { cellOptions } = useContext(CompGroupContext);
  const options = getSelectionCellOptions({
    specificApproach: cellOptions,
  });
  return <GridSelect useHandleChange={false} options={options} menuPlacement="top" {...props} enumerated />;
};
export default MultipleTypeSelect;
