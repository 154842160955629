import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    gpcInMarketAdjustment: {
      color: theme.palette.text.secondary,
      fontSize: theme.spacing(1.75),
      marginLeft: theme.spacing(1),
    },
    isBenchmarkCheckBox: {
      padding: 0,
      marginLeft: theme.spacing(1),
    },
    approachValuesContainer: {
      alignItems: 'flex-start',
      borderTop: `${theme.spacing(0.5)}px solid ${theme.palette.primary.main}`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      opacity: '1 !important',
      width: '100%',
    },
    approachValues: {
      '& .MuiListItemText-primary': {
        color: theme.palette.primary.main,
        fontWeight: 700,
        textTransform: 'uppercase',
      },

      '& .MuiListItemText-secondary': {
        color: theme.palette.primary.main,
        fontSize: theme.spacing(1.75),
      },
    },
  })
);

export default useStyles;
