import React, { useContext } from 'react';
import { SelectValueViewer } from 'components';
import CompGroupContext from 'context/CompGroupContext';
import { getSelectionCellOptions } from 'pages/ValuationsAllocation/util/getSelectionCellOptions';

const MultipleTypeValueViewer = props => {
  const { cellOptions } = useContext(CompGroupContext);
  const options = getSelectionCellOptions({
    specificApproach: cellOptions,
  });
  return <SelectValueViewer options={options} useLabel {...props} />;
};

export default MultipleTypeValueViewer;
