export const ADD_INVESTMENTS_TO_CONTINUE = 'Please add investments to a company to continue';
export const COMP_GROUPS_SLUG = 'comp-groups';
export const COMPLETE_FUNDS_AND_COMPANY = 'Please complete funds and companies with cap tables';
export const CONTACT_INFORMATION = 'Contact Information';
export const CREATE_COMPANY_FIRST = 'Please create a company first';
export const EMPTY_COMPANIES = 'There are no companies associated with this Firm';
export const EMPTY_FIRM = 'There is no information associated with this Firm';
export const EMPTY_INVESTMENTS = 'There are no investments associated with the funds of this firm';
export const FIRM_REQUESTS_SLUG = 'requests';
export const FIRM_SETTINGS = 'Firm Settings';
export const FIRM_SETTINGS_SLUG = 'settings';
export const FIRMS_FIRM_SUMMARY_PAGE = 'Firm Summary page';
export const FIRMS_SUMMARY_SLUG = 'summary';
export const FORBIDDEN_FIRM_SUMMARY = 'You do not have permissions to view the firm summary';
export const GET_FIRM_SUMMARY_ACCESS = 'Please contact your manager to gain access';
export const REQUESTS = 'Requests';
export const REQUIRES_2FA = 'Requires 2FA?';
export const SHOW_PROCESS_MANAGEMENT_COLUMN = 'Show Process Management Column?';
export const FIRMS_MEASUREMENT_DATE = 'Measurement Date';

export const FIRM_FUND_STEPPER_BASE_STATE = Object.freeze({
  values: {},
  isValid: false,
  dbErrors: {},
  errors: {},
});

export const FIRM_FUND_STEPPER_TITLES = Object.freeze({
  0: 'Create New Firm',
  1: 'Create New Fund',
});

export const FIRM_FIELDS_CONSTRAINTS = Object.freeze({
  name: {
    length: { minimum: 3, maximum: 100, message: 'must be at least 3 and less than 100 characters' },
    presence: { allowEmpty: false, message: 'is required' },
  },
  website: {
    url: true,
  },
  zip_code: {
    length: { minimum: 3, maximum: 12, message: 'must be at least 3 and less than 12 characters' },
    format: {
      message: 'must be a valid zip code, containing only numbers',
      pattern: '^\\d{3,12}$',
    },
  },
  phone_number: {
    format: {
      message: 'must be at least 10 and at most 15 characters, with no spaces, hyphens, or brackets',
      pattern: '^(\\+)?1?\\d{10,15}$',
    },
  },
  country: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  state: {
    length: { minimum: 0, message: 'must be at most 30 characters', maximum: 30 },
  },
  street_address: {
    length: { minimum: 0, message: 'must be at most 100 characters', maximum: 100 },
  },
});

// Requests page

export const REQUEST_TITLE = (name: string) => `Request Defaults for ${name}`;
export const boldTextFooter = 'Ready to onboard the companies?';
export const DOCUMENT_REQUEST_DESCRIPTION
  = 'The recipient will need to upload a document that includes the information for each request.';
export const DOCUMENT_REQUEST_TITLE = 'Document Requests';
export const PROCESS_MANAGEMENT = 'Process Management';
export const QUESTION_REQUEST_DESCRIPTION
  = 'Use the questions to request additional information that will be needed to complete the valuation.';
export const QUESTION_REQUEST_TITLE = 'Questions';
export const REQUEST_DESCRIPTION
  = 'This page specifies the default document requests and default questions that will be sent to companies when the initial request process has been started for each company. Requests can be configured to be assigned only to a specific company, using the edit options.';
export const SEND_REQUESTS = 'Send Requests';
export const TEXT_FOOTER
  = 'Ready to onboard the companies? Confirm or modify the documents and question that need to be requested from each company. When ready, press Send Requests to confirm the companies you would like to receive these current requests.';
// document and question requests modal
export const PICK_ANSWER_TYPE = 'Pick answer type';
export const TEXT_TYPE_LABEL = 'text';
export const DATE_TYPE_LABEL = 'date';
export const DROPDOWN_TYPE_LABEL = 'dropdown';
export const MULTIPLE_TYPE_LABEL = 'multiple';
export const QUESTION_TYPE_CHOICES = [
  { value: 0, label: TEXT_TYPE_LABEL },
  { value: 1, label: DATE_TYPE_LABEL },
  { value: 2, label: DROPDOWN_TYPE_LABEL },
  // { value: 3, label: MULTIPLE_TYPE_LABEL },
];
export const questionModalMetadata = {
  name: 'Question',
  namePlaceholder: 'Question example?',
  description: 'Question description',
  descriptionPlaceholder: 'Add a brief description to the question requirements',
};
export const requestedDocumentModalMetadata = {
  name: 'Document Name',
  namePlaceholder: 'Enter name of requested document',
  description: 'Document description',
  descriptionPlaceholder: 'Add a brief description to the document',
};

// Tasks progress
export const ARE_YOU_SURE = 'Are you sure?';
export const MODAL_ACTION_BUTTON = 'Send Requests';
export const MODAL_TITLE = 'Select companies to send requests';
export const NO_COMPANIES_FOUND = 'No companies found';
export const NO_MEASUREMENT_DATE_SET = 'No measurement date set';
export const NO_COMPANY_USERS = 'No company users';
export const NO_TASKS = 'No tasks required';
export const TASK_COMPLETION = 'Task Completion';
export const TIMEZONE = 'America/New_York';
export const QUESTIONS_TYPES = Object.freeze({
  text: 'text',
  dropdown: 'dropdown',
  date: 'date',
});

// Input Names:
export const PHONE_NUMBER = 'phone_number';
export const WEBSITE = 'website';
