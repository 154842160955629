import React from 'react';
import { Button } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { VALUATIONS_VALUATION } from 'pages/ValuationsAllocation/common/constants/valuations';

const EXTERNAL_PAGES = [VALUATIONS_VALUATION];

const GoToCapTable = ({ capTableURL, page }) => {
  const history = useHistory();

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => {
        history.push(capTableURL);
      }}
      startIcon={EXTERNAL_PAGES.includes(page) ? '' : <ArrowBackIcon />}
      endIcon={EXTERNAL_PAGES.includes(page) ? <ArrowForwardIcon /> : ''}>
      Add Security
    </Button>
  );
};

GoToCapTable.propTypes = {
  capTableURL: PropTypes.string,
  page: PropTypes.string,
};

export default GoToCapTable;
