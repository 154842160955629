export const FUTURE_EXIT_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TITLE = 'Modified Present Equity Value';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_DISCOUNT_RATE_TITLE = 'Discount Rate';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_EXIT_DATE_TITLE = 'Exit Date';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_FUTURE_VALUE_CURRENT_SHAREHOLDERS_TITLE
  = 'Future Value to Current Shareholders';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_DEBT_CASH_TITLE = '(Less Debt)';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_MEASUREMENT_DATE_TITLE = 'Measurement Date';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_PLUS_DEBT_TITLE = 'Plust Debt';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_PRESENT_ENTERPRISE_VALUE_TITLE = 'Present Enterprise Value';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_PRESENT_EQUITY_VALUE_TITLE = 'Present Equity Value';
export const FE_MODIFIED_PRESENT_EQUITY_VALUE_YEARS_UNTIL_EXIT_TITLE = 'Years Until Exit';

export const FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TITLE = 'Future Equity Value';
export const FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE = 'Exit Date';
export const FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE = 'LTM Revenue';
export const FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH = 'Valuation Approach';
export const FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE = 'Multiple Value';
export const FE_FUTURE_EQUITY_SPREADSHEET_ENTERPRISE_VALUE = 'Enterprise Value';
export const FE_FUTURE_EQUITY_SPREADSHEET_CASH = 'Cash as of';
export const FE_FUTURE_EQUITY_SPREADSHEET_DEBT = 'Debt as of';
export const FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_EQUITY_VALUE = 'Future Equity Value';
export const FE_FUTURE_EQUITY_SPREADSHEET_DILUTION_FUTURE_INVESTMENTS = 'Dilution % from Future Investments';
export const FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS = 'Future Value to Current Shareholders';

// OPM
export const FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD_TITLE = 'Allocation Method';
export const FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE_TITLE = 'Cap Table Selection';
// CSE
export const FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS_TITLE = 'OPM Inputs';
export const FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS_TITLE = 'Maturity (Years)';
export const FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_DATE_TITLE = 'Maturity Date';
export const FE_ALLOCATION_METHOD_SPREADSHEET_RISK_FREE_RATE_TITLE = 'Risk Free Rate';
export const FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE_TITLE = 'Volatility Source';
export const FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_TITLE = 'Volatility';

export const MEASUREMENT_DATE_ROW_NUMBER = 2;
export const YEARS_UNTIL_EXIT_ROW_NUMBER = 4;
export const DISCOUNT_RATE_ROW_NUMBER = 5;
export const PRESENT_EQUITY_VALUE_ROW_NUMBER = 7;

export const FE_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET = Object.freeze({
  MODIFIED_PRESENT_EQUITY_VALUE: {
    ROW_NUMBER: 1,
    TITLE: FUTURE_EXIT_MODIFIED_PRESENT_EQUITY_VALUE_SPREADSHEET_TITLE,
  },
  MEASUREMENT_DATE: {
    ROW_NUMBER: 2,
    TITLE: FE_MODIFIED_PRESENT_EQUITY_VALUE_MEASUREMENT_DATE_TITLE,
  },
  EXIT_DATE: {
    ROW_NUMBER: 3,
    TITLE: FE_MODIFIED_PRESENT_EQUITY_VALUE_EXIT_DATE_TITLE,
  },
  YEARS_UNTIL_EXIT: {
    ROW_NUMBER: 4,
    TITLE: FE_MODIFIED_PRESENT_EQUITY_VALUE_YEARS_UNTIL_EXIT_TITLE,
  },
  DISCOUNT_RATE: {
    ROW_NUMBER: 5,
    TITLE: FE_MODIFIED_PRESENT_EQUITY_VALUE_DISCOUNT_RATE_TITLE,
  },
  FUTURE_VALUE_CURRENT_SHAREHOLDERS: {
    ROW_NUMBER: 6,
    TITLE: FE_MODIFIED_PRESENT_EQUITY_VALUE_FUTURE_VALUE_CURRENT_SHAREHOLDERS_TITLE,
  },
  PRESENT_EQUITY_VALUE: {
    ROW_NUMBER: 7,
    TITLE: FE_MODIFIED_PRESENT_EQUITY_VALUE_PRESENT_EQUITY_VALUE_TITLE,
  },
  PLUS_DEBT: {
    ROW_NUMBER: 8,
    TITLE: FE_MODIFIED_PRESENT_EQUITY_VALUE_PLUS_DEBT_TITLE,
  },
  LESS_CASH: {
    ROW_NUMBER: 9,
    TITLE: FE_MODIFIED_PRESENT_EQUITY_VALUE_DEBT_CASH_TITLE,
  },
  PRESENT_ENTERPRISE_VALUE: {
    ROW_NUMBER: 10,
    TITLE: FE_MODIFIED_PRESENT_EQUITY_VALUE_PRESENT_ENTERPRISE_VALUE_TITLE,
  },
});

export const FE_FUTURE_EQUITY_VALUE_SPREADSHEET = Object.freeze({
  FUTURE_EQUITY_VALUE_TITLE: {
    ROW_NUMBER: 1,
    TITLE: FE_FUTURE_EQUITY_VALUE_SPREADSHEET_TITLE,
  },
  EXIT_DATE: {
    ROW_NUMBER: 2,
    TITLE: FE_FUTURE_EQUITY_SPREADSHEET_EXIT_DATE,
  },
  METRIC_VALUE: {
    ROW_NUMBER: 3,
    TITLE: FE_FUTURE_EQUITY_SPREADSHEET_METRIC_VALUE,
  },
  VALUATION_APPROACH: {
    ROW_NUMBER: 4,
    TITLE: FE_FUTURE_EQUITY_SPREADSHEET_VALUATION_APPROACH,
  },
  MULTIPLE_VALUE: {
    ROW_NUMBER: 5,
    TITLE: FE_FUTURE_EQUITY_SPREADSHEET_MULTIPLE_VALUE,
  },
  ENTERPRISE_VALUE: {
    ROW_NUMBER: 6,
    TITLE: FE_FUTURE_EQUITY_SPREADSHEET_ENTERPRISE_VALUE,
  },
  PLUS_CASH: {
    ROW_NUMBER: 7,
    TITLE: FE_FUTURE_EQUITY_SPREADSHEET_CASH,
  },
  LESS_DEBT: {
    ROW_NUMBER: 8,
    TITLE: FE_FUTURE_EQUITY_SPREADSHEET_DEBT,
  },
  FUTURE_EQUITY_VALUE: {
    ROW_NUMBER: 9,
    TITLE: FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_EQUITY_VALUE,
  },
  DILUTION_FUTURE_INVESTMENTS: {
    ROW_NUMBER: 10,
    TITLE: FE_FUTURE_EQUITY_SPREADSHEET_DILUTION_FUTURE_INVESTMENTS,
  },
  FUTURE_VALUE_CURRENT_SHAREHOLDERS: {
    ROW_NUMBER: 10,
    TITLE: FE_FUTURE_EQUITY_SPREADSHEET_FUTURE_VALUE_CURRENT_SHAREHOLDERS,
  },
});

export const FE_ALLOCATION_METHOD_SPREADSHEET = Object.freeze({
  ALLOCATION_METHOD: {
    ROW_NUMBER: 1,
    TITLE: FE_ALLOCATION_METHOD_SPREADSHEET_ALLOCATION_METHOD_TITLE,
  },
  CAP_TABLE: {
    ROW_NUMBER: 2,
    TITLE: FE_ALLOCATION_METHOD_SPREADSHEET_CAP_TABLE_TITLE,
  },
  OPM_INPUTS: {
    ROW_NUMBER: 3,
    TITLE: FE_ALLOCATION_METHOD_SPREADSHEET_OPM_INPUTS_TITLE,
  },
  MATURITY_YEARS: {
    ROW_NUMBER: 4,
    TITLE: FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_YEARS_TITLE,
  },
  MATURITY_DATE: {
    ROW_NUMBER: 5,
    TITLE: FE_ALLOCATION_METHOD_SPREADSHEET_MATURITY_DATE_TITLE,
  },
  RISK_FREE_RATE: {
    ROW_NUMBER: 6,
    TITLE: FE_ALLOCATION_METHOD_SPREADSHEET_RISK_FREE_RATE_TITLE,
  },
  VOLATILITY_SOURCE: {
    ROW_NUMBER: 7,
    TITLE: FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_SOURCE_TITLE,
  },
  VOLATILITY: {
    ROW_NUMBER: 8,
    TITLE: FE_ALLOCATION_METHOD_SPREADSHEET_VOLATILITY_TITLE,
  },
});
