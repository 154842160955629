import { range } from 'mathjs';
import {
  MEAN_LABEL,
  MEDIAN_LABEL,
  PERCENTILE_25,
  PERCENTILE_75,
  VOLATILITY_YEARS_VALUES,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics';
import { SPECIFIED_LABEL } from 'pages/Valuations/util/constants';
import { generateAllRowsExpression } from 'utillities';
import type { TCells, TChanges, TrowConfig } from './types';
import {
  get25thPercentileExpression,
  get75thPercentileExpression,
  getMeanExpression,
  getMedianExpression,
  getPreviousCell,
} from './utils';

const afterCellChanged = (changes: TChanges[], cells: TCells, rowConfig: TrowConfig[]) => {
  const allComparisonsExpression = generateAllRowsExpression({
    rowConfig,
    excludedRows: 2,
    excludeLastRow: true,
  });

  changes.forEach((change: TChanges) => {
    const { cell, value } = change;
    const { key, columnLegend } = cell;
    const previousCell = getPreviousCell(key);
    const pfmRange = range(1, allComparisonsExpression.length)
      .map((rowENumber: number) => `${columnLegend}${rowENumber + 2}`)
      .toString()
      .replace(/"/g, '');
    if (VOLATILITY_YEARS_VALUES.includes(cell.alias)) {
      switch (value) {
        case SPECIFIED_LABEL:
          changes.push({
            cell: cells[previousCell],
            value: '',
          });
          break;
        case MEAN_LABEL:
          changes.push({
            cell: cells[previousCell],
            value: getMeanExpression(pfmRange),
          });
          break;
        case MEDIAN_LABEL:
          changes.push({
            cell: cells[previousCell],
            value: getMedianExpression(pfmRange),
          });
          break;
        case PERCENTILE_25:
          changes.push({
            cell: cells[previousCell],
            value: get25thPercentileExpression(pfmRange),
          });
          break;
        case PERCENTILE_75:
          changes.push({
            cell: cells[previousCell],
            value: get75thPercentileExpression(pfmRange),
          });
          break;
        default:
          break;
      }
    }
  });
  // Return the updated changes
  return changes;
};

export default afterCellChanged;
