import React, { FC, useContext, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { ExtraActionsMenu } from 'components';
import { ConfirmationDialog } from 'components/Dialogs';
import { InformationDashboard409AContext } from 'context/InformationDashboard409AContext';
import {
  ERROR_DISMISSING_REQUESTED_DOCUMENT,
  SUCCESS_DISMISSING_REQUESTED_DOCUMENT,
  SUCCESS_MODIFYING_INFORMATION_REQUEST,
} from 'dashboard409a/common/constants';
import { modifyExistingRequest } from 'dashboard409a/services/dashboard';
import theme from 'theme';
import { gridShortDate } from 'utillities';
import { CurrentRequestedDocumentTableProps, DocumentProgress } from './types';

const CurrentRequestedDocumentTable: FC<CurrentRequestedDocumentTableProps> = ({ documents }) => {
  const responsibleUsers = documents?.responsible_users || [];
  const lastTaskId = documents?.id || null;
  const [isOpenDismissDocumentRequestModal, setIsOpenDismissDocumentRequestModal] = useState(false);
  const [documentToRemove, setDocumentToRemove] = useState<DocumentProgress | null>(null);
  const { handleGetCurrentInformationRequests } = useContext(InformationDashboard409AContext);
  const { enqueueSnackbar } = useSnackbar();

  const tableHeadRows = [
    { id: 'format', label: 'Format' },
    { id: 'name', label: 'Name' },
    { id: 'source', label: 'Source' },
    { id: 'sentUploadDate', label: 'Sent Date/Upload Date' },
    { id: 'actions', label: 'Actions' },
  ];

  const openDismissDocumentRequestModal = (document: DocumentProgress) => {
    setDocumentToRemove(document);
    setIsOpenDismissDocumentRequestModal(true);
  };

  const removeDocument = async (document: DocumentProgress) => {
    try {
      if (!lastTaskId || !document.id) {
        enqueueSnackbar(ERROR_DISMISSING_REQUESTED_DOCUMENT, { variant: 'error' });
        return false;
      }
      const data = {
        task: lastTaskId,
        requested_documents_to_delete: [document.id],
        requested_documents: [],
      };
      const response = await modifyExistingRequest(data);

      if (response?.message === SUCCESS_MODIFYING_INFORMATION_REQUEST) {
        enqueueSnackbar(SUCCESS_DISMISSING_REQUESTED_DOCUMENT, { variant: 'success' });
        return true;
      }
      enqueueSnackbar(ERROR_DISMISSING_REQUESTED_DOCUMENT, { variant: 'error' });
      return false;
    } catch (error) {
      enqueueSnackbar(ERROR_DISMISSING_REQUESTED_DOCUMENT, { variant: 'error' });
      return false;
    } finally {
      setDocumentToRemove(null);
      setIsOpenDismissDocumentRequestModal(false);
      handleGetCurrentInformationRequests();
    }
  };

  const optionsForExtraActionsMenu = (documentToRemoveParam: DocumentProgress | null) => [
    {
      label: 'UPLOAD THIS DOCUMENTS',
      callback: () => '',
      isActive: false,
    },
    {
      label: 'DISMISS DOCUMENT REQUEST',
      callback: () => {
        if (documentToRemoveParam !== null) {
          openDismissDocumentRequestModal(documentToRemoveParam);
        }
      },
      isActive: true,
    },
  ];

  const actions = [
    {
      label: 'CANCEL',
      variant: 'outlined',
      disabled: false,
      callback: () => setIsOpenDismissDocumentRequestModal(false),
    },
    {
      label: 'YES, CONFIRM',
      variant: 'contained',
      disabled: false,
      callback: () => {
        if (documentToRemove !== null) {
          removeDocument(documentToRemove);
        }
      },
    },
  ];

  const getFileFormat = (document: DocumentProgress) => {
    const { name } = document;
    if (name.includes('.')) {
      const fileExtension = name.split('.').pop();
      return fileExtension ? fileExtension.toUpperCase() : '-';
    }
    return '-';
  };

  const getFormattedDate = (date: string | null) => (date ? gridShortDate(date) : '');

  const getSentAndUploadDate = (document: DocumentProgress) => {
    const { created_at: sentDate, updated_at: uploadedDate } = document || {};
    const formattedCreatedDate = getFormattedDate(sentDate);
    const formattedUploadedDate = getFormattedDate(uploadedDate);
    if (formattedCreatedDate && formattedUploadedDate) {
      return formattedCreatedDate === formattedUploadedDate
        ? `${formattedCreatedDate}`
        : `${formattedCreatedDate} - ${formattedUploadedDate}`;
    }
    return formattedCreatedDate || formattedUploadedDate;
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table style={{ minWidth: 650 }} aria-label="documents table">
          <TableHead>
            <TableRow>
              {tableHeadRows.map(row => (
                <TableCell key={row.id}>{row.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.progress.map(document => {
              // check if document is a DocumentProgress type
              if ('files' in document) {
                return (
                  <TableRow key={document.id}>
                    <TableCell>{getFileFormat(document)}</TableCell>
                    <TableCell>{document.name}</TableCell>
                    <TableCell>{responsibleUsers.map(user => user.full_name).join(', ')}</TableCell>
                    <TableCell>{getSentAndUploadDate(document)}</TableCell>
                    <TableCell>
                      <ExtraActionsMenu
                        options={optionsForExtraActionsMenu(document)}
                        dotsColor={theme.palette.gray[500]}
                      />
                    </TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={isOpenDismissDocumentRequestModal}
        title="Do you confirm that you want to dismiss this document request?"
        onClose={() => setIsOpenDismissDocumentRequestModal(false)}
        actions={actions}>
        <span>
          This modification will affect the original request to the company user, eliminating it from its portal.
        </span>
      </ConfirmationDialog>
    </>
  );
};

export default CurrentRequestedDocumentTable;
