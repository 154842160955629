import React, { useContext, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { captableAction } from 'common/actions';
import { companySummaryUrl } from 'common/config/urls';
import {
  BREAKPOINT_ANALYSIS_SLUG,
  BREAKPOINT_ANALYSIS_TITLE,
  CAP_TABLE_SLUG,
  CAP_TABLE_TITLE,
  FUND_OWERNERSHIP_TITLE,
  FUND_OWNERSHIP_SLUG,
} from 'common/constants/cap-table';
import { useStore } from 'common/store';
import LayoutContext from 'context/LayoutContext';
import { CapTableDialog } from 'layouts/Main/components';
import useCapTableVersionFilter from 'pages/CapTable/cap-table/utilities/useCapTableVersionFilter';
import { useCompanyMeasurementDateFilter } from 'services/hooks';
import { useGetAllocationInfo, useGetAllocationStatusBreadcrumb } from 'services/hooks/allocation';
import { getObjectValue } from 'utillities';
import BreakpointAnalysis from './breakpoint-analysis';
import CapTable from './cap-table';
import { CaptableVersionEditButton as EditButton } from './cap-table/components';
import FundOwnership from './fund-ownership';

const CapTableView = () => {
  const { tableSlugParam } = useParams();
  const [{ firmInfo, companyInfo, captableInfo }, dispatch] = useStore();
  const { id: currentCapTableId } = getObjectValue(captableInfo);

  const { setPageBreadcrumbs, showPageForm, togglePageForm, companyExchangeRate } = useContext(LayoutContext);

  // Filters options
  const { MDDialogue, selectedMeasurementDate, measurementDates } = useCompanyMeasurementDateFilter();
  const { selectedVersion, onEditCaptable, updateCapTableVersions, saveAsNewProforma, versionName }
    = useCapTableVersionFilter({ selectedMeasurementDate });

  const [, getAllocationInfo] = useGetAllocationInfo();

  const [allocationStatusBreadcrumb] = useGetAllocationStatusBreadcrumb();

  const activeTable = useMemo(() => {
    switch (tableSlugParam) {
      case CAP_TABLE_SLUG:
        return CAP_TABLE_TITLE;
      case FUND_OWNERSHIP_SLUG:
        return FUND_OWERNERSHIP_TITLE;
      case BREAKPOINT_ANALYSIS_SLUG:
        return BREAKPOINT_ANALYSIS_TITLE;
      default:
        return '';
    }
  }, [tableSlugParam]);

  const companyURL = useMemo(
    () => (isEmpty(firmInfo) || isEmpty(companyInfo) ? '' : companySummaryUrl(firmInfo.slug, companyInfo.slug)),
    [firmInfo, companyInfo]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        title: companyInfo.name,
        url: companyURL,
      },
      {
        title: activeTable,
      },
      ...(versionName
        ? []
        : [
          {
            title: versionName,
            icon: <EditButton onClickHandler={togglePageForm} />,
          },
        ]),
      allocationStatusBreadcrumb,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companyInfo, activeTable, versionName, companyURL, measurementDates, allocationStatusBreadcrumb]
  );

  // Set breadcrumbs in header
  useEffect(() => {
    if (!isEmpty(companyInfo) && !isEmpty(firmInfo)) {
      setPageBreadcrumbs(breadcrumbs);
    }
    return () => setPageBreadcrumbs(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs]);

  useEffect(() => {
    if (companyInfo?.allocation_id && companyExchangeRate) {
      getAllocationInfo(companyInfo.allocation_id, companyExchangeRate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo, companyExchangeRate]);

  // Clean cap table info in the global store when leaving the page
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(captableAction.setCaptableInfo({})), []);

  return (
    <>
      {(selectedVersion || !selectedMeasurementDate) && tableSlugParam === CAP_TABLE_SLUG && (
        <CapTable
          updateCapTableVersions={updateCapTableVersions}
          saveAsNewProforma={saveAsNewProforma}
          selectedMeasurementDate={selectedMeasurementDate}
          capTableId={currentCapTableId}
        />
      )}
      {(selectedVersion || !selectedMeasurementDate) && tableSlugParam === FUND_OWNERSHIP_SLUG && (
        <FundOwnership
          updateCapTableVersions={updateCapTableVersions}
          saveAsNewProforma={saveAsNewProforma}
          selectedMeasurementDate={selectedMeasurementDate}
        />
      )}
      {tableSlugParam === BREAKPOINT_ANALYSIS_SLUG && (
        <BreakpointAnalysis
          updateCapTableVersions={updateCapTableVersions}
          saveAsNewProforma={saveAsNewProforma}
          selectedMeasurementDate={selectedMeasurementDate}
        />
      )}

      {captableInfo && <CapTableDialog open={showPageForm} onClose={togglePageForm} onSave={onEditCaptable} />}
      <MDDialogue />
    </>
  );
};

export default CapTableView;

export { CapTableView, CapTable, FundOwnership, BreakpointAnalysis };
