import React, { useContext } from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import PropTypes from 'prop-types';
import { ConfirmationDelete, ConfirmationDialog } from 'components/Dialogs';
import { DialogContext } from 'context';
import useDocumentsContext from 'context/DocumentsContext';
import { useDocuments } from 'services/hooks';
import theme from 'theme';
import VerticalMenu from './VerticalMenu';

const useStyles = makeStyles({
  actionButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
  },
  actionButton: {
    margin: `-${theme.spacing(2)}px 0`,
    color: theme.palette.black[300],
  },
  dragIcon: {
    color: `${theme.palette.grey[300]} !important`,
    cursor: 'grab',
    transition: 'ease opacity 1s',
    '&:hover': {
      opacity: 1,
    },
  },
});

const ContentComponent = () => <ConfirmationDelete itemName="document" />;

const DocumentsTableActionButtons = ({
  fileData,
  isCurrentData,
  measurementDate,
  setFormState,
  setOpenEditFileDialog,
}) => {
  const classes = useStyles();
  const { setIsLoading, setCompanyDocuments } = useDocumentsContext();
  const { showDialog } = useContext(DialogContext);
  const { downloadDocument, deleteFile, getDocuments } = useDocuments();

  const handleDownload = () => {
    setIsLoading(true);
    downloadDocument(fileData);
    setIsLoading(false);
  };

  const handleEditDocument = fileDataParam => {
    const {
      file,
      referenced_in: references,
      md_documents: documentId,
      id,
      document_reference_id: documentReferenceId,
    } = fileDataParam;
    const { id: fileId, filename, folder: folderId } = file;
    const { id: mdId } = measurementDate || {};
    setFormState(currentState => ({
      ...currentState,
      values: {
        documentId,
        id,
        mdId,
        fileId,
        folderId,
        filename,
        references,
        document_reference_id: documentReferenceId,
      },
    }));
    setOpenEditFileDialog(true);
  };

  const handleDelete = async (documentsId, file) => {
    setIsLoading(true);
    const response = await deleteFile(documentsId, file);
    if (response) {
      setCompanyDocuments(await getDocuments());
    }
    setIsLoading(false);
  };

  const deleteItem = async fileDataParam => {
    const { file, md_documents: documentsId } = fileDataParam;

    showDialog({
      wrapper: ConfirmationDialog,
      content: ContentComponent,
      actions: [
        {
          label: 'Cancel',
          type: 'cancel',
        },
        {
          label: 'Delete',
          variant: 'contained',
          type: 'danger',
          callback: () => handleDelete(documentsId, file),
        },
      ],
    });
  };

  return (
    <div className={classes.actionButtonContainer}>
      <Tooltip title="Download Document" placement="top" arrow>
        <IconButton className={classes.actionButton} onClick={handleDownload}>
          <CloudDownloadOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Move Document" placement="top" arrow>
        <IconButton className={classes.dragIcon}>
          <MenuOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      {isCurrentData && (
        <VerticalMenu
          itemData={fileData}
          editItem={() => handleEditDocument(fileData)}
          deleteItem={() => deleteItem(fileData)}
        />
      )}
    </div>
  );
};

DocumentsTableActionButtons.propTypes = {
  fileData: PropTypes.shape({
    file: PropTypes.shape({
      id: PropTypes.number,
      filename: PropTypes.string,
      folder: PropTypes.number,
    }),
    md_documents: PropTypes.number,
    referenced_in: PropTypes.arrayOf(PropTypes.shape({})),
    document_reference_id: PropTypes.number,
  }).isRequired,
  isCurrentData: PropTypes.bool.isRequired,
  measurementDate: PropTypes.shape({
    id: PropTypes.number,
  }),
  setFormState: PropTypes.func.isRequired,
  setOpenEditFileDialog: PropTypes.func.isRequired,
};

export default DocumentsTableActionButtons;
