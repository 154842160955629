import { twoDecimalPercentFormat } from 'common/formats/formats';
import { ValuationSummaryCellCustomData } from 'pages/ValuationsAllocation/approaches/ValuationSummary/config';
import { ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE } from 'pages/ValuationsAllocation/common/constants/allocation';
import { SHEET_TITLES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/valuationSummary';
import { getNumberValue, getObjectValue } from 'utillities';
import { HandleAllocationScenariosWeightsParams } from './types';

const { FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER, getAdjustedColumnNumber } = SHEET_TITLES_CONSTANTS;

const handleAllocationScenariosWeights = (params: HandleAllocationScenariosWeightsParams) => {
  const { cell, colNumber, isDisabled, isUniformCurrency, panelId, scenarioId, scenarioRef, scenarioType } = params;

  if (FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER <= getAdjustedColumnNumber({ colNumber, isUniformCurrency })) {
    cell.allowNegativeValue = false;
    cell.dbDecimalPlaces = 1;
    cell.defaultZero = true;
    cell.format = twoDecimalPercentFormat({ minimumFractionDigits: 0, validateFloat: true });
    cell.gridType = 'percentage';
    cell.hidden = true;
    cell.readOnly = true;
    cell.value = getNumberValue(cell.value as number);

    // Custom Data for Approaches Allocation Scenarios Weights
    cell.data = {
      ...getObjectValue(cell.data),
      panelId,
      scenarioId,
      scenarioRef,
    } as ValuationSummaryCellCustomData;

    if (scenarioType === ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE) {
      cell.hidden = false;
      cell.readOnly = isDisabled;
    }
    // Backsolve, Future Exit, Specified Share Values
    else if (cell.value > 0) {
      cell.hidden = false;
    }
  }
};

export default handleAllocationScenariosWeights;
