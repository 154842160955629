import React from 'react';
import { CREATE_COMPANY_FIRST, EMPTY_COMPANIES } from 'common/constants/firms';
import { MessageBox } from 'components';
import AddCompanyButton from './AddCompanyButton';

const NoCompanyMessage = () => (
  <MessageBox title={EMPTY_COMPANIES} tagline={CREATE_COMPANY_FIRST} fullWidth={false} action={<AddCompanyButton />} />
);

export default NoCompanyMessage;
