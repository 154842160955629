/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, FormLabel, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { firmsAction, globalAction } from 'common/actions';
import {
  ADMIN,
  COMPANY,
  COMPANY_USER_VALUE,
  EDIT,
  EXISTING_VALID_ROLES_VALUES,
  FIRM,
  FUND,
  FUND_ADMIN_VALUE,
  PERMISSIONS_NOT_SAVED_ERROR,
  PERMISSIONS_SAVED,
  USE_PORTAL,
} from 'common/constants/user';
import { useStore } from 'common/store';
import { MessageBox } from 'components';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { LayoutContext } from 'context';
import { AuthService, UsersService } from 'services';
import { useResponse } from 'services/hooks';
import { useAddUserToFirm, useSaveUserPermissions } from 'services/hooks/useManageUser';
import { useGetUserDetails, useGetUserPermissions } from 'services/hooks/useUserSelected';
import CompanyFundSelect from './components/CompanyFundSelect';
import FirmItems from './components/FirmItems';
import UserCard from './components/UserCard';
import UserDirectory from './components/UserDirectory';
import { userCanEditAllFundsAndComps, userHasAccessType } from './utils';
import { getAllPotentialPermissions } from './utils/handlePermissions';
import { prettyLastLogin, prettyRoleType, prettyUsername } from './utils/prettyUserData';
import translateRoleIntoPermissions from './utils/translateRoleIntoPermissions';
import usePermissionStyles from './utils/usePermissionStyles';

const UserPermissions = () => {
  const classes = usePermissionStyles();
  const { setPageActions } = useContext(LayoutContext);
  const [
    {
      userManagementFirmList,
      companyList,
      firmList,
      fundList,
      firmInfo,
      user, // Logged in user
      selectedUserPermissions,
    },
    dispatch,
  ] = useStore();

  const { successNotification, errorNotification } = useResponse();
  const [currentUser, setCurrentUser] = useState();
  const [selectedFirm, setSelectedFirm] = useState();
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [originalUserPermissions, setOriginalUserPermissions] = useState();
  const [userPermissionsToSave, setUserPermissionsToSave] = useState([]);
  const [allPotentialPermissions, setAllPotentialPermissions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [firmItem, setFirmItem] = useState({
    itemType: null,
    itemId: null,
  });

  // Custom hooks
  const { data: userSelected } = useGetUserDetails(currentUser?.id || user?.id);
  const { data: userPermissions } = useGetUserPermissions(currentUser?.id || user?.id, selectedFirm?.id);
  const { mutate: addUserToFirm } = useAddUserToFirm();
  const { mutate: savePermissions } = useSaveUserPermissions();

  useEffect(() => {
    if (userPermissions && !selectedRole) {
      setUserPermissionsToSave({
        user_id: userSelected?.id,
        permissions: userPermissions,
      });
    }

    return () => {
      setUserPermissionsToSave([]);
    };
  }, [userPermissions, userSelected, selectedRole]);

  useEffect(() => {
    if (userPermissions) {
      setOriginalUserPermissions(userPermissions);
    }
  }, [userPermissions]);

  const handleAddUserToFirm = useCallback(
    async (userData, role, handleDialogClose) => {
      const roleToPermissions = translateRoleIntoPermissions({
        role,
        firmId: selectedFirm?.id,
        companyList,
        fundList,
      });
      addUserToFirm(
        {
          firmId: selectedFirm?.id,
          userData,
          permissions: roleToPermissions,
        },
        {
          onSuccess: response => {
            const { is_new: isNew } = response;
            if (isNew) {
              const authService = AuthService.getInstance();
              authService
                .resendActivationEmail(userData.email)
                .then(() => {
                  successNotification('User created and added to firm successfully');
                  handleDialogClose();
                })
                .catch(() => {
                  errorNotification('Error sending the activation email');
                });
            } else {
              successNotification('User added to firm successfully');
              handleDialogClose();
            }
          },
          onError: response => {
            errorNotification(JSON.parse(response.response.text).User[0]);
          },
        }
      );
    },
    [selectedFirm, companyList, fundList, addUserToFirm]
  );

  const userFullNameOrEmail = useMemo(() => prettyUsername(userSelected), [userSelected]);

  const isFirmAdmin = useMemo(() => {
    if (firmList && userManagementFirmList && selectedFirm) {
      const currentFirmPermissions = firmList
        .find(firm => firm.id === selectedFirm?.id)
        ?.user_permissions.map(permission => ({
          [permission.user_id]: permission.access_type,
        }));

      return isEqual(currentFirmPermissions, [{ [user.id]: ADMIN }]);
    }

    return false;
  }, [selectedFirm, firmList, userManagementFirmList]);

  // Currently logged-in user is (1) superuser or (2) a firm admin
  const isSuperUserOrFirmAdmin = useMemo(() => (isSuperUser || isFirmAdmin) ?? false, [isFirmAdmin, isSuperUser]);

  const setSelectedFirmAndFetchUserPermissions = value => {
    if (!value) {
      setSelectedFirm(null);
    } else {
      setSelectedFirm({
        ...value,
        user_permissions: selectedUserPermissions?.find(firm => firm.id === value?.id)?.user_permissions || [],
      });
    }
  };

  useEffect(() => {
    if (firmInfo) {
      setSelectedFirmAndFetchUserPermissions(firmInfo);
    }
  }, [firmInfo]);

  useEffect(() => {
    const getUserInfo = async () => {
      const usersService = new UsersService();
      const userInfo = await usersService.getUserProfileById(user.id);
      if (userInfo) {
        const { is_superuser: userIsSuper, firms_permissions: firmPermissions } = userInfo;
        setIsSuperUser(userIsSuper);
        // Populate the global store with the firms available to the selected user
        dispatch(firmsAction.setUserManagementFirmList(firmPermissions));
      }
    };
    if (user?.id) {
      // Permissions for the logged in user
      getUserInfo();
    }
  }, [user]);

  useEffect(() => {
    if (userManagementFirmList?.length === 1) {
      setSelectedFirm(userManagementFirmList[0]);
    }
  }, [userManagementFirmList]);

  const lastLogin = useMemo(() => prettyLastLogin(userSelected), [userSelected]);

  // Implicitly check all permissions if the user is a superuser or a firm admin.
  // These are the only types of accounts that can grant permissions to a user.
  const shouldCheckAll = useMemo(
    () =>
      userHasAccessType({
        permissions: userPermissions,
        accessType: ADMIN,
        userId: userSelected?.id,
        objectId: selectedFirm?.id,
        objectType: FIRM,
      }),
    [userPermissions, userSelected, selectedFirm]
  );

  const hasEditPermissionsOnAllFundsAndComps = useMemo(() => {
    if (companyList && fundList && userPermissionsToSave) {
      return userCanEditAllFundsAndComps({
        companyList,
        fundList,
        userPermissionsToSave,
      });
    }

    return false;
  }, [companyList, fundList, userPermissionsToSave]);

  const roleType = useMemo(
    () => prettyRoleType(selectedRole?.roleType, userSelected, selectedFirm?.id),
    [selectedRole, userSelected, selectedFirm]
  );

  const handleSave = useCallback(async () => {
    dispatch(globalAction.showLoadingProgress(true));
    savePermissions(
      {
        userId: userSelected?.id,
        roleType,
        firmId: selectedFirm?.id,
        userPermissionsToSave,
      },
      {
        onSuccess: data => {
          setOriginalUserPermissions(data);
          successNotification(PERMISSIONS_SAVED);
          setSelectedFirmAndFetchUserPermissions(firmInfo);
        },
        onError: () => {
          errorNotification(PERMISSIONS_NOT_SAVED_ERROR);
        },
      }
    );
    dispatch(globalAction.showLoadingProgress(false));
  }, [userSelected, userPermissionsToSave, roleType]);

  useEffect(() => {
    setPageActions({
      mainAction: {
        id: 'user-permissions',
        label: 'Save',
        // Disable the Save button if the user has not changed any permissions.
        // Also, all permissions must have a valid object_id. This is important
        // specially for company users and fund admins that must be associated
        // with a given company or fund.
        isActive:
          !isEqual(userPermissionsToSave?.permissions, originalUserPermissions)
          && !userPermissionsToSave?.permissions?.every(perm => perm.feature_object.object_id),
        callback: handleSave,
      },
    });

    return () => setPageActions(null);
  }, [userPermissionsToSave, userSelected]);

  useEffect(() => {
    if (selectedFirm && selectedUserPermissions) {
      dispatch(
        firmsAction.setSelectedUserPermissions([
          ...selectedUserPermissions.filter(firm => firm.id !== selectedFirm.id),
          selectedFirm,
        ])
      );
    }
  }, [selectedFirm]);

  // Combine all funds and companies as potential permissions on the selected firm
  useEffect(() => {
    if (selectedFirm && fundList && companyList && userPermissionsToSave) {
      const allMissingFundsAndCompsPermissions = getAllPotentialPermissions(
        userPermissionsToSave,
        fundList,
        companyList
      );
      setSelectAll(allMissingFundsAndCompsPermissions.length === 0);
      setAllPotentialPermissions(allMissingFundsAndCompsPermissions);
    }
  }, [selectedFirm, fundList, companyList, userPermissionsToSave]);

  const updatePermissionsForCompOrFundRole = (itemType, itemId, prevPermissions) => {
    const updatedPermissions = prevPermissions.permissions
      .filter(perm => perm.id !== 0)
      .map(perm => ({
        ...perm,
        remove_permission: true,
      }));

    const newPermission = {
      id: 0,
      feature_object: {
        feature: null,
        object_type: itemType,
        object_id: itemId,
      },
      access_type: itemType === COMPANY ? USE_PORTAL : EDIT,
      remove_permission: false,
    };

    updatedPermissions.push(newPermission);

    if (itemType === FUND) {
      const firmPermission = {
        id: 0,
        feature_object: {
          feature: null,
          object_type: FIRM,
          object_id: selectedFirm?.id,
        },
        access_type: EDIT,
        remove_permission: false,
      };
      updatedPermissions.push(firmPermission);
    }

    return {
      user_id: userSelected?.id,
      role_type: roleType,
      permissions: updatedPermissions,
    };
  };

  const handleSelect = ({ itemType, itemId }) => {
    if (![COMPANY, FUND].includes(itemType)) return;

    setFirmItem({ itemType, itemId });

    setUserPermissionsToSave(prevPermissionsToSave =>
      updatePermissionsForCompOrFundRole(itemType, itemId, prevPermissionsToSave)
    );
  };

  /** This function is to obtain the value as it is in the 'value' prop of the select this is necessary because until you change for the first time the selected company
   * or fund the permissions are not updated.
   * Therefore, we need to replicate the process used in the 'handleSelect' function to update the permissions.
   */
  const getSelectInitialValue = itemType => {
    const itemId = userPermissions?.find(perm => perm?.feature_object.object_type === itemType)?.feature_object
      .object_id;
    return itemId ? { initialItemType: itemType, initialItemId: itemId } : null;
  };

  const getCompanyOrFundSelectInitialValue = () => {
    if (roleType === COMPANY_USER_VALUE) {
      return getSelectInitialValue(COMPANY);
    }
    if (roleType === FUND_ADMIN_VALUE) {
      return getSelectInitialValue(FUND);
    }
    return null;
  };

  useEffect(() => {
    const selectedRoleType = selectedRole?.roleType;
    if (companyList && fundList && EXISTING_VALID_ROLES_VALUES.includes(selectedRoleType)) {
      const roleToPermissions = translateRoleIntoPermissions({
        role: { id: selectedRoleType, firmItem },
        firmId: selectedFirm?.id,
        companyList,
        fundList,
      });
      setUserPermissionsToSave(prevPermissionsToSave => ({
        ...prevPermissionsToSave,
        permissions: [
          ...(userPermissions || []).filter(perm => perm.id !== 0).map(perm => ({ ...perm, remove_permission: true })),
          ...roleToPermissions,
        ],
        user_id: userSelected?.id,
        role_type: selectedRoleType,
      }));
    }
    // These conditions avoid cases where the firmItem already was set and the roletype is not FUND_ADMIN_VALUE or COMPANY_USER_VALUE
    if (!firmItem.itemType && !firmItem.itemId && (roleType === FUND_ADMIN_VALUE || roleType === COMPANY_USER_VALUE)) {
      const initialFirmItem = getCompanyOrFundSelectInitialValue();
      if (initialFirmItem) {
        const { initialItemType, initialItemId } = initialFirmItem;
        if (initialItemType && initialItemId) {
          setUserPermissionsToSave(prevPermissionsToSave =>
            updatePermissionsForCompOrFundRole(initialItemType, initialItemId, prevPermissionsToSave)
          );
        }
      }
    }
  }, [selectedRole, selectedFirm, companyList, fundList, firmItem, userPermissions, userSelected]);

  useEffect(() => setSelectedRole(), [currentUser]);

  const handleCheckAll = useCallback(
    event => {
      const { checked } = event.target;
      setSelectAll(checked);
      if (checked) {
        const permissionsToAdd = allPotentialPermissions.filter(
          item =>
            !userPermissionsToSave?.permissions?.find(
              perm =>
                perm.feature_object.object_id === item.feature_object.object_id
                && perm.feature_object.object_type === item.feature_object.object_type
            )
        );
        setUserPermissionsToSave(prevPermissionsToSave => ({
          ...prevPermissionsToSave,
          permissions: [
            ...(prevPermissionsToSave?.permissions || []).map(perm => ({ ...perm, remove_permission: false })),
            ...permissionsToAdd,
          ],
        }));
      } else {
        setUserPermissionsToSave({
          ...userPermissionsToSave,
          permissions: (userPermissionsToSave?.permissions || [])
            .filter(perm => perm.id !== 0)
            .map(perm => ({ ...perm, remove_permission: true })),
        });
      }
    },
    [allPotentialPermissions]
  );

  if (companyList && fundList && !isSuperUserOrFirmAdmin) {
    return (
      <MessageBox
        title="You do not have permissions to view this page"
        tagline="Please contact your supervisor if you believe this is an error"
        fullWidth={false}
      />
    );
  }

  if (companyList && fundList && isSuperUserOrFirmAdmin) {
    return (
      <Grid container style={{ margin: '-1rem' }}>
        <Grid item sm={6} className={classes.section}>
          {selectedFirm && (
            <>
              <Typography variant="h6">User Management Detail View</Typography>
              <br />
              <UserCard
                name={userFullNameOrEmail}
                lastLogin={lastLogin}
                email={userSelected?.email}
                pictureUrl={userSelected?.profile?.picture_url}
                roleType={roleType}
                setSelectedRole={setSelectedRole}
                disabled={userSelected?.id === user?.id}
              />
              <div className={classes.container}>
                {[COMPANY_USER_VALUE, FUND_ADMIN_VALUE].includes(roleType) && (
                  <FormLabel component="legend" classes={{ root: classes.boldLabel }}>
                    {`Select ${roleType === COMPANY_USER_VALUE ? COMPANY : FUND}`}
                  </FormLabel>
                )}
                {roleType === FUND_ADMIN_VALUE && (
                  <CompanyFundSelect
                    value={
                      firmItem.itemId
                      ?? userPermissions?.find(perm => perm?.feature_object.object_type === FUND)?.feature_object.object_id
                    }
                    handleSelect={handleSelect}
                    itemType={FUND}
                    itemList={
                      fundList?.map(fund => ({
                        id: fund.id,
                        name: fund.name,
                      })) || []
                    }
                  />
                )}
                {roleType === COMPANY_USER_VALUE && (
                  <CompanyFundSelect
                    value={
                      firmItem.itemId
                      ?? userPermissions?.find(perm => perm?.feature_object.object_type === COMPANY)?.feature_object
                        .object_id
                    }
                    handleSelect={handleSelect}
                    itemType={COMPANY}
                    itemList={
                      companyList?.map(company => ({
                        id: company.company_id,
                        name: company.company_name,
                      })) || []
                    }
                  />
                )}
              </div>
              <Grid container spacing={1} className={classes.subtitle}>
                <FormControlLabel
                  classes={{
                    label: classes.compsAndFunds,
                  }}
                  control={
                    <Checkbox
                      onChange={handleCheckAll}
                      color="primary"
                      checked={shouldCheckAll || selectAll || hasEditPermissionsOnAllFundsAndComps}
                      indeterminate={
                        !selectAll
                        && !hasEditPermissionsOnAllFundsAndComps
                        && !shouldCheckAll
                        && userPermissionsToSave?.permissions?.some(perm => !perm.remove_permission)
                      }
                      disabled
                    />
                  }
                  label="Select all funds and companies"
                  labelPlacement="end"
                />
                <Grid item sm={12}>
                  <PerfectScrollbar
                    className={clsx('always-visible', classes.scrollbarStyle)}
                    options={{ suppressScrollX: true }}
                    id="scrollbar-firm-items">
                    <div className={classes.scrollbarContainer}>
                      <Grid container spacing={1}>
                        <Grid item sm={8}>
                          <Typography
                            className={classes.compsAndFunds}
                            style={{ fontSize: '1.2rem' }}
                            id="fund-subtitle">
                            Funds
                          </Typography>
                        </Grid>
                        <Grid item sm={2}>
                          <Typography variant="inherit">Edit</Typography>
                        </Grid>
                        <Grid item sm={2}>
                          <Typography variant="inherit">View</Typography>
                        </Grid>
                        <FirmItems
                          itemType="Fund"
                          firmItems={fundList.map(({ id, name }) => ({ id, name }))}
                          userPermissions={userPermissionsToSave?.permissions}
                          // If the currently selected account is the logged-in user and it has edit access or higher,
                          // then he/she has implicit permissions over all the companies and funds in the firm.
                          shouldCheckAll={shouldCheckAll || selectAll}
                          setUserPermissionsToSave={setUserPermissionsToSave}
                          userId={userSelected?.id}
                          disabled
                        />
                        <Grid item sm={12}>
                          <Typography
                            className={classes.compsAndFunds}
                            style={{ fontSize: '1.2rem' }}
                            id="company-subtitle">
                            Companies
                          </Typography>
                        </Grid>
                        <FirmItems
                          itemType="Company"
                          firmItems={companyList.map(({ company_id: id, company_name: name }) => ({ id, name }))}
                          userPermissions={userPermissionsToSave?.permissions}
                          shouldCheckAll={shouldCheckAll || selectAll}
                          setUserPermissionsToSave={setUserPermissionsToSave}
                          userId={userSelected?.id}
                          disabled
                        />
                      </Grid>
                    </div>
                  </PerfectScrollbar>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item sm={6} className={`${classes.section} ${classes.borderLeft}`}>
          <UserDirectory
            setCurrentUser={setCurrentUser}
            selectedFirm={selectedFirm}
            isFirmAdmin={isFirmAdmin}
            companyList={companyList}
            fundList={fundList}
            isSuperUser={isSuperUser}
            handleAddUser={handleAddUserToFirm}
          />
        </Grid>
      </Grid>
    );
  }

  return <LoadingIndicator isLoading={!fundList && !companyList} />;
};

export default UserPermissions;
