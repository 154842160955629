import React from 'react';
import { DOCUMENT_REQUEST_CATEGORY } from 'common/constants/process-management';
import CurrentRequestedDocumentTable from './CurrentRequestedDocumentTable';
import CurrentRequestedTasksAccordion from './CurrentRequestedTasksAccordion';
import { CurrentRequestedDocumentsAndQuestionsProps, DocumentProgress } from './types';

const CurrentRequestedDocumentAccordion = ({
  currentInformationRequests,
}: CurrentRequestedDocumentsAndQuestionsProps) => {
  const documents = currentInformationRequests.find(request => request.category === DOCUMENT_REQUEST_CATEGORY);
  if (!documents?.id) {
    return null;
  }
  const filteredDocuments = {
    ...documents,
    responsible_users:
      documents.responsible_users.map(user => ({
        id: user.id,
        email: user.email,
        full_name: user.full_name,
      })) || [],
    progress:
      documents.progress.filter(
        (item): item is DocumentProgress => 'files' in item && 'is_uploaded' in item && 'tags' in item
      ) || [],
  };

  return (
    <CurrentRequestedTasksAccordion
      id={documents.id}
      title="Documents"
      subTitles={[
        {
          id: 1,
          label: `Documents requested to company: ${documents?.progress.length} sent`,
        },
        {
          id: 2,
          label: 'Documents requested to collaborators: -',
        },
        {
          id: 3,
          label: 'Documents uploaded by analyst: -',
        },
      ]}
      childrenComponent={<CurrentRequestedDocumentTable documents={filteredDocuments} />}
      values={filteredDocuments}
    />
  );
};

export default CurrentRequestedDocumentAccordion;
