import { isNil } from 'lodash';
import * as validationMessages from 'common/constants/messages/validations';
import {
  MULTIPLE_DISCOUNT_COLUMN_LEGEND,
  SPECIFIED_LABEL,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { GPC_APPROACH_CELL_ALIAS, LTM_MULTIPLE_COLUMNS_ALIAS } from './constants';

const validations = ({ cell, addFeedback, removeFeedback, tableData, parsedColumns }) => {
  const { isDisabled, gpcOptions } = tableData;
  const { columnLegend, rowNumber } = cell;

  if (LTM_MULTIPLE_COLUMNS_ALIAS.includes(cell.columnId)) {
    if (isNil(cell.value)) {
      addFeedback(validationMessages.IS_REQUIRED_ERROR);
    } else {
      removeFeedback(validationMessages.IS_REQUIRED_ERROR);
    }
  }
  if (GPC_APPROACH_CELL_ALIAS.includes(cell.alias) && MULTIPLE_DISCOUNT_COLUMN_LEGEND.includes(cell.columnId)) {
    const nextKeyIndex = 1;
    const mdCellKey = `${columnLegend}${rowNumber + nextKeyIndex}`;
    const multipleDiscountCell = parsedColumns[mdCellKey];
    multipleDiscountCell.readOnly
      = gpcOptions.find(option => option.id === cell.value)?.name !== SPECIFIED_LABEL || isDisabled;
  }
};

export default validations;
