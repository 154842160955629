import { createContext } from 'react';

const LayoutContext = createContext({
  pageTitle: null,
  setPageTitle: () => {},
  breadcrumb: null,
  setBreadcrumb: () => {},
  selectedFund: null,
  setSelectedFund: () => {},
  selectedMeasurementDate: null,
  setSelectedMeasurementDate: () => {},
  selectedVersion: null,
  setSelectedVersion: () => {},
  pageFilters: null,
  setPageFilters: () => {},
  navItems: [],
  setNavItems: () => {},
  extraPageActions: null,
  setExtraPageActions: () => {},
});

LayoutContext.displayName = 'LayoutContext';

export default LayoutContext;
