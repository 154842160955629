/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isUndefined } from 'lodash';
import { isEmpty } from 'underscore';
import { ALLOCATIONS_DRAFT, ALLOCATIONS_FINAL, ALLOCATIONS_PUBLISHED } from 'common/constants/allocations';
import { GridSkeleton } from 'components/Grid';
import ScalarDatasheet from 'components/ScalarDatasheet';
import useMDContext from 'context/MDContext';
import companyParser from './companies.parser';

const useStyles = makeStyles(theme => ({
  marginTop: {
    marginTop: theme.spacing(3),
  },
}));

const CompaniesTable = () => {
  const classes = useStyles();

  const { items, selectedCompanies, setSelectedCompanies } = useMDContext();

  useEffect(() => {
    let tmpSelectedCompanies = { ...selectedCompanies };

    items.forEach(fund => {
      fund.subItems.forEach(company => {
        const { id, allocationId } = company;

        if (selectedCompanies[id] && allocationId) {
          let updatedStatus = ALLOCATIONS_DRAFT;

          if (company.isPublished && !company.isFinal) {
            updatedStatus = ALLOCATIONS_PUBLISHED;
          } else if (company.isFinal) {
            updatedStatus = ALLOCATIONS_FINAL;
          }

          const allocationStatus = !isUndefined(selectedCompanies[id].allocationStatus)
            ? selectedCompanies[id].allocationStatus
            : updatedStatus;

          tmpSelectedCompanies = {
            ...tmpSelectedCompanies,
            [id]: {
              allocationStatus,
              allocationId,
            },
          };
        }
      });
    });

    setSelectedCompanies(tmpSelectedCompanies);
  }, [items]);

  const updateCompanyAllocation = (companyId, value) => {
    const tmpSelectedCompanies = {
      ...selectedCompanies,
      [companyId]: {
        ...selectedCompanies[companyId],
        allocationStatus: Number(value),
      },
    };

    setSelectedCompanies(tmpSelectedCompanies);
  };

  const markAllFinal = () => {
    let tmpSelectedCompanies = { ...selectedCompanies };

    Object.entries(selectedCompanies).forEach(([companyId]) => {
      tmpSelectedCompanies = {
        ...tmpSelectedCompanies,
        [companyId]: {
          ...tmpSelectedCompanies[companyId],
          allocationStatus: ALLOCATIONS_FINAL,
        },
      };
    });

    setSelectedCompanies(tmpSelectedCompanies);
  };

  const grid = useMemo(
    () =>
      companyParser({
        items,
        selectedCompanies,
        updateCompanyAllocation,
      }),
    [items, selectedCompanies]
  );

  if (isEmpty(grid)) {
    return <GridSkeleton />;
  }

  return (
    <>
      <Typography variant="body1" gutterBottom>
        Before moving to a new measurement date, please make any changes to the valuation status of the previous
        measurement date below
      </Typography>
      <Grid classes={{ root: classes.marginTop }}>
        <ScalarDatasheet className="data-sheet" data={grid} />
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center" classes={{ root: classes.marginTop }}>
        <Button variant="outlined" size="small" color="primary" onClick={markAllFinal}>
          Make All Final
        </Button>
      </Grid>
    </>
  );
};

export default CompaniesTable;
