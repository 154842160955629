import React from 'react';
import { QUESTIONNAIRE_REQUEST_CATEGORY } from 'common/constants/process-management';
import CurrentRequestedQuestionsList from './CurrentRequestedQuestionsList';
import CurrentRequestedTasksAccordion from './CurrentRequestedTasksAccordion';
import { CurrentRequestedDocumentsAndQuestionsProps, QuestionProgress } from './types';

const CurrentRequestedQuestionsAccordion = ({
  currentInformationRequests,
}: CurrentRequestedDocumentsAndQuestionsProps) => {
  const questions = currentInformationRequests.find(request => request.category === QUESTIONNAIRE_REQUEST_CATEGORY);
  if (!questions?.id) {
    return null;
  }
  const filteredQuestions = {
    ...questions,
    responsible_users:
      questions?.responsible_users.map(user => ({
        id: user.id,
        email: user.email,
        full_name: user.full_name,
      })) || [],
    progress:
      questions?.progress.filter(
        (item): item is QuestionProgress =>
          'children_questions' in item
          && 'question_type' in item
          && 'options' in item
          && 'answers' in item
          && 'parent_question' in item
      ) || [],
  };

  return (
    <CurrentRequestedTasksAccordion
      id={questions.id}
      title="Additional Information"
      subTitles={[
        {
          id: 1,
          label: `Questions requested to the company: ${questions?.progress.length} sent`,
        },
        {
          id: 2,
          label: 'Questions requested to collaborators: -',
        },
        {
          id: 3,
          label: 'Questions answered by analyst: -',
        },
      ]}
      childrenComponent={<CurrentRequestedQuestionsList questions={filteredQuestions} />}
      values={filteredQuestions}
    />
  );
};

export default CurrentRequestedQuestionsAccordion;
