export const USER_MANAGEMENT_PAGE_TITLE = 'User Management';
export const USER_PERMISSIONS = 'Permissions';
export const USER_ACCESS_DENIED = 'You don\'t have access to this information';
export const USER_PROFILE_ERROR = 'An error has occurred trying to get the user details.';
export const EMPTY_TABLE = 'There are no users created yet';
export const ADD_FIRST_USER = 'Please add a user to begin';
export const MAX_FILE_SIZE_ALLOWED = (maxSize, fileName) => `${fileName || 'File'} size exceeds ${maxSize} MB`;
export const FILE_TYPE_NOT_ALLOWED = (fileName, validExtensions) =>
  `${fileName} is not allowed. Please choose a ${validExtensions}.`;
export const S3_BUCKET_NOT_CONFIGURED = 'You don\'t have any AWS S3 Bucket configured yet.';
export const DRAG_YOUR_PICTURE_HERE = 'Drag your picture here or tap to select';
export const PROFILE_PICTURE_SPECS = '(15 MB max file size. JPG format file.)';
export const DO_YOU_WANT_RESET_PASSWORD = 'Do you want to reset your user password?';
export const SEND_PASSWORD_RESET_EMAIL = 'SEND PASSWORD RESET EMAIL';
export const USER_PROFILE_INFO_UPDATE = 'The user profile has been updated successfully!';
export const ADMIN = 'Admin';
export const EDIT = 'Edit';
export const VIEW = 'View';
export const USE_PORTAL = 'Use Portal';
export const OPACITY_PERMISSION_SELECTED = 1;
export const OPACITY_PERMISSION_UNSELECTED = 0.3;
export const MAX_ITEMS_PER_ROW = 3;
export const PERMISSIONS_SAVED = 'Permissions were saved successfully';
export const PERMISSIONS_NOT_SAVED_ERROR = 'There was an error while saving permissions';
export const USER_NOT_IN_FIRM = (userName, firmName) =>
  `${userName} is not in ${firmName} yet. Press the Save button to add the user to the firm.`;
export const USER_ADDED_TO_FIRM = 'The user was added to the firm successfully';
export const USER_NOT_ADDED_TO_FIRM = 'There was an error while adding the user to the firm';
export const ACCOUNT_SETTINGS_TAB = 'account-settings';
export const TWO_FACTOR_AUTH_TAB = 'two-factor-authentication';
export const PERMISSIONS_TAB = 'permissions';
export const USER_MANAGEMENT_TAB = 'user-management';
export const USER_DELETED = 'The user has been deleted successfully!';
export const PROFILE_PICTURE = 'Profile Picture';
export const FIRM = 'Firm';
export const FUND = 'Fund';
export const FUNDS = 'funds';
export const COMPANY = 'Company';
export const COMPANIES = 'companies';
export const FIRM_SUMMARY = 'summary';
export const VALUATIONS = 'valuations';

// User Permissions
export const MAX_FIRM_ITEMS = 10;
export const CREATE_USERS = 'CREATE USERS';
export const DIRECTORY = 'DIRECTORY';
export const BULK_ADD = 'BULK ADD';
export const INVITE_USER = 'INVITE USER';
export const ADD_USERS_NO_EMAIL = 'Add user(s) to the firm without sending a notification email';
export const FILE_MAX_SIZE_MSG = '(1 MB max file size in xls/xlsx format)';
export const UPLOAD_TEMPLATE_MSG = 'Please download the template below and upload the file with the new users.';
export const DOWNLOAD_TEMPLATE = 'Download Template';
export const HAS_NOT_LOGGED_IN_YET = 'Has not logged in yet';

// Roles
export const FIRM_ADMIN = 'Firm Admin';
export const COMPANY_USER = 'Company User';
export const ANALYST = 'Analyst';
export const FUND_ADMIN = 'Fund Admin';
export const AUDITOR = 'Auditor';
export const FULL_ACCESS_VIEWER = 'Full Access Viewer';
export const LIMITED_VIEWER = 'Limited Viewer';
export const EXPORT_VIEWER = 'Export Viewer';
export const DOCUMENT_VIEWER = 'Document Viewer';
export const NO_ITEMS_AVAILABLE = pluralItemType => `No ${pluralItemType} available`;
export const FIRM_ADMIN_VALUE = 0;
export const COMPANY_USER_VALUE = 1;
export const ANALYST_VALUE = 2;
export const FUND_ADMIN_VALUE = 3;
export const AUDITOR_VALUE = 4;
export const FULL_ACCESS_VIEWER_VALUE = 5;
export const LIMITED_VIEWER_VALUE = 6;
export const EXPORT_VIEWER_VALUE = 7;
export const DOCUMENT_VIEWER_VALUE = 8;
export const FIRM_ADMIN_ROLE = { value: FIRM_ADMIN_VALUE, label: FIRM_ADMIN };
export const COMPANY_USER_ROLE = { value: COMPANY_USER_VALUE, label: COMPANY_USER };
export const ANALYST_ROLE = { value: ANALYST_VALUE, label: ANALYST };
export const FUND_ADMIN_ROLE = { value: FUND_ADMIN_VALUE, label: FUND_ADMIN };
export const AUDITOR_ROLE = { value: AUDITOR_VALUE, label: AUDITOR };
export const FULL_ACCESS_VIEWER_ROLE = { value: FULL_ACCESS_VIEWER_VALUE, label: FULL_ACCESS_VIEWER };
export const LIMITED_VIEWER_ROLE = { value: LIMITED_VIEWER_VALUE, label: LIMITED_VIEWER };
export const EXPORT_VIEWER_ROLE = { value: EXPORT_VIEWER_VALUE, label: EXPORT_VIEWER };
export const DOCUMENT_VIEWER_ROLE = { value: DOCUMENT_VIEWER_VALUE, label: DOCUMENT_VIEWER };

export const USER_ROLES = {
  [FIRM_ADMIN]: FIRM_ADMIN_ROLE,
  [COMPANY_USER]: COMPANY_USER_ROLE,
  [ANALYST]: ANALYST_ROLE,
  [FUND_ADMIN]: FUND_ADMIN_ROLE,
  [AUDITOR]: AUDITOR_ROLE,
  [FULL_ACCESS_VIEWER]: FULL_ACCESS_VIEWER_ROLE,
  [LIMITED_VIEWER]: LIMITED_VIEWER_ROLE,
  [EXPORT_VIEWER]: EXPORT_VIEWER_ROLE,
  [DOCUMENT_VIEWER]: DOCUMENT_VIEWER_ROLE,
};

export const EXISTING_VALID_ROLES_VALUES = [
  ANALYST_VALUE,
  AUDITOR_VALUE,
  COMPANY_USER_VALUE,
  DOCUMENT_VIEWER_VALUE,
  EXPORT_VIEWER_VALUE,
  LIMITED_VIEWER_VALUE,
  FULL_ACCESS_VIEWER_VALUE,
  FIRM_ADMIN_VALUE,
  FUND_ADMIN_VALUE,
];
