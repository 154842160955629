import { SHEET_ALIASES_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import { getObjectValue } from 'utillities';
import { CustomValidationsParams } from './types';
import { handleWeightingProbability } from './utils';

const { EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY } = SHEET_ALIASES_CONSTANTS;

const customValidations = (params: CustomValidationsParams) => {
  const { addFeedback, cell, parsedColumns: parsedCells, removeFeedback, removeFeedbackFromCell } = params;

  const { alias } = getObjectValue(cell);

  // Validate Scenario Weighting/Probability
  if (alias === EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY) {
    handleWeightingProbability({ addFeedback, parsedColumns: parsedCells, removeFeedback, removeFeedbackFromCell });
  }
};

export default customValidations;
