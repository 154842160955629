/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FundInvestment = {
  id: number;
  fund_name?: string | null;
  fund_slug?: string | null;
  company_name?: string | null;
  company_slug?: string | null;
  security_name?: string | null;
  investment?: string | null;
  investment_date?: string | null;
  shares?: string | null;
  cse_shares?: string | null;
  diluted_ownership?: string | null;
  realized_value?: string | null;
  unrealized_value?: string | null;
  total_value?: string | null;
  x_factor?: string | null;
  has_only_custom_ownerships?: boolean;
  model_as_equity?: FundInvestment.model_as_equity;
  fund: number;
  measurement_date: number;
  company: number;
  fund_ownership_detail: number;
};

export namespace FundInvestment {
  export enum model_as_equity {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
  }
}
