/* eslint-disable no-param-reassign */
import { isNil } from 'lodash';
import {
  ALLOCATION_PERCENTAGE,
  BREAKPOINT_PRICE_ALIAS,
  BREAKPOINT_SERIE_ALIAS,
  CURRENT_VALUE_LESS_THAN_PREVIOUS,
  EMPTY_BREAKPOINT,
} from 'common/constants/cap-table';
import { letterRegex, numberRegex } from 'common/constants/general';
import { LOWER_VALUE_THAN_PREVIOUS } from 'common/constants/messages/validations';
import { addClassName, removeClassName } from 'utillities';

const getColumnTotals = (uniqueColumnKeys, columnCells) =>
  uniqueColumnKeys.map(key => ({
    column: key,
    total: Object.values(columnCells).reduce((total, curr) => {
      const letterPart = letterRegex.exec(curr.key) ? letterRegex.exec(curr.key)[0] : '';
      if (curr.isBpValue && letterPart === key) {
        return total + Number(curr.value);
      }
      return total;
    }, 0),
  }));

const conditions = ({ cell, cells }) => {
  const { alias, value, key: cellKey, sheet } = cell;
  const { BreakPoints: BreakPointCells } = cells;
  // The currency symbol is the second part of the currency string: 'USD $' -> '$'
  const cellCurrencySymbol = sheet?.currency.split(' ')[1];

  const addTooltipMessage = (message, key) => {
    // tooltipMessages may not exist - that is why we try to extract them separately
    const tooltipMessages = BreakPointCells[key]?.tooltipMessages || [];
    if (!tooltipMessages.includes(message)) {
      tooltipMessages.push(message);
    }
    BreakPointCells[key].tooltipMessages = tooltipMessages;
  };

  const removeTooltipMessage = (message, key) => {
    // tooltipMessages may not exist - that is why we try to extract them separately
    const tooltipMessages = BreakPointCells[key]?.tooltipMessages || [];
    if (tooltipMessages.includes(message)) {
      const index = tooltipMessages.findIndex(m => m === message);
      tooltipMessages.splice(index, 1);
    }
    BreakPointCells[key].tooltipMessages = tooltipMessages;
  };

  const cellsKeys = Object.keys(BreakPointCells);

  // Returns the letter part of all the parsed column keys: ['A', 'A', 'B', 'B', 'AA', 'AB', etc]
  const cellsKeysLetters = cellsKeys.map(key => letterRegex.exec(key)[0]);

  // Sort by length and then by lexicographical order. Finally, remove duplicates.
  // For example, AA should be after Z in the same fashion as in an Excel spreadsheet.
  // ['A', 'T', 'S', 'B', 'F', 'U', 'AA', 'C', 'AB'] --> ['A', 'B', 'C', 'F', 'S', 'T', 'U', 'AA', 'AB']
  cellsKeysLetters.sort((a, b) => a.length - b.length || a.localeCompare(b));
  const uniqueKeys = [...new Set(cellsKeysLetters)];

  const columnTotals = getColumnTotals(uniqueKeys, BreakPointCells);

  // If cellKey is B3 or AA21, then cellLocation becomes:
  // { column: 'B', row: '3'} or { column: 'AA', row: '21'}
  const cellLocation = {
    column: letterRegex.exec(cellKey) ? letterRegex.exec(cellKey)[0] : '',
    row: numberRegex.exec(cellKey) ? numberRegex.exec(cellKey)[0] : '',
  };

  const cellColIndex = uniqueKeys.findIndex(key => key === cellLocation.column);

  const bpAliases = [BREAKPOINT_PRICE_ALIAS, BREAKPOINT_SERIE_ALIAS];

  const prevCellKey = `${uniqueKeys[cellColIndex - 1]}${cellLocation.row}`;
  const prevColumnCell = BreakPointCells[prevCellKey];
  const currentClassName = BreakPointCells[cellKey].className;

  const nextCellKey = `${uniqueKeys[cellColIndex + 1]}${cellLocation.row}`;
  const nextColumnCell = BreakPointCells[nextCellKey];

  if (bpAliases.includes(alias)) {
    if (cellColIndex > 0) {
      // check current cell is not in the last column
      const isInLastColumn = BreakPointCells[cellKey].columnId === columnTotals.length - 1;
      if (Number(value) < Number(prevColumnCell.value) && !isInLastColumn) {
        addTooltipMessage(LOWER_VALUE_THAN_PREVIOUS('breakpoint'), cellKey);
        removeTooltipMessage(
          CURRENT_VALUE_LESS_THAN_PREVIOUS({
            value: 1,
            currencySymbol: cellCurrencySymbol,
          }),
          cellKey
        );
      } else {
        removeTooltipMessage(LOWER_VALUE_THAN_PREVIOUS('breakpoint'), cellKey);
      }
    }

    // check next cell is not from the last column (weight column)
    if (nextColumnCell && nextColumnCell.columnId !== columnTotals.length - 1) {
      if (
        !isNil(nextColumnCell)
        && nextColumnCell.alias !== ALLOCATION_PERCENTAGE
        && Number(nextColumnCell.value) < Number(value)
      ) {
        addTooltipMessage(LOWER_VALUE_THAN_PREVIOUS('breakpoint'), nextCellKey);
        removeTooltipMessage(
          CURRENT_VALUE_LESS_THAN_PREVIOUS({
            value: 1,
            currencySymbol: cellCurrencySymbol,
          }),
          nextCellKey
        );
      } else {
        removeTooltipMessage(LOWER_VALUE_THAN_PREVIOUS('breakpoint'), nextCellKey);
      }

      if (
        nextColumnCell.alias !== BREAKPOINT_PRICE_ALIAS
        && Number(nextColumnCell.value) >= Number(value)
        && Number(nextColumnCell.value) - Number(value) < 1
        && cellCurrencySymbol
      ) {
        addTooltipMessage(
          CURRENT_VALUE_LESS_THAN_PREVIOUS({
            value: 1,
            currencySymbol: cellCurrencySymbol,
          }),
          nextCellKey
        );
        removeTooltipMessage(LOWER_VALUE_THAN_PREVIOUS('breakpoint'), nextCellKey);
      } else {
        removeTooltipMessage(
          CURRENT_VALUE_LESS_THAN_PREVIOUS({
            value: 1,
            currencySymbol: cellCurrencySymbol,
          }),
          nextCellKey
        );
      }
    }
  }

  if (alias === BREAKPOINT_SERIE_ALIAS && cellColIndex > 0) {
    if (
      Number(value) >= Number(prevColumnCell.value)
      && Number(value) - Number(prevColumnCell.value) < 1
      && cellCurrencySymbol
    ) {
      addTooltipMessage(
        CURRENT_VALUE_LESS_THAN_PREVIOUS({
          value: 1,
          currencySymbol: cellCurrencySymbol,
        }),
        cellKey
      );
      removeTooltipMessage(LOWER_VALUE_THAN_PREVIOUS('breakpoint'), cellKey);
    } else {
      removeTooltipMessage(
        CURRENT_VALUE_LESS_THAN_PREVIOUS({
          value: 1,
          currencySymbol: cellCurrencySymbol,
        }),
        cellKey
      );
      BreakPointCells[cellKey].className = removeClassName(currentClassName, 'warning');
    }
  }

  // Managing the className 'warning' separate from the tooltips in order to not overlap them
  const adjustWarningClass = cell => {
    const tooltipMessages = cell.tooltipMessages || [];
    if (tooltipMessages.length > 0) {
      cell.className = addClassName(cell.className, 'warning');
    } else {
      cell.className = removeClassName(cell.className, 'warning');
    }
  };

  if (bpAliases.includes(alias)) {
    adjustWarningClass(BreakPointCells[cellKey]);
    if (nextColumnCell) {
      adjustWarningClass(BreakPointCells[nextCellKey]);
    }
  }

  if (BreakPointCells[cellKey]?.isBpValue) {
    const columnTotal = columnTotals.find(el => el.column === cellLocation.column)?.total || 0;
    const firstCellKey = `${cellLocation.column}1`;
    const firstCellClassName = BreakPointCells[firstCellKey].className;
    if (columnTotal > 0) {
      BreakPointCells[firstCellKey].tooltipMessages = [];
      BreakPointCells[firstCellKey].isValid = true;
      BreakPointCells[firstCellKey].className = removeClassName(firstCellClassName, 'error');
    } else {
      BreakPointCells[firstCellKey].tooltipMessages = [EMPTY_BREAKPOINT];
      BreakPointCells[firstCellKey].isValid = false;
      BreakPointCells[firstCellKey].className = addClassName(firstCellClassName, 'error');
    }
  }
};

export default conditions;
